<template>
  <div class="player-details" v-if="player">
    <div class="player-info">
      <div class="headshot">
        <img :src="position.playerHeadshotURL" alt="">
      </div>
    </div>
    <div class="player-stats-box">
      <div v-if="position.positionName.includes('Full')" class="player-stats">
        <div class="stats"><div> Tries </div><div class="stat-number">{{ player.tries ? player.tries : 0 }}</div></div>
        <div class="stats"><div> Try Assists </div><div class="stat-number">{{ player.tryAssists ? player.tryAssists : 0 }}</div></div>
        <div class="stats"><div> Tackle Breaks </div><div class="stat-number">{{player.tackleBreaks ? player.tackleBreaks: 0 }}</div></div>
        <div class="stats"><div> LineBreaks </div><div class="stat-number">{{player.lineBreaks ? player.lineBreaks: 0 }}</div></div>
        <div class="stats"><div> Line Break Assists </div><div class="stat-number">{{player.lineBreakAssist ? player.lineBreakAssist: 0 }}</div></div>
        <div class="stats"><div> Total Run Metres </div><div class="stat-number">{{player.allRunMetres ? player.allRunMetres: 0 }}</div></div>
        <div class="stats"><div> Kick Return Metres </div><div class="stat-number">{{player.kickReturnMetres ? player.kickReturnMetres: 0 }}</div></div>
        <div class="stats"><div> Kicks Defused </div><div class="stat-number"> 
          {{ (player.kicksDefused) 
            ? player.kicksDefused : 0 
              / 
              (player.kicksDefused + player.kicksNotDefused) 
              ? (player.kicksDefused + player.kicksNotDefused)
              : (player.kicksNotDefused) 
                ? player.kicksNotDefused
                : (player.kicksDefused) 
                  ? player.kicksDefused
                  : 0  
          }}
        </div></div>
        <div class="stats"><div> Tackles </div><div class="stat-number">{{ player.tackles }}</div></div>
        <div class="stats"><div> Tackle Efficiency </div><div class="stat-number">{{ player.effectiveTacklePercentage }}%</div></div>
      </div>

      <div v-else-if="position.positionName.includes('Wing')" class="player-stats">
        <div class="stats"><div> Tries </div><div class="stat-number">{{ player.tries ? player.tries : 0 }}</div></div>
        <div class="stats"><div> Tackle Breaks </div><div class="stat-number">{{player.tackleBreaks ? player.tackleBreaks: 0 }}</div></div>
        <div class="stats"><div> LineBreaks </div><div class="stat-number">{{player.lineBreaks ? player.lineBreaks: 0 }}</div></div>
        <div class="stats"><div> Total Run Metres </div><div class="stat-number">{{player.allRunMetres ? player.allRunMetres: 0 }}</div></div>
        <div class="stats"><div> Kick Return Metres </div><div class="stat-number">{{player.kickReturnMetres ? player.kickReturnMetres: 0 }}</div></div>
        <div class="stats"><div> Kicks Defused </div><div class="stat-number"> 
          {{ (player.kicksDefused) 
            ? player.kicksDefused : 0 
              / 
              (player.kicksDefused + player.kicksNotDefused) 
              ? (player.kicksDefused + player.kicksNotDefused)
              : (player.kicksNotDefused) 
                ? player.kicksNotDefused
                : (player.kicksDefused) 
                  ? player.kicksDefused
                  : 0  
          }}
        </div></div>
        <div class="stats"><div> Tackles </div><div class="stat-number">{{ player.tackles }}</div></div>
        <div class="stats"><div> Tackle Efficiency </div><div class="stat-number">{{ player.effectiveTacklePercentage }}%</div></div>
      </div>

      <div v-else-if="position.positionName.includes('Cent')" class="player-stats">
        <div class="stats"><div> Tries </div><div class="stat-number">{{ player.tries ? player.tries : 0 }}</div></div>
        <div class="stats"><div> Try Assists </div><div class="stat-number">{{ player.tryAssists ? player.tryAssists : 0 }}</div></div>
        <div class="stats"><div> Tackle Breaks </div><div class="stat-number">{{player.tackleBreaks ? player.tackleBreaks: 0 }}</div></div>
        <div class="stats"><div> LineBreaks </div><div class="stat-number">{{player.lineBreaks ? player.lineBreaks: 0 }}</div></div>
        <div class="stats"><div> Total Run Metres </div><div class="stat-number">{{player.allRunMetres ? player.allRunMetres: 0 }}</div></div>
        <div class="stats"><div> Tackles </div><div class="stat-number">{{ player.tackles }}</div></div>
        <div class="stats"><div> Tackle Efficiency </div><div class="stat-number">{{ player.effectiveTacklePercentage }}%</div></div>
      </div>

      <div v-else-if="position.positionName.includes('2nd')" class="player-stats">
        <div class="stats"><div> Minutes </div><div class="stat-number">{{ player.mins ? player.mins : 0 }}</div></div>
        <div class="stats"><div> Total Run Metres </div><div class="stat-number">{{player.allRunMetres ? player.allRunMetres: 0 }}</div></div>
        <div class="stats"><div> Post Contact Metres </div><div class="stat-number">{{ player.postContactMetres ? player.postContactMetres : 0 }}</div></div>
        <div class="stats"><div> Tackles </div><div class="stat-number">{{ player.tackles }}</div></div>
        <div class="stats"><div> Tackle Efficiency </div><div class="stat-number">{{ player.effectiveTacklePercentage }}%</div></div>
        <div class="stats"><div> Offloads  </div><div class="stat-number">{{ player.offloads ? player.offloads : 0 }}</div></div>
        <div class="stats">Supports & Decoys 
          <div class="stat-number">
            {{ player.decoys && player.supports 
              ? (player.decoys + player.supports)
              : (player.decoys) 
                ? player.decoys 
                : (player.supports) 
                  ? player.supports
                  : 0 }}
          </div>
        </div>
      </div>

      <div v-else-if="position.positionName.includes('Half') || position.positionName.includes('Five')" class="player-stats">
        <div class="stats">
          <div> Touches </div> 
          <div class="stat-number"> {{ (player.passes && player.allRuns) 
            ? (player.passes + player.allRuns)
            :(player.passes) 
              ? player.passes
              : (player.allRuns) 
              ? player.allRuns
              :0}}
          </div>
        </div>
        <div class="stats"><div> Tries </div><div class="stat-number">{{ player.tries ? player.tries : 0 }}</div></div>
        <div class="stats"><div> Try Assists </div><div class="stat-number">{{ player.tryAssists ? player.tryAssists : 0 }}</div></div>
        <div class="stats"><div> Tackle Breaks </div><div class="stat-number">{{player.tackleBreaks ? player.tackleBreaks: 0 }}</div></div>
        <div class="stats"><div> LineBreaks </div><div class="stat-number">{{player.lineBreaks ? player.lineBreaks: 0 }}</div></div>
        <div class="stats"><div> Line Break Assists </div><div class="stat-number">{{player.lineBreakAssist ? player.lineBreakAssist: 0 }}</div></div>
        <div class="stats"><div> Line Engaged Runs </div><div class="stat-number">{{ player.lineEngagedRuns ? player.lineEngagedRuns : 0 }}</div></div>
        <div class="stats"><div> Forced Drop Outs </div><div class="stat-number">{{ player.forcedDropoutKicker ? player.forcedDropoutKicker : 0 }}</div></div>
        <div class="stats"><div> Kick Metres </div><div class="stat-number">{{ player.kickMetres ? player.kickMetres : 0 }}</div></div>
        <div class="stats"><div> 40/20 </div><div class="stat-number">{{ player.fortyTwentyKicks ? player.fortyTwentyKicks : 0 }}</div></div>
        <div class="stats"><div> Tackles </div><div class="stat-number">{{ player.tackles }}</div></div>
        <div class="stats"><div> Tackle Efficiency </div><div class="stat-number">{{ player.effectiveTacklePercentage }}%</div></div>
      </div>

      <div v-else-if="position.positionName.includes('Hooker')" class="player-stats">
        <div class="stats"><div> Tackles </div><div class="stat-number">{{ player.tackles }}</div></div>
        <div class="stats"><div> Tackle Efficiency </div><div class="stat-number">{{ player.effectiveTacklePercentage }}%</div></div>
        <div class="stats"><div> Line Engaged Runs </div><div class="stat-number">{{ player.lineEngagedRuns ? player.lineEngagedRuns : 0 }}</div></div>
        <div class="stats"><div> Total Run Metres </div><div class="stat-number">{{player.allRunMetres ? player.allRunMetres: 0 }}</div></div>
        <div class="stats"><div> Try Assists </div><div class="stat-number">{{ player.tryAssists ? player.tryAssists : 0 }}</div></div>
        <div class="stats"><div> Line Break Assists </div><div class="stat-number">{{player.lineBreakAssist ? player.lineBreakAssist: 0 }}</div></div>
        <div class="stats"><div> Kick Metres </div><div class="stat-number">{{ player.kickMetres ? player.kickMetres : 0 }}</div></div>
        <div class="stats"><div> Forced Drop Outs </div><div class="stat-number">{{ player.forcedDropoutKicker ? player.forcedDropoutKicker : 0 }}</div></div>
        <div class="stats"><div> 40/20 </div><div class="stat-number">{{ player.fortyTwentyKicks ? player.fortyTwentyKicks : 0 }}</div></div>
      </div>

      <div v-else-if="position.positionName.includes('Pr') || position.positionName.includes('Lock')" class="player-stats">
        <div class="stats"><div> Minutes </div><div class="stat-number">{{ player.mins ? player.mins : 0 }}</div></div>
        <div class="stats"><div> Total Run Metres </div><div class="stat-number">{{player.allRunMetres ? player.allRunMetres: 0 }}</div></div>
        <div class="stats"><div> Post Contact Metres </div><div class="stat-number">{{ player.postContactMetres ? player.postContactMetres : 0 }}</div></div>
        <div class="stats"><div> Tackles </div><div class="stat-number">{{ player.tackles }}</div></div>
        <div class="stats"><div> Tackle Efficiency </div><div class="stat-number">{{ player.effectiveTacklePercentage }}%</div></div>
        <div class="stats"><div> Tackle Breaks </div><div class="stat-number">{{player.tackleBreaks ? player.tackleBreaks: 0 }}</div></div>
        <div class="stats"><div> Offloads  </div><div class="stat-number">{{ player.offloads ? player.offloads : 0 }}</div></div>
        <div class="stats">
          <div> Supports & Decoys </div>
          <div class="stat-number">
            {{ player.decoys && player.supports 
              ? (player.decoys + player.supports)
              : (player.decoys) 
                ? player.decoys 
                : (player.supports) 
                  ? player.supports
                  : 0 }}
          </div>
        </div>
      </div>

      <div v-else-if="position.positionName.includes('Interch')" class="player-stats">
        <div class="stats"><div> Minutes </div><div class="stat-number">{{ player.mins ? player.mins : 0 }}</div></div>
        <div class="stats"><div> Total Run Metres </div><div class="stat-number">{{player.allRunMetres ? player.allRunMetres: 0 }}</div></div>
        <div class="stats"><div> Post Contact Metres </div>< <div class="stat-number">{{ player.postContactMetres ? player.postContactMetres : 0 }}</div></div>
        <div class="stats"><div> Tackles </div><div class="stat-number">{{ player.tackles }}</div></div>
        <div class="stats"><div> Tackle Efficiency </div><div class="stat-number">{{ player.effectiveTacklePercentage }}%</div></div>
        <div class="stats"><div> Tackle Breaks </div><div class="stat-number">{{player.tackleBreaks ? player.tackleBreaks: 0 }}</div></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "player-details",
  props: {
    myplayer: { type: Object, required: true }
  },
  computed: {
    player() {
      return this.myplayer ? this.myplayer.playerStats : false;
    },
    position() {
      return this.myplayer || false;
    }
  }
}
</script>

<style lang="scss" scoped>

.player-details {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.player-info {
  text-align: center;
  .headshot {
    margin: 0 auto;
    width: 10rem;
    height: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.player-stats-box {
  padding: 1rem;
  width: 60%;
  @media (max-width: $tablet) {
    width: 90%;
  }
  .player-stats {
    .stats {
      div { width: 50%; text-align: center; }
      padding: 0.85rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid white;
      font-weight: 300;
      letter-spacing: 0.06375rem;
    }
  }
}
</style>