<template>
  <component :is="Default">
    <div style="padding: 1rem 8px 0;max-width: 1024px;margin: auto;">
      <div
        style="display:flex;flex-direction: column;justify-content:center;padding-top:32px;width:100wh;"
      >
        <div>
          <img
            src="../assets/img/logo/dally-m-V2.svg"
            height="200"
            style="height: auto; width: calc(100% - 20px); max-height: 200px;"
          />
        </div>
        <div style="width: calc(100% - 20px)">
          <h1 style="font-size:24px;font-weight:100;color:rgba(214, 193, 135);">
            {{ memberPos }} of the Year
          </h1>
        </div>
        <div v-if="judgeOverrideOptions" class="mt-20">
          <label class="subtitle">Vote on Behalf of:</label>
          <div class="d-flex filters">
            <v-select
              placeholder="Select Judge"
              :options="judgesToOverride"
              label="name"
              v-model="judgeOverride"
              class="w-45"
            />
          </div>
        </div>
      </div>
      <div
        class="container mt-20"
        v-if="category && category !== 'team-of-the-year'"
      >
        <el-table
          :data="tableData"
          border
          @row-click="awardPlayer"
          highlight-current-row
        >
          <el-table-column label="Name" prop="memberName" width="auto">
          </el-table-column>
          <el-table-column label="Team" prop="teamName" width="auto">
          </el-table-column>
          <el-table-column fixed="right" width="48">
            <template slot-scope="scope">
              <span v-if="prizeCheck(scope.row)">
                {{ prizeCheck(scope.row) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          class="next"
          type="primary"
          v-if="Object.keys(best).every((award) => best[award] !== false)"
          @click="submitEOS"
        >
          Submit
        </el-button>
      </div>
      <div class="mt-20" v-if="category && category === 'team-of-the-year'">
        <div class="mt-20 subtitle">{{ activePos }}</div>
        <div v-if="activePos">
          <el-input
            autocomplete="off"
            placeholder="Filter Names"
            v-model="search"
            class="mt-20"
          ></el-input>
          <el-table
            :data="activeTable"
            border
            @row-click="selectMember"
            highlight-current-row
            :key="tableKey"
            class="mt-20"
          >
            <el-table-column label="Name" prop="name" width="auto">
            </el-table-column>
            <el-table-column label="Team" prop="team" width="auto">
            </el-table-column>
          </el-table>
        </div>
        <div class="clear"></div>
        <el-tabs type="card" class="mt-20">
          <el-tab-pane label="Field">
            <div class="mt-20 column">
              <!-- Column 2 - Canvas with player positions -->
              <div class="canvas">
                <div
                  v-for="position in mappedPositions"
                  class="player"
                  :class="{
                    [position[0].toLowerCase()]: true,
                  }"
                >
                  <div
                    :id="position[0]"
                    class="number-circle gold clickable"
                    @click="populateTable($event, position[0])"
                  >
                    <b>{{
                      position[0] !== undefined ? getShirtNum(position[0]) : ""
                    }}</b>
                  </div>
                  <div class="player-name-wrap">
                    <div class="player-name">
                      <mark v-if="position[1]">{{
                        position[1] !== undefined
                          ? getName(position[1].player.name)
                          : ""
                      }}</mark>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="List">
            <div v-for="pos in positions" :key="pos.type">
              <div class="mt-20 pos-box">
                <div :id="pos.type" class="position" @click="populateTable">
                  {{ pos.name }}
                </div>
                <input
                  v-if="teamObj[pos.type]"
                  :value="teamObj[pos.type].player.name"
                  class="position-input"
                  disabled
                /><input
                  v-else
                  class="position-input__unset"
                  :value="undefined"
                  :placeholder="
                    `Interact with label above to select a ${pos.name}`
                  "
                  disabled
                />
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="clear"></div>
        <el-button
          class="next"
          type="primary"
          :disabled="
            Object.keys(teamObj).some((pos) => teamObj[pos] === undefined)
          "
          @click="submitEOS"
        >
          Submit
        </el-button>
        <div id="bottom" class="clear"></div>
      </div>
    </div>
  </component>
</template>
<script>
import {
  dallyMCaptainOYList,
  dallyMCoachOYList,
  dallyMRookieOYList,
  reportsUsersProd,
  reportsUsersTest,
  intervalPosArr,
  interchange,
} from "../utils/constants";
import Default from "@/layouts/default";

export default {
  name: "EndOfSeasonVoting",
  data() {
    return {
      Default,
      activePos: "",
      best: {
        first: false,
        second: false,
        third: false,
      },
      currentComp: {},
      noInterval: false,
      ineligibleIds: [],
      judge: {
        _id: null,
        name: undefined,
        assignmentId: null,
        reference: undefined,
      },
      judgeOverrideOptions: undefined,
      judgeOverride: undefined,
      members: [],
      posId: "",
      positions: intervalPosArr,
      search: "",
      selectedMember: {
        player: {
          name: undefined,
          position: undefined,
        },
        team: {
          _id: null,
          name: undefined,
        },
        type: undefined,
        competition: null,
        votes: 1,
      },
      tableData: [],
      tableKey: 0,
      teamNominees: {},
      teamObj: {
        Fullback: undefined,
        "Wing-1": undefined,
        "Centre-1": undefined,
        "Centre-2": undefined,
        "Wing-2": undefined,
        "Five-Eighth": undefined,
        Halfback: undefined,
        "Prop-1": undefined,
        "Prop-2": undefined,
        Hooker: undefined,
        "SecondRow-1": undefined,
        "SecondRow-2": undefined,
        Lock: undefined,
      },
      interchangeData: undefined
    };
  },
  async mounted() {
    try {
      //   auth check
      this.$store.state.root.loading = true;
      const eosVotingGet = await this.$http.get(
        `/nrl/api/v1/awards/comps/end-of-season?type=${this.$route.params.id}`,
      );
      const assignments =
        eosVotingGet && eosVotingGet.data && eosVotingGet.data.data
          ? eosVotingGet.data.data
          : [];
      const selectedCompetition =
        this.$route.query && this.$route.query.competition;
      const category = this.$route.params.id;

      if (!assignments[category].length) throw new Error("Forbidden");
      const foundAssignment = assignments[category].find(
        (a) => a.comp.id === parseInt(selectedCompetition),
      );
      if (!foundAssignment) throw new Error("Forbidden");
      const statusArr = foundAssignment.status.split("_");
      if (!statusArr.includes("assigned") || !statusArr.includes("open"))
        throw new Error("Forbidden");

      const currentAssignment = foundAssignment;

      const interchangeDataRes = await this.$http.get(
      `/nrl/api/v1/awards/interchange/competition/${currentAssignment.comp.id}`
      )
      const interchangeData = interchangeDataRes.data.data
      this.interchangeData = interchangeData;

      const { _id, quarter: q, type: t, memberId } = currentAssignment;
      const name = this.$store.getters["user/userName"];
      this.judge = {
        _id: memberId,
        name,
        assignmentId: _id,
        reference: `voter_${memberId}_quarter_${q}-${t}`,
      };
      this.selectedMember.competition = currentAssignment.comp.id;
      const competitionGet = await this.$http.get(
        `/nrl/api/v1/awards/competitions/${currentAssignment.comp.id}`,
      );
      this.currentComp =
        competitionGet && competitionGet.data && competitionGet.data.data
          ? competitionGet.data.data
          : {};
      const { currentComp } = this;
      let totyGet;
      if (
        currentComp &&
        currentComp.meta &&
        currentComp.meta.enableAwards &&
        currentComp.meta.awardsSettings &&
        currentComp.meta.awardsSettings.endOfSeasonVoting &&
        currentComp.meta.awardsSettings.endOfSeasonVoting.includes("Team")
      ) {
        if (!currentComp.meta.awardsSettings.periodVoting)
          this.noInterval = true;
        const url = currentComp.meta.awardsSettings.periodVoting
          ? `/nrl/api/v1/awards/competitions/${currentAssignment.comp.id}/team-of-the-year`
          : `/nrl/api/v1/awards/comps/${currentAssignment.comp.id}/players/interval/all`;
        totyGet = await this.$http.get(url);
        this.teamNominees =
          totyGet.data && totyGet.data.data && totyGet.data.data.playerPosObj;
      }
      if (category === "captain-of-the-year") {
        const eosList =
          interchangeData &&
          interchangeData.endOfSeason &&
          interchangeData.endOfSeason.captain
              ? interchangeData.endOfSeason.captain
              : [];
        this.tableData = eosList;
      }
      if (category === "coach-of-the-year") {
        const eosList =
          interchangeData &&
          interchangeData.endOfSeason &&
          interchangeData.endOfSeason.coach
              ? interchangeData.endOfSeason.coach
              : [];
        this.tableData = eosList;
      }
      if (category === "rookie-of-the-year") {
        const eosList =
          interchangeData &&
          interchangeData.endOfSeason &&
          interchangeData.endOfSeason.rookie
              ? interchangeData.endOfSeason.rookie
              : [];
        this.tableData = eosList;
      }
      if (category === "team-of-the-year") {
        this.tableData = [];
      }

      // override
      if (
        interchangeData && interchangeData.interval && interchangeData.interval[
            this.category
          ]
      ) {
        const nomsExpanded = Object.entries(this.nominees);
        const flattenNoms = Object.values(
          interchangeData.interval[
            this.category
          ],
        );
        const overrideIds = flattenNoms
          .flat()
          .map((profile) => profile && profile.playerId);
        for (const nom of nomsExpanded) {
          const nomPos = nom[0];
          const nomArr = nom[1];
          nomArr.forEach((n) => {
            if (!overrideIds.includes(n.playerId)) { return };
            const nomsIds = this.nominees[nomPos].map((n) => n.playerId);
            const deleteInd = nomsIds.indexOf(n.playerId);
            if (deleteInd > -1) this.nominees[nomPos].splice(deleteInd, 1);
          });
        }
      }

      const userId = this.$store.getters["user/userid"];
      const userAuth =
        process.env.NODE_ENV === "production"
          ? reportsUsersProd
          : reportsUsersTest;
      if (!userAuth.includes(userId)) {
        this.$store.state.root.loading = false;
        return;
      }
      this.judgeOverrideOptions = [];
      const url = `/nrl/api/v1/awards/comps/${currentAssignment.comp.id}/judges/end-of-season?type=${this.$route.params.id}`;
      const superRes = await this.$http.get(url);
      if (!superRes.data && !superRes.data.data) {
        this.$store.state.root.loading = false;
        return;
      }
      this.judgeOverrideOptions = superRes.data.data;
      this.$store.state.root.loading = false;
    } catch (error) {
      this.$store.state.root.loading = false;
      this.$router.push("/matches");
      console.log({ error });
      this.$notify.error({
        title: "Error",
        message: "Not authorised to access this Voting",
      });
      return;
    }
  },
  methods: {
    clickLogout() {
      this.$store.commit("user/logout");
      this.$router.push("/");
    },
    selectMember(currentRow) {
      this.selectedMember.player = {
        //   _id: currentRow.playerId,
        name: currentRow.memberName,
        position: this.memberPos, // TODO: dynamic param
      };
      this.selectedMember.team = {
        _id: currentRow.teamId,
        name: currentRow.teamName,
      };
      this.selectedMember.type = this.category; // TODO: dynamic param
      this.awardPlayer(currentRow);
    },
    awardPlayer(selectedPlayer, place) {
      const hasFirst = this.best.first !== false;
      const hasSecond = this.best.second !== false;
      const hasThird = this.best.third !== false;
      const memberDetails =
        this.tableData.find((m) => m.memberId === selectedPlayer.memberId) ||
        {};

      const member_copy = { ...memberDetails, ...selectedPlayer };
      const member = {
        player: {
          _id: member_copy.memberId,
          name: member_copy.memberName,
        },
        team: {
          _id: member_copy.teamId,
          name: member_copy.teamName,
        },
      };
      member.type = this.category;
      member.competition = this.currentComp._id;

      const prizeWithMemberName = Object.keys(this.best).find(
        (prize) =>
          this.best[prize] && this.best[prize].player._id === member.memberId,
      );

      const hasAll = hasFirst && hasSecond && hasThird;
      if (prizeWithMemberName && !hasAll) {
        if (prizeWithMemberName === "first") {
          this.best.first = false;
          this.best.second = member;
        } else if (prizeWithMemberName === "second") {
          this.best.second = false;
          this.best.third = member;
        } else if (prizeWithMemberName === "third") {
          this.best.third = false;
        }
      } else if (prizeWithMemberName && hasAll) {
        this.best.first = member;
        this.best.second = false;
        this.best.third = false;
      } else {
        const nextPrize = Object.keys(this.best).find(
          (prize) => this.best[prize] === false,
        );
        if (nextPrize) {
          this.best[nextPrize] = member;
        } else {
          this.best.first = member;
          this.best.second = false;
          this.best.third = false;
        }
      }
    },
    getName(n) {
      const split = n.split(" ");
      const firstLetterFirstName = split[0].charAt(0).toUpperCase();
      const hyphenCheck = split[1].split("-");
      const capitalizedLastName = hyphenCheck
        .map((x) => {
          const firstLetter = x.charAt(0).toUpperCase();
          const remainingLetters = x.slice(1).toLowerCase();
          return firstLetter + remainingLetters;
        })
        .join("-");
      const name = `${firstLetterFirstName}. ${capitalizedLastName}`;
      return name;
    },
    getShirtNum(pos) {
      const obj = {
        "Centre-1": 3,
        "Centre-2": 4,
        "Five-Eighth": 6,
        Fullback: 1,
        Halfback: 7,
        Hooker: 9,
        Lock: 13,
        "Prop-1": 8,
        "Prop-2": 10,
        "SecondRow-1": 11,
        "SecondRow-2": 12,
        "Wing-1": 2,
        "Wing-2": 5,
      };
      return obj[pos];
    },
    populateTable(e, pe = false) {
      this.$store.state.root.loading = true;
      this.search = "";
      this.tableKey++;
      const check = this.positions.find((p) => p.type === pe);
      const pos = pe && check ? check.name : e.target.innerHTML;
      this.posId = check ? check.type : e.target.id;
      this.activePos = pos.trim();
      let currentPos = this.activePos;
      if (process.env.VUE_APP_PIN_ENV === "test") {
        const uatArr = ["Winger", "Second Row"];
        if (this.noInterval && uatArr.includes(currentPos)) {
          const map = {
            Winger: "Wing",
            "Second Row": "2nd Row",
          };
          currentPos = map[currentPos];
        }
      }
      let posObj = this.nominees[currentPos] || [];
      
      let addedPlayers = [];
      let removedPlayers = [];
      const activePosition = this.activePos.replace(" ", "");
      if (activePosition === "Winger") {
        const activePositions = ["Wing", "Winger"];
        activePositions.map((activePosition) => {
          if (
            this.interchangeData.interval[this.category] &&
            this.interchangeData.interval[this.category][activePosition] &&
            this.interchangeData.interval[this.category][activePosition]
              .length > 0
          ) {
            this.interchangeData.interval[this.category][activePosition].map(
              (player) => {
                addedPlayers.push(player);
              }
            );
          }
          if (
            this.interchangeData.removedInterval[this.category] &&
            this.interchangeData.removedInterval[this.category][
              activePosition
            ] &&
            this.interchangeData.removedInterval[this.category][activePosition]
              .length > 0
          ) {
            this.interchangeData.removedInterval[this.category][
              activePosition.replace(" ", "")
            ].map((player) => {
              removedPlayers.push(player);
            });
          }
        });
      } else {
        if (
          this.interchangeData.interval[this.category] &&
          this.interchangeData.interval[this.category][activePosition] &&
          this.interchangeData.interval[this.category][activePosition].length >
            0
        ) {
          addedPlayers = this.interchangeData.interval[this.category][
            activePosition
          ];
        }

        if (
          this.interchangeData.removedInterval[this.category] &&
          this.interchangeData.removedInterval[this.category][activePosition] &&
          this.interchangeData.removedInterval[this.category][activePosition]
            .length > 0
        ) {
          removedPlayers = this.interchangeData.removedInterval[this.category][
            activePosition.replace(" ", "")
          ];
        }
      }
      const removedPlayerIds = (removedPlayers || []).map(p => p.playerId);

      if(addedPlayers.length > 0) {
        addedPlayers.forEach((int) => {
          const playersInPos = posObj.map((p) => p.playerId);
          if (playersInPos.includes(int.playerId)) return;
          int.order = 0;
          posObj.push(int);
        });

        if (removedPlayerIds.length > 0) {
            posObj = posObj.filter(player => !removedPlayerIds.includes(player.playerId));
          }
      }

      if (removedPlayerIds.length > 0) {
          posObj = posObj.filter(player => !removedPlayerIds.includes(player.playerId));
      }

      if (posObj && posObj.length)
        this.members = posObj.sort((p1, p2) => {
          if (p1.order < p2.order) {
            return 1;
          }
          if (p1.order > p2.order) {
            return -1;
          }
          if (p1.name.toLowerCase() < p2.name.toLowerCase()) {
            return -1;
          }
          if (p1.name.toLowerCase() > p2.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      else this.members = [];
      if (this.mappedPlayers && this.mappedPlayers.length)
        this.members = this.members.filter(
          (mem) => !this.mappedPlayers.includes(mem.playerId),
        );

      window.scrollTo(0, 0);
      this.$store.state.root.loading = false;
    },
    prizeCheck(member) {
      const top_three = this.best;
      const isFirst =
        top_three.first && top_three.first.player._id === member.memberId;
      const isSecond =
        top_three.second && top_three.second.player._id === member.memberId;
      const isThird =
        top_three.third && top_three.third.player._id === member.memberId;
      let votes;
      if (isFirst) votes = 3;
      if (isSecond) votes = 2;
      if (isThird) votes = 1;
      return votes;
    },
    selectMember(currentRow) {
      try {
        if (this.ineligibleIds.includes(currentRow.playerId))
          throw {
            title: ": Ineligible Player",
            message:
              "Selected player player has been flagged as ineligble due to accumulated suspensions.",
          };
        this.teamObj[this.posId] = {
          player: {
            _id: currentRow.playerId,
            name: currentRow.name,
            position: this.activePos,
          },
          team: {
            _id: currentRow.teamId,
            name: currentRow.team,
          },
        };
        this.activePos = "";
      } catch (error) {
        this.$store.state.root.loading = false;
        if (!error.title) error.title = "";
        if (!error.message) error.message = "Could not select player";
        this.$notify.error({
          title: `Error${error.title}`,
          message: error.message,
          showClose: false,
          iconClass: "hideIcon",
        });
      }
    },
    async submitEOS() {
      try {
        this.$store.state.root.loading = true;
        let bestPlayers;
        if (this.category === "team-of-the-year") {
          // clear previous errors
          this.$store.state.root.loading = true;
          const allFlaggedErrors = document.querySelectorAll(".error_input");
          const allErrSpans = document.querySelectorAll("span.error_message");
          allErrSpans.forEach((s) => s.remove());
          allFlaggedErrors.forEach((i) => i.classList.remove("error_input"));

          const { teamObj } = this;
          let best = Object.values(teamObj);
          best = best.filter((v) => v);
          const players = best.map((v) => {
            if (v) {
              if (this.ineligibleIds.includes(v.player._id)) {
                let objE = Object.entries(this.teamObj);
                objE = objE.filter((v) => v);
                const inPl = objE.filter(
                  (e) => e[1] && e[1].player._id === v.player._id,
                );
                const flags = inPl.flat(2);
                let flaggedPlayers = [];
                if (flags.length)
                  flaggedPlayers = flags.filter((d) => typeof d === "string");
                if (flaggedPlayers.length) {
                  flaggedPlayers.forEach((id) => {
                    const elCircle = document.querySelector(
                      `#${id}.number-circle`,
                    );
                    const el = document.querySelector(`#${id}.position`);
                    const canvasPlayer = elCircle.parentNode;
                    const input = el.nextSibling;
                    const cl = input.classList;
                    if (cl.contains("error_input")) return;
                    input.classList.add("error_input");
                    canvasPlayer.classList.add("error_input");
                    const span = document.createElement("span");
                    span.innerHTML =
                      "Player selected has been deemed ineligible";
                    span.style.color = "red";
                    span.classList.add("error_message");
                    input.after(span);
                  });
                }
                throw {
                  title: ": Ineligible Player",
                  message: `Selected player (${v.player.name})  has been flagged as ineligble due to accumulated suspensions.`,
                };
              }
              return v.player._id;
            }
          });

          const dupPlayers = players.filter(
            (value, index, self) => self.indexOf(value) !== index,
          );
          if (dupPlayers.length) {
            let entries = Object.entries(this.teamObj);
            entries = entries.filter((v) => v);
            const dupEl = dupPlayers.map((id) =>
              entries.filter((e) => e[1] && e[1].player._id === id),
            );
            const dups = dupEl.flat(2);
            let dupIds = [];
            if (dups.length) dupIds = dups.filter((d) => typeof d === "string");
            if (dupIds.length)
              dupIds.forEach((id) => {
                const el = document.getElementById(id);
                const input = el.nextSibling;
                const cl = input.classList;
                if (cl.contains("error_input")) return;
                input.classList.add("error_input");
                const span = document.createElement("span");
                span.innerHTML = "Player selected in multiple positions";
                span.style.color = "red";
                span.classList.add("error_message");
                input.after(span);
              });
            throw {
              title: ": Player selected in multiple positions",
              message: "Player can only be selected in one position.",
            };
          }
          bestPlayers = Object.values(teamObj);
          const bestPos = Object.keys(teamObj);
          if (bestPlayers.filter((p) => !p).length > 0)
            throw {
              title: "",
              message:
                "A player must be selected in every position before submitting votes",
            };

          bestPlayers.forEach((bp, i) => {
            if (
              ["Winger", "Centre", "Prop", "Second Row"].includes(
                bp.player.position,
              )
            )
              bp.player.position = bestPos[i];
            bp.type = this.category;
            bp.judge = this.judge;
            bp.competition = this.currentComp && this.currentComp._id;
            bp.votes = 1;
          });
        } else {
          const { best } = this;
          const top_three = Object.keys(best);
          for (const place of top_three) {
            const { competition, player, team, type } = best[place];
            if (
              !competition ||
              !type ||
              !player._id ||
              !player.name ||
              !team._id ||
              !team.name
            )
              throw {
                title: "Missing Data",
                message: "Unable to submit vote",
              };
            this.best[place].player.position = this.memberPos;
            const prize = Object.keys(best).find(
              (prize) => best[prize] && best[prize].player._id === player._id,
            );
            const votes =
              prize === "first"
                ? 3
                : prize === "second"
                ? 2
                : prize === "third"
                ? 1
                : "";
            if (!votes)
              throw {
                title: "Missing Data",
                message: "Unable to submit vote",
              };
            this.best[place].votes = votes;
            this.best[place].judge = this.judge;
          }
        }

        const { judgeOverride } = this;

        let override;
        if (
          judgeOverride &&
          Object.entries(judgeOverride).flat().length === 10
        ) {
          override = judgeOverride;
        }
        const assignmentUpdate = {
          comp: {
            id: this.currentComp._id,
            name: this.currentComp.name,
          },
          memberId: this.judge._id,
          status: "voter_assigned_open_voted",
          quarter: "end-of-season",
          type: this.category,
        };
        if (this.category === "team-of-the-year") {
          await this.$http.post("/nrl/api/v1/awards/interval/vote", {
            bestPlayers,
            override,
            interval: "end-of-season-team-of-the-year",
            assignmentUpdate,
          });
        } else {
          await this.$http.post(
            `/nrl/api/v1/awards/end-of-season/vote?type=${this.category}`,
            {
              selectedMembers: this.best,
              override,
              assignmentUpdate,
            },
          );
        }

        this.$notify.success({
          title: "Success",
          message: "Vote has been successfully submitted",
          showClose: false,
          iconClass: "hideIcon",
        });
        this.$store.state.root.loading = false;
        this.$router.push("/matches");
      } catch (error) {
        if (!error.title) error.title = "";
        if (!error.message) error.message = "Could not submit votes";
        this.$notify.error({
          title: `Error${error.title}`,
          message: error.message,
          showClose: false,
          iconClass: "hideIcon",
        });
        this.$store.state.root.loading = false;
        this.$router.push("/matches");
      }
    },
  },
  computed: {
    activeTable() {
      if (!this.activePos) return [];
      const keys = Object.keys(this.teamObj);
      const otherPosArr = keys.filter((k) => {
        return (
          (k.includes(this.activePos.replace(" ", "")) ||
            (["Wing-1", "Wing-2"].includes(k) &&
              this.activePos === "Winger")) &&
          k !== this.posId
        );
      });
      let otherPos;
      if (otherPosArr.length) otherPos = otherPosArr[0];
      let playerDup;
      if (otherPos) playerDup = this.teamObj[otherPos];
      if (playerDup && typeof playerDup === "object")
        this.members = this.members.filter(
          (m) => m.playerId !== playerDup.player._id,
        );

      if (this.search.length > 2) {
        const res = this.members.filter((p) =>
          p.name.toLowerCase().includes(this.search.toLowerCase().trim()),
        );
        return res.sort();
      }
      return this.members.sort();
    },
    category() {
      return this.$route.params.id;
    },
    judgesToOverride() {
      const { judgeOverrideOptions } = this;
      if (!judgeOverrideOptions) return [];
      const arr = judgeOverrideOptions.filter(
        (j) =>
          j &&
          j.status &&
          j.status.split("_").includes("assigned") &&
          j.status.split("_").includes("open"),
      );
      return arr;
    },
    mappedPositions() {
      const entries = Object.entries(this.teamObj);
      return entries;
    },
    memberPos() {
      const pos = {
        "captain-of-the-year": "Captain",
        "coach-of-the-year": "Coach",
        "rookie-of-the-year": "Rookie",
        "team-of-the-year": "Team",
      };
      return pos[this.$route.params.id];
    },
    nominees() {
      const playerPool = this.teamNominees
        ? Object.entries(this.teamNominees)
        : [];
      const obj = {};
      if (this.noInterval) {
        for (const player of playerPool) {
          const posObj = player[1];
          const position = player[0];

          const posSort = posObj.sort((p1, p2) => {
            if (p1.order < p2.order) {
              return 1;
            }
            if (p1.order > p2.order) {
              return -1;
            }
            if (p1.name.toLowerCase() < p2.name.toLowerCase()) {
              return -1;
            }
            if (p1.name.toLowerCase() > p2.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (!obj[position]) obj[position] = posSort;
        }
      } else {
        for (const player of playerPool) {
          const name = player[0];
          const profile = player[1];
          const posKeys = profile.positionsAgg || {};
          const positions = Object.keys(posKeys) || [];
          // players largest vote value recieved
          const largestVoteValue = Math.max(...Object.values(posKeys));

          const maxVotesPos = positions.filter(
            (p) => posKeys[p] === largestVoteValue,
          );
          positions.forEach((p) => {
            if (
              maxVotesPos.includes(p) &&
              posKeys[p] &&
              profile.details &&
              profile.details.playerId &&
              profile.details.teamName &&
              profile.details.teamId
            ) {
              const playerObj = {
                name,
                order: posKeys[p],
                playerId: profile.details.playerId,
                team: profile.details.teamName,
                teamId: profile.details.teamId,
              };
              if (!obj[p]) obj[p] = [];
              obj[p].push(playerObj);
            }
          });
        }
      }
      return obj;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-header {
  width: 88%;
  margin: auto;
  max-width: 1024px;
}

.subtitle {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.225rem;
}

.position-input.error_input {
  border-bottom: solid 3px red;
  color: red;
  opacity: 0.6;
}

.canvas {
  .player {
    &.error_input {
      .number-circle {
        border-color: red;
        color: red;
        opacity: 0.6;
      }
      .player-name-wrap .player-name mark {
        background-color: red;
      }
    }
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}
.clear::after {
  content: "";
  clear: both;
  display: table;
}

.subtitle {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.225rem;
}

.v-select {
  @media (min-width: $tablet) {
    max-width: 600px;
    margin: 0 auto;
  }
}

.pos-box {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.position {
  color: #fff;
  cursor: pointer;
}
.position-input {
  border-bottom: 1px solid rgb(214, 193, 135);
  max-width: 400px;
  width: 100%;
  text-align: center;
  color: rgba(214, 193, 135, 0.5);
  font-size: 14px;
}
.position-input__unset {
  max-width: 400px;
  width: 100%;
  border-bottom: solid 3px red;
  &::placeholder {
    font-size: 0.8rem;
  }
}

$number-circle-factor: 0.8;
.canvas {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 550px;
  background-color: #098255;
  margin: auto;

  .player {
    position: absolute;
    transform: translate(20px, 16px);

    .player-name-wrap {
      position: relative;

      .player-name {
        position: absolute;
        width: auto;
        left: -50%;
        text-align: center;
        font-size: 0.7em;
        color: $secondary;
        white-space: nowrap;
        mark {
          background-color: rgb(214, 193, 135);
          padding: 0 4px;
        }
      }
    }
  }
  .prop-1,
  .prop-2,
  .hooker {
    top: 0;
  }
  .secondrow-1,
  .secondrow-2 {
    top: 15%;
  }
  .lock {
    top: 30%;
  }
  .halfback {
    top: 45%;
  }
  .five-eighth {
    top: 55%;
  }
  .centre-1,
  .centre-2 {
    bottom: 25%;
  }
  .wing-1,
  .wing-2 {
    bottom: 15%;
  }
  .fullback {
    bottom: 10%;
  }

  .prop-1,
  .wing-1 {
    left: 0;
  }
  .prop-2,
  .wing-2 {
    left: 75%;
  }
  .hooker,
  .lock,
  .fullback {
    left: 38.5%;
  }
  .secondrow-1,
  .centre-1 {
    left: 17.5%;
  }
  .secondrow-2,
  .centre-2 {
    left: 60%;
  }
  .halfback {
    left: 27.5%;
  }
  .five-eighth {
    left: 50%;
  }

  .number-circle {
    flex-shrink: 0;
    width: 36px * $number-circle-factor;
    height: 36px * $number-circle-factor;
    padding: 8px * $number-circle-factor;
    margin: 0;

    color: $tint;

    font: 20px * $number-circle-factor Arial, sans-serif;

    &.gold {
      border-color: $awards-primary;
    }

    &.available {
      border-color: $primary-highlight;
      color: $primary-highlight;
    }
  }
}
.next {
  margin: 0 auto;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  width: 50%;
  display: block;
}
</style>
