<template>
  <div>
    <div
      class="d-flex position-box mt-20"
      v-for="(players, index) in playersByPosition"
      :key="index"
    >
      <div
        v-if="homePlayer(players)"
        class="teamlist-profile--home clickable"
        @click.stop="awardPlayer(homePlayer(players).player)"
      >
        <div class="player-headshot">
          <img
            :src="getHeadshot(homePlayer(players).player, true)"
            :alt="playerName(homePlayer(players).player)"
          />
        </div>
        <div class="profile-box">
          <span class="profile-name">
            {{ playerName(homePlayer(players).player) }}
          </span>
          <div class="points-box">
            {{ getPlayerPoint(homePlayer(players).player) }}
          </div>
        </div>
      </div>
      <div v-else class="teamlist-profile--home"></div>
      <div class="teamlist-position">
        <span class="shirt-number">{{ getPlayerNumbers(players) }}</span>
        <span class="position">{{ getPlayerPosition(players) }}</span>
      </div>
      <div
        v-if="awayPlayer(players)"
        class="teamlist-profile--away clickable"
        @click.stop="awardPlayer(awayPlayer(players).player)"
      >
        <div class="profile-box">
          <span class="profile-name">
            {{ playerName(awayPlayer(players).player) }}
          </span>
          <div class="points-box">
            {{ getPlayerPoint(awayPlayer(players).player) }}
          </div>
        </div>
        <div class="player-headshot">
          <img
            :src="getHeadshot(awayPlayer(players).player, false)"
            :alt="playerName(awayPlayer(players).player)"
          />
        </div>
      </div>
      <div v-else class="teamlist-profile--away"></div>
    </div>
  </div>
</template>
<script>
import { positionAbbrev, positionNames } from "../utils/constants";

export default {
  name: "Top3",
  props: ["game", "best"],
  methods: {
    awardPlayer(p) {
      this.$emit("awardPlayer", p);
    },
    getHeadshot(player, isHomeTeam) {
      if (player.teamName)
        isHomeTeam = player.teamName === this.game.homeTeam.name; // fallback code if hometeam boolean is not sent through to function
      const team = this.game.squadData.find(
        (team) => team.isHomeTeam === isHomeTeam,
      );
      const myplayer = team.headshots.find((p) => p._id === player._id);

      return myplayer &&
        myplayer.profile &&
        myplayer.profile.meta &&
        myplayer.profile.meta.avatar &&
        myplayer.profile.meta.avatar.includes("statsperform")
        ? myplayer.profile.meta.avatar
        : require("@/assets/head-shot.png");
    },
    getPlayerAbbrev(player) {
      return positionAbbrev[player.position.name] || "";
    },
    getPlayerNumbers(players) {
      const unique = [
        ...new Set(
          players
            .sort((a, b) => {
              if (a.homeTeam) return -1;
              else return 1;
            })
            .map((profile) => profile.player.shirtNum),
        ),
      ];
      return unique.join(" | ");
    },
    getPlayerPoint(player) {
      const prize = Object.keys(this.best).find(
        (prize) => this.best[prize] && this.best[prize]._id === player._id,
      );
      return prize === "first"
        ? 3
        : prize === "second"
        ? 2
        : prize === "third"
        ? 1
        : "";
    },
    getPlayerPosition(players) {
      const unique = [
        ...new Set(players.map((profile) => profile.player.position.name)),
      ];
      return positionNames[unique[0]];
    },
    awayPlayer(players) {
      const away = players.find((p) => !p.homeTeam);
      return away;
    },
    homePlayer(players) {
      const home = players.find((p) => p.homeTeam);
      return home;
    },
    playerName(player) {
      let { firstName, lastName } = player;
      return `${firstName || ""} ${lastName || ""}`;
    },
  },
  computed: {
    playersByPosition() {
      if (!this.game.squadData || !this.game.squadData.length) return [];
      const playersObj = {};
      this.game.squadData.forEach((team) => {
        const homeTeam = team.isHomeTeam;
        team.players.forEach((player) => {
          if (!playersObj[player.position.order])
            playersObj[player.position.order] = [];
          playersObj[player.position.order].push({
            homeTeam,
            team: team.team,
            player,
          });
        });
      });
      const players = Object.values(playersObj);
      return players;
    },
  },
};
</script>
<style lang="scss" scoped>
.position-box {
  justify-content: center;
  position: relative;

  [class^="teamlist-profile--"] {
    width: 35%;
    display: flex;
    align-items: center;
    min-height: 75px;

    .player-headshot {
      width: 8.75rem;
      position: relative;
      bottom: 0;
      height: 5rem;
      overflow-x: clip;
      position: absolute;
      img {
        position: absolute;
        bottom: 0;
        height: 3.25rem;
        max-width: none;
        z-index: 1;
        @media (min-width: $tablet) {
          height: 4rem;
        }
      }
    }

    .profile-box {
      display: flex;
      align-items: center;
      column-gap: calc(0rem + 180 * (100vw - 320px) / 1280);
      justify-content: flex-end;
      @media (min-width: $tablet) {
        column-gap: 2rem;
      }

      .points-box {
        width: 25px;
        height: 25px;
        background-color: rgb(214, 193, 135);
        color: rgb(120, 100, 46);
        margin: 0 2px;
        border-radius: 5px;
        font-size: 25px;
        display: flex;
        flex-basis: 25px;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        @media (min-width: $tablet) {
          width: 40px;
          height: 40px;
          font-size: 32px;
          flex-basis: 40px;
        }
      }
    }

    .profile-name {
      color: rgb(214, 193, 135);
      font-size: 0.6em;
      z-index: 10;
      width: 60%;

      @media (min-width: $tablet) {
        font-size: 16px;
        width: 50%;
      }
    }
  }
  .teamlist-profile--home {
    justify-content: flex-end;

    .player-headshot {
      left: 0;
      right: auto;
      img {
        right: 0;
        left: calc(-1.5rem + 2.5 * (100vw - 320px) / 180);
        @media (min-width: $tablet) {
          left: 0;
        }
      }
    }

    .profile-name {
      text-align: right;
    }
  }
  .teamlist-profile--away {
    justify-content: flex-start;

    .player-headshot {
      left: auto;
      right: 0;
      img {
        right: calc(-1.5rem + 2.5 * (100vw - 320px) / 180);
        @media (min-width: $tablet) {
          right: 0;
        }
      }
    }

    .profile-box {
      flex-direction: row-reverse;
      .profile-name {
        text-align: left;
      }
    }
  }
  .teamlist-position {
    width: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.125rem;
    padding: 0 4px;

    span {
      color: rgb(214, 193, 135);
      &.shirt-number {
        font-size: 12px;
        @media (min-width: $tablet) {
          font-size: 16px;
        }
      }
      &.position {
        font-size: 0.55rem;
        @media (min-width: $tablet) {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
