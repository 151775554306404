import { render, staticRenderFns } from "./PlayerDetails.vue?vue&type=template&id=6a77ba86&scoped=true&"
import script from "./PlayerDetails.vue?vue&type=script&lang=js&"
export * from "./PlayerDetails.vue?vue&type=script&lang=js&"
import style0 from "./PlayerDetails.vue?vue&type=style&index=0&id=6a77ba86&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a77ba86",
  null
  
)

export default component.exports