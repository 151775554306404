<template>
  <div class="container">
    <div class="head">
      <div class="start">
        <div class="breadcrumb" style="color: rgb(214, 193, 135)">
          <p>{{ $route.meta.header }}</p>
          <span class="slash" style="color: rgb(214, 193, 135)">/</span>
        </div>
      </div>
      <slot name="buttons" v-if="isAuthenticated">
        <div class="buttons">
          <button
            class="loose transparent thin"
            @click="$router.push('/matches')"
          >
            matches
          </button>
          <button class="loose transparent thin" @click="$logout">
            log out
          </button>
        </div>
      </slot>
    </div>
    <div class="body">
      <slot />
    </div>
    <div class="foot">
      <slot name="foot" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  .head {
    min-height: 42px;
    display: flex;
    flex-shrink: 0;
    padding: 8px;
    flex-wrap: wrap;
    row-gap: 10px;

    > * {
      margin-top: auto;
      margin-bottom: auto;
    }

    .start {
      flex-grow: 1;
      text-align: left;
    }
  }
  .body {
    flex-grow: 1;
    // padding: 8px;
  }
  .foot {
    background-color: white;
    margin: 0 -20px;
  }
}
</style>
<script>
export default {
  name: "default",
  computed: {
    isAuthenticated() {
      const loggedIn = this.$store.getters["user/isAuthenticated"];
      return loggedIn;
    },
  },
};
</script>
