import axios from "axios";
import moment from "moment";
import store from "../store";
import router from "../router";
import httpnoauth from "./noauth";

// Require Auth Requests
const httpauth = axios.create({
  baseURL: store.state.root.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
// Handle Refresh Token
httpauth.interceptors.request.use(
  (config) => {
    const originalRequest = config;
    const now = moment().valueOf();
    const expiry = store.getters["user/expiry"];
    const userid = store.getters["user/userid"];

    // Token expired or about to expire
    if (expiry - 300 <= now) {
      const refreshToken = store.getters["user/refreshToken"];
      return httpnoauth
        .post("/nrl/api/v1/elite/auth/authorize", {
          refresh_token: refreshToken,
          user_id: userid,
        })
        .then((response) => {
          store.commit("user/LOGIN", response.data);
          // Update Auth Header
          const token = store.getters["user/accessToken"];
          const authheader = `${userid}:${token}`;
          const encodedString = btoa(authheader);
          originalRequest.headers.Authorization = `Bearer ${encodedString}`;
          originalRequest.headers["active-Role"] =
            store.getters["user/activeRoleId"];
          return originalRequest;
        })
        .catch((e) => {
          store.commit("user/LOGOUT");
          router.go("/");
          return e;
        });
      // eslint-disable-next-line no-else-return
    } else {
      // Update Auth Header
      const accessToken = store.getters["user/accessToken"];
      const authheader = `${userid}:${accessToken}`;
      const encodedString = btoa(authheader);
      originalRequest.headers.Authorization = `Bearer ${encodedString}`;
      originalRequest.headers["active-Role"] =
        store.getters["user/activeRoleId"];
      return originalRequest;
    }
  },
  (err) => Promise.reject(err),
);

// Handle Global Error Handler
// eslint-disable-next-line consistent-return
httpauth.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      // Not Authorized, redirect to login
      store.commit("user/LOGOUT");
      store.commit("root/LOADING", false);
      router.go("/");
    } else {
      return Promise.reject(error);
    }
  },
);

export default httpauth;
