var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.Default,{tag:"component"},[_c('template',{slot:"buttons"},[_c('div',{staticClass:"buttons"},[(_vm.userCheck)?_c('button',{staticClass:"loose transparent thin",on:{"click":_vm.getHistory}},[_vm._v(" admin report ")]):_vm._e(),_c('button',{staticClass:"loose transparent thin",on:{"click":_vm.$logout}},[_vm._v("log out")])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"d-flex filters"},[_c('v-select',{staticClass:"w-45",attrs:{"placeholder":"Select Competition","options":_vm.competitions,"label":"name","clearable":false},on:{"input":_vm.setCompetition},model:{value:(_vm.selectedCompetition),callback:function ($$v) {_vm.selectedCompetition=$$v},expression:"selectedCompetition"}}),_c('v-select',{staticClass:"w-45",attrs:{"placeholder":"Select Round","options":_vm.rounds,"clearable":false},model:{value:(_vm.selectedRound),callback:function ($$v) {_vm.selectedRound=$$v},expression:"selectedRound"}})],1),_c('el-collapse',{staticClass:"mt-20"},[(_vm.periodVotingExists)?_c('el-collapse-item',{attrs:{"title":"Periodic Voting","name":"1"}},_vm._l((_vm.periodVoting),function(p){return _c('div',{key:p._id,staticClass:"clickable",on:{"click":function($event){return _vm.$router.push(
              `/interval-voting?rounds=${p.quarter}&competition=${_vm.selectedCompetition._id}`,
            )}}},[_c('img',{staticClass:"centred",attrs:{"src":require("@/assets/img/logo/dally-m-V2.svg")}}),_c('span',{staticClass:"title"},[_vm._v("Rounds "+_vm._s(p.quarter)+" Voting Open")]),(_vm.periodVotesSubmitted(p.quarter))?_c('span',{staticClass:"badge"},[_vm._v("Votes Submitted")]):_vm._e()])}),0):_vm._e(),(
          _vm.eosCaptainAssignment ||
            _vm.eosCoachAssignment ||
            _vm.eosRookieAssignment ||
            _vm.eosTeamAssignment
        )?_c('el-collapse-item',{attrs:{"title":"End of Season Voting","name":"2"}},_vm._l((_vm.eosAssignedCategories),function(eos){return _c('div',{key:eos._id,staticClass:"clickable",on:{"click":function($event){return _vm.$router.push(
              `/end-of-season-voting/${eos.type}?competition=${_vm.selectedCompetition._id}`,
            )}}},[_c('img',{staticClass:"centred",attrs:{"src":require("@/assets/img/logo/dally-m-V2.svg")}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.textFormat(eos.type))+" Voting Open")]),(_vm.eosVotesSubmitted(eos.type))?_c('span',{staticClass:"badge"},[_vm._v("Votes Submitted")]):_vm._e()])}),0):_vm._e()],1),(_vm.filteredMatches && _vm.filteredMatches.length)?_c('div',{staticClass:"competitions"},_vm._l((_vm.filteredMatches),function(f,index){return _c('div',{key:index,staticClass:"mt-20 clickable",on:{"click":function($event){return _vm.selectMatch(f.match, f.votingOpen)}}},[_c('Match',{attrs:{"place":f.venue ? f.venue.name : '',"team1":f.match && f.match.home ? f.match.home.name : '',"team2":f.match && f.match.away ? f.match.away.name : '',"club1":f.homeClub ? f.homeClub : undefined,"club2":f.awayClub ? f.awayClub : undefined,"score1":f.homeScore,"score2":f.awayScore,"time":_vm.matchTime(f.dateTime),"status":f.matchStatus,"title":`Round ${f.round.number} ${_vm.matchDate(f.dateTime)}`,"votingStatus":f.votingOpen,"votesSubmitted":f.votedOn}})],1)}),0):_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Welcome to the Dally M voting app. You are currently not assigned any matches to vote on. ")])]),_c('div',{staticStyle:{"height":"60px"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }