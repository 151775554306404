import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "@/views/auth/Login";
import Oauth from "../views/auth/Oauth.vue";
import EndOfSeasonVoting from "../views/EndOfYearVoting.vue";
import NotFound from "../views/NotFound";
import DallyM from "../views/DallyM";
import MatchList from "../views/MatchList";
import HowToVote from "../views/HowToVote";
import Interval from "../views/Interval";
import noauth from "../axios/noauth";
Vue.use(VueRouter);

const routes = [
  {
    path: "/matches",
    name: "matches",
    component: MatchList,
    meta: {
      header: "Matches",
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "index",
    meta: {
      displayName: "Login",
      header: "Login",
      // layout: "login"
    },
    component: Login,
  },
  {
    path: "/oauth",
    name: "oauth",

    component: Oauth,
  },
  // {
  //   path: "/match-list",
  //   name: "Match List",
  //   component: MatchList,
  // },
  {
    path: "/match/:id",
    name: "dallym",
    component: () => import("@/views/DallyM"),
    meta: {
      header: "Match Voting",
      requiresAuth: true,
    },
  },
  {
    path: "/how-to-vote",
    name: "how-to-vote",
    component: HowToVote,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/interval-voting",
    name: "interval-voting",
    component: Interval,
    meta: {
      header: "Interval Voting",
      requiresAuth: true,
    },
  },
  {
    path: "/end-of-season-voting/:id",
    name: "end-of-season-voting",
    component: EndOfSeasonVoting,
    meta: {
      header: "End of Season Voting",
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "404",
    meta: {
      displayName: "Page Not Found",
    },
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // if (
  //   to.name !== "login" &&
  //   to.name !== "oauth" &&
  //   store.state.user.authenticated === false
  // )
  //   next("/login");

  // next();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const now = Date.now();
    const expiry = store.getters["user/expiry"];
    const userid = store.getters["user/userid"];
    const isAuthenticated = store.getters["user/isAuthenticated"];
    const refreshToken = store.getters["user/refreshToken"];
    const verified = store.getters["user/isVerified"];

    if (isAuthenticated && expiry - 300 > now) {
      if (!verified) {
        console.log("profile not verified");
        store.commit("user/logout");
        router.push("/");
      } else {
        // Account Verified
        console.log("account verified");
        next();
      }
    } else if (expiry - 300 <= now && refreshToken && userid) {
      // Expired Token
      // Refresh Token before going to page
      noauth
        .post("/nrl/api/v1/elite/authorize", {
          refresh_token: refreshToken,
          user_id: userid,
        })
        .then((response) => {
          // Refresh Successful - Login User
          store.commit("user/login", response.data);
          next();
        })
        .catch(() => {
          // Failed Refresh - Logout User
          store.commit("user/setRedirectUriPath", `${to.fullPath}`);
          store.commit("user/logout");
          router.go("/");
        });
    } else {
      // No Data - Redirect to Login
      store.commit("user/setRedirectUriPath", `${to.fullPath}`);
      store.commit("user/logout");
      next("/");
    }
  } else {
    next();
  }
});

export default router;
