<template>
  <component :is="Default">
    <div class="main mt-20">
      <div>
        <img class="logo" src="@/assets/shield_white.svg" alt="nrl" />
        <div class="title">
          Mysideline AWARDS
        </div>
      </div>
      <button
        class="tw-font-medium tw-tracking-widest tw-uppercase mt-20"
        id="login"
        type="success"
        @click="redirect()"
      >
        LOGIN WITH NRL ACCOUNT
      </button>
      <div class="d-flex support mt-20">
        <a
          href="mailto:feedback@nrl.com.au?subject=NRL Account Support"
          class="subtitle"
          title="NRL Account Support"
          >NRL Account Support</a
        >
      </div>
    </div>
  </component>
</template>

<script>
import Default from "@/layouts/default";
export default {
  name: "Login",
  props: {},
  created() {
    if (this.$store.getters["user/isAuthenticated"]) {
      this.$router.push("/matches");
    }
  },
  data() {
    return {
      Default,
    };
  },
  methods: {
    redirect() {
      const m =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://nrl-qa.nrl.digital/"
          : "https://www.nrl.com/";
      const clientId =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "grassrootstest"
          : "grassroots";
      const redirectUri = process.env.VUE_APP_REDIRECT_URI;
      const url = `/account/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid email profile offline_access verified&response_type=code`;
      const a = new URL(url, m);
      window.location = a;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  padding: 8px;
  .logo {
    max-height: 100%;
    max-width: 200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: $tablet) {
      max-width: 150px;
    }
  }
  .title {
    background: -webkit-linear-gradient(rgba(214, 193, 135), #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    margin-top: 20px;
  }
  #login {
    color: black;
    background-color: rgba(217, 189, 113);
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: 600;
    @media (min-width: $tablet) {
      min-width: 400px;
      width: auto;
    }
  }
  .support {
    justify-content: flex-end;
    margin-bottom: 20px;
    .subtitle {
      color: rgba(217, 189, 113);
    }
  }
}
.wrap {
  height: 117px;
  max-height: 117px;
  text-align: center;
  .logo {
    max-height: 100%;
  }
}

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .login-form {
    text-align: center;
    position: relative;
    width: 400px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .btn-container {
    margin-top: 50px;
  }
  .el-input {
    display: inline-block;
    width: 100%;
  }
  .el-button {
    width: 90%;
    margin-top: 2rem;
  }
  @media only screen and (max-width: $sm) {
    .login-form {
      width: auto;
    }
  }
}
.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>
