<template>
  <component :is="Default">
    <div class="main">
      <!-- dally m header -->
      <div class="title box">
        {{ pageTitle }}
      </div>
      <Match
        v-if="game"
        :place="game.venue ? game.venue.name : ''"
        :team1="game.homeTeam ? game.homeTeam.name : ''"
        :team2="game.awayTeam ? game.awayTeam.name : ''"
        :club1="{
          _id:
            game.homeTeam && game.homeTeam.orgtree && game.homeTeam.orgtree.club
              ? game.homeTeam.orgtree.club._id
              : undefined,
          name:
            game.homeTeam && game.homeTeam.orgtree && game.homeTeam.orgtree.club
              ? game.homeTeam.orgtree.club.name
              : undefined,
        }"
        :club2="{
          _id:
            game.awayTeam && game.awayTeam.orgtree && game.awayTeam.orgtree.club
              ? game.awayTeam.orgtree.club._id
              : undefined,
          name:
            game.awayTeam && game.awayTeam.orgtree && game.awayTeam.orgtree.club
              ? game.awayTeam.orgtree.club.name
              : undefined,
        }"
        :score1="game.scores ? game.scores.homeTeam : undefined"
        :score2="game.scores ? game.scores.awayTeam : undefined"
        :time="matchTime(game.dateTime)"
        :status="game.status"
        :title="`${game.round.displayName} ${matchDate(game.dateTime)}`"
        class="mt-20"
      />
      <!-- match scores -->
      <div v-if="game.squadData && game.squadData.length == 2">
        <ScoreCard :game="game" class="mt-20" />
        <!-- 3 2 1 -->
        <Top3
          v-if="step === 1 && game"
          :game="game"
          :best="best"
          @awardPlayer="awardPlayer"
          @getPlayerPoint="getPlayerPoint"
        />
        <div v-if="step === 1" class="submit-startover">
          <el-button
            class="next"
            type="primary"
            v-if="
              step === 1 &&
                Object.keys(best).every((award) => best[award] !== false)
            "
            @click.stop="next(step)"
          >
            Continue
          </el-button>
        </div>

        <TopTeam
          v-if="step === 2"
          :playerByPosition="playerByPosition"
          :bestPos="bestPos"
          :game="game"
          @handleSelectPosition="handleSelectPosition"
          @pickPlayer="pickPlayer"
        />
        <div v-if="step === 3" class="summary-box mt-20">
          <span class="title">Top 3</span>
          <div
            v-for="(place, index) in Object.keys(best)"
            :key="`${place}-${index}`"
            class="player-box mt-20"
          >
            <div class="player-points">{{ getPlayerPoint(best[place]) }}</div>
            <div class="player-name">{{ playerName(best[place]) }}</div>
            <div class="player-headshot">
              <!-- @click.stop="
                playerDetailModal(best[place], best[place].isHomeTeam)
              " -->
              <img
                :src="getHeadshot(best[place], best[place].isHomeTeam)"
                :alt="playerName(best[place])"
              />
            </div>
          </div>
          <div
            class="mt-20"
            v-if="game.awardsSettings && game.awardsSettings.teamOfYear"
          >
            <span class="title">Best by Position</span>

            <div
              v-for="pos in Object.keys(bestPos)"
              :key="`${bestPos[pos]._id}`"
              class="player-box mt-20"
            >
              <div class="player-position">
                {{ getPlayerAbbrev(bestPos[pos]) }}
              </div>
              <div class="player-name">{{ playerName(bestPos[pos]) }}</div>
              <div class="player-headshot">
                <img
                  :src="getHeadshot(bestPos[pos], bestPos[pos].isHomeTeam)"
                  :alt="playerName(bestPos[pos])"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="step === 3" class="submit-startover">
          <el-checkbox v-model="checked"
            ><span>I agree to Dally M Judge </span>
            <a href="/files/terms.pdf" target="_blank" download
              >Terms of Reference</a
            ></el-checkbox
          >
        </div>

        <div v-if="step === 2 || step === 3" class="submit-startover">
          <el-button
            class="next"
            type="primary"
            v-if="
              step === 1 &&
                Object.keys(best).every((award) => best[award] !== false)
            "
            @click.stop="next(step)"
          >
            Continue
          </el-button>
          <el-button
            type="primary"
            class
            @click.stop="goBack"
            v-if="step === 2 || step === 3"
            >Back</el-button
          >
          <el-button
            type="primary"
            class
            @click.stop="next"
            v-if="readyForSubmit"
            :disabled="step === 3 && !checked"
            >{{ step === 2 ? "Continue" : "Submit" }}</el-button
          >
        </div>
      </div>
      <div class="mt-20" v-else>
        <span class="title">
          Both teams have not yet submitted their final squads
        </span>
      </div>
      <modal
        v-if="selectingPlayer"
        :heading="
          `${selectingPlayer.firstName} ${selectingPlayer.lastName} - ${selectingPlayer.position.name}`
        "
        @close="selectingPlayer = false"
      >
        <player-details :myplayer="selectingPlayer" />
      </modal>
    </div>
  </component>
</template>

<script>
import Default from "@/layouts/default";
import PlayerDetails from "@/components/PlayerDetails.vue";
import ScoreCard from "@/components/ScoreCard";
import Match from "@/components/MatchCard";
import Top3 from "@/components/Top3";
import TopTeam from "@/components/TopTeam";
import Modal from "@/components/Modal.vue";
import moment from "moment";
import { positionAbbrev } from "../utils/constants";

const allPositions = [
  { name: "Full-Back", number: [1], value: "fb", posId: 500001 },
  { name: "Wing", number: [2, 5], value: "winger" },
  { name: "Centre", number: [3, 4], value: "centre" },
  { name: "Five-Eighth", number: [6], value: "fe" },
  { name: "Half-Back", number: [7], value: "hb" },
  { name: "Front-Row", number: [8, 10], value: "prop" },
  { name: "Hooker", number: [9], value: "hooker" },
  { name: "Second Row", number: [11, 12], value: "sr" },
  { name: "Lock", number: [13], value: "lock" },
  { name: "Interchange", number: [14, 15, 16, 17, 99], value: "interchange" },
];

export default {
  name: "DallyM",
  components: {
    Match,
    Modal,
    PlayerDetails,
    ScoreCard,
    Top3,
    TopTeam,
  },
  data() {
    return {
      Default,
      checked: false,
      game: false,
      step: 1,
      best: {
        first: false,
        second: false,
        third: false,
      },
      bestPos: {
        fb: false,
        winger: false,
        centre: false,
        fe: false,
        hb: false,
        prop: false,
        hooker: false,
        sr: false,
        lock: false,
        interchange: false,
      },
      finalLineup: false,
      selectingPlayer: false,
      allPositions,
    };
  },
  async mounted() {
    try {
      this.$store.state.root.loading = true;
      const matchesGet = await this.$http.get("/nrl/api/v1/awards/matches");
      if (!matchesGet.data || !matchesGet.data.data)
        throw "Could not retrieve matches";
      const matches = matchesGet.data.data;
      const authCheck = matches.find(({ match }) => {
        return match.id === parseInt(this.$route.params.id);
      });
      if (!authCheck) {
        this.$store.state.root.loading = false;
        this.$router.push("/matches");
        this.$notify.error({
          title: "Error",
          message: "Not authorised to access this Match",
        });
        return;
      } else {
        const votingStatusClosed = authCheck.status
          .split("_")
          .includes("closed");
        if (votingStatusClosed) {
          this.$notify.error({
            title: "Error",
            message: "Voting for this match has closed",
          });
          this.$store.state.root.loading = false;
          this.$router.push("/matches");
          return;
        }
        const matchid = this.$route.params.id || null;
        const matchRes = await this.$http.get(
          `/nrl/api/v1/awards/matches/${matchid}`,
        );
        this.game = { ...matchRes.data.data };
        if (!this.game.awardsEnabled) {
          this.$notify.error({
            title: "Error",
            message: "This match does not have awards voting enabled",
          });
          this.$store.state.root.loading = false;
          this.$router.push("/matches");
          return;
        }
        this.$store.state.root.loading = false;
      }
    } catch (e) {
      console.log({ e });
      this.$notify.error({
        title: "Error",
        message:
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Could not retrieve match data",
      });
      this.$store.state.root.loading = false;
      this.$router.push("/matches");
    }
  },
  computed: {
    readyForSubmit() {
      return (
        Object.keys(this.bestPos).every((key) => this.bestPos[key] !== false) ||
        (this.game.awardsSettings && !this.game.awardsSettings.teamOfYear)
      );
    },
    pageTitle() {
      if (this.step === 1) return "Player of the Match";
      if (this.step === 2) return "Team of the Match";
      if (this.step === 3) return "Confirm Selections";
    },
    playerByPosition() {
      const allPlayers = this.game.squadData.reduce(
        (acc, current) =>
          acc.concat(
            current.players.map((player) => ({
              ...player,
              isHomeTeam: current.isHomeTeam,
              teamName: current.team.name,
            })),
          ),
        [],
      );
      const allPlayersWithHS = this.game.squadData.reduce(
        (acc, current) => acc.concat(current.headshots),
        [],
      );
      const playersByPosition = allPositions.map((position) => {
        const { name, number, value } = position;
        return {
          name,
          number,
          value,
          players: allPlayers
            .map((player) => {
              const withHeadshot = allPlayersWithHS.find(
                (p) => p._id === player._id,
              );
              return { ...withHeadshot, ...player };
            })
            .filter((player) => number.includes(player.position.number)),
        };
      });
      return playersByPosition;
    },
  },
  methods: {
    startOver() {
      this.best = { first: false, second: false, third: false };
      this.bestPos = {
        fb: false,
        winger: false,
        centre: false,
        fe: false,
        hb: false,
        prop: false,
        hooker: false,
        sr: false,
        lock: false,
        interchange: false,
      };
      this.step = 1;
    },
    matchDate(dateTime) {
      return moment(dateTime).format("dddd Do MMMM");
    },
    matchTime(dateTime) {
      return moment(dateTime).format("h:mm a");
    },
    getHeadshot(player, isHomeTeam) {
      if (player.teamName)
        isHomeTeam = player.teamName === this.game.homeTeam.name; // fallback code if hometeam boolean is not sent through to function
      const team = this.game.squadData.find(
        (team) => team.isHomeTeam === isHomeTeam,
      );
      const myplayer = team.headshots.find((p) => p._id === player._id);

      return myplayer &&
        myplayer.profile &&
        myplayer.profile.meta &&
        myplayer.profile.meta.avatar &&
        myplayer.profile.meta.avatar.includes("statsperform")
        ? myplayer.profile.meta.avatar
        : require("@/assets/body-shot.png");
    },
    playerName(player) {
      let { firstName, lastName } = player;
      lastName = ` ${lastName}`;
      const name = firstName + lastName;
      return name;
    },
    playerDetailModal(player, isHomeTeam) {
      const team = this.game.squadData.find(
        (team) => team.isHomeTeam === isHomeTeam,
      );
      const myplayer = team.players.find((p) => p._id === player._id);
      this.selectingPlayer = { ...player, ...myplayer };
    },
    getPlayerPoint(player) {
      const prize = Object.keys(this.best).find(
        (prize) => this.best[prize] && this.best[prize]._id === player._id,
      );
      return prize === "first"
        ? 3
        : prize === "second"
        ? 2
        : prize === "third"
        ? 1
        : "";
    },
    getPlayerAbbrev(player) {
      return positionAbbrev[player.position.name] || "";
    },
    handleSelectPosition(position) {
      if (this.bestPos[position].locked !== true) {
        this.bestPos[position] = false;
      }
    },
    awardPlayer(selectedPlayer, place) {
      const hasFirst = this.best.first !== false;
      const hasSecond = this.best.second !== false;
      const hasThird = this.best.third !== false;
      const allPlayersWithHS = this.game.squadData.reduce(
        (acc, current) =>
          acc.concat(
            current.players.map((p) => ({
              ...p,
              teamName: current.team.name,
            })),
          ),
        [],
      );
      const playerDetails =
        allPlayersWithHS.find((p) => p._id === selectedPlayer._id) || {};
      const player = { ...playerDetails, ...selectedPlayer };
      const prizeWithPlayerName = Object.keys(this.best).find(
        (prize) => this.best[prize] && this.best[prize]._id === player._id,
      );
      const hasAll = hasFirst && hasSecond && hasThird;
      if (prizeWithPlayerName && !hasAll) {
        if (prizeWithPlayerName === "first") {
          this.best.first = false;
          this.best.second = player;
        } else if (prizeWithPlayerName === "second") {
          this.best.second = false;
          this.best.third = player;
        } else if (prizeWithPlayerName === "third") {
          this.best.third = false;
        }
      } else if (prizeWithPlayerName && hasAll) {
        this.best.first = player;
        this.best.second = false;
        this.best.third = false;
      } else {
        const nextPrize = Object.keys(this.best).find(
          (prize) => this.best[prize] === false,
        );
        if (nextPrize) {
          this.best[nextPrize] = player;
        } else {
          this.best.first = player;
          this.best.second = false;
          this.best.third = false;
        }
      }
    },
    pickPlayer(player, positionValue) {
      const position = this.allPositions.find(
        (pos) => pos.value === positionValue,
      );
      this.$set(this.bestPos, positionValue, player);
    },
    next(step) {
      // step
      if (step <= 2) window.scrollTo(0, 0);
      if (this.step === 1) {
        this.bestPos = {
          fb: false,
          winger: false,
          centre: false,
          fe: false,
          hb: false,
          prop: false,
          hooker: false,
          sr: false,
          lock: false,
          interchange: false,
        };
        Object.keys(this.best).forEach((prize) => {
          const player = this.best[prize];
          const position = this.allPositions.find((position) =>
            position.number.includes(player.position.number),
          );
          const bestOfThisPrice = Object.keys(this.best)
            .filter((prize) => {
              return (
                this.best[prize].position.name === player.position.name ||
                position.number.includes(this.best[prize].position.number)
              );
            })
            .sort((a, b) => {
              const ranking = { first: 3, second: 2, third: 1 };
              return ranking[a] > ranking[b]
                ? -1
                : ranking[a] < ranking[b]
                ? 1
                : 0;
            });

          const best =
            bestOfThisPrice.length <= 1
              ? player
              : this.best[bestOfThisPrice[0]];

          this.bestPos[position.value] = {
            ...best,
            locked: true,
          };
        });

        this.step =
          !this.game.awardsSettings ||
          (this.game.awardsSettings && this.game.awardsSettings.teamOfYear)
            ? 2
            : 3;
      } else if (this.step === 2) {
        this.step = 3;
      } else if (this.step === 3) {
        this.$confirm(
          "Are you sure you want to submit your votes?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            showClose: false,
            iconClass: "hideIcon",
          },
        )
          .then(() => {
            const roster = [];
            this.game.squadData.forEach((squad) => {
              const { team } = squad;
              squad.players.forEach((player) => {
                roster.push({
                  name: `${player.firstName} ${player.lastName}`,
                  league_net_id: player._id.toString(),
                  position: player.position.name.includes("2nd")
                    ? "secondrow"
                    : player.position.name.toLowerCase().replace("-", ""),
                  team: team.name,
                  player_position_id: player._id.toString(),
                  shirt_number: parseInt(player.shirtNum),
                });
              });
            });

            const vote = {
              createdAt: moment.now(),
              user: {
                user_id: this.$store.getters["user/userid"],
                name: this.$store.getters["user/userName"],
              },
              fixture: {
                game_id: this.game._id,
                round: {
                  displayName: this.game.round.displayName,
                  number: this.game.round.number,
                },
                title:
                  this.game.homeTeam.name + " v " + this.game.awayTeam.name,
                home_team_id: this.game.homeTeam._id,
                home_team: this.game.homeTeam.name,
                away_team_id: this.game.awayTeam._id,
                away_team: this.game.awayTeam.name,
                comp: {
                  name: this.game.competition.name,
                  _id: this.game.competition._id,
                  awardsSettings: this.game.awardsSettings,
                },
                venue: this.game.venue.name,
                date:
                  moment(this.game.dateTime).format(
                    "ddd MMM DD YYYY HH:mm:ss",
                  ) + " AEDT",
              },
              roster,
              dallyMPlayerVotes: [
                {
                  name: `${this.best.first.firstName} ${this.best.first.lastName}`,
                  _id: this.best.first._id,
                  position: this.best.first.position.name.includes("2nd")
                    ? "secondrow"
                    : this.best.first.position.name
                        .toLowerCase()
                        .replace("-", ""),
                  team: this.best.first.teamName,
                  team_id:
                    this.game.homeTeam.name === this.best.first.teamName
                      ? this.game.homeTeam._id
                      : this.game.awayTeam._id,
                  votes: 3,
                },
                {
                  name: `${this.best.second.firstName} ${this.best.second.lastName}`,
                  _id: this.best.second._id,
                  position: this.best.second.position.name.includes("2nd")
                    ? "secondrow"
                    : this.best.second.position.name
                        .toLowerCase()
                        .replace("-", ""),
                  team: this.best.second.teamName,
                  team_id:
                    this.game.homeTeam.name === this.best.second.teamName
                      ? this.game.homeTeam._id
                      : this.game.awayTeam._id,
                  votes: 2,
                },
                {
                  name: `${this.best.third.firstName} ${this.best.third.lastName}`,
                  _id: this.best.third._id,
                  position: this.best.third.position.name.includes("2nd")
                    ? "secondrow"
                    : this.best.third.position.name
                        .toLowerCase()
                        .replace("-", ""),
                  team: this.best.third.teamName,
                  team_id:
                    this.game.homeTeam.name === this.best.third.teamName
                      ? this.game.homeTeam._id
                      : this.game.awayTeam._id,
                  votes: 1,
                },
              ],
            };

            if (
              this.game.awardsSettings &&
              this.game.awardsSettings.teamOfYear
            ) {
              vote.dallyMTeamVotes = [
                ...Object.keys(this.bestPos).map((position) => ({
                  name: `${this.bestPos[position].firstName} ${this.bestPos[position].lastName}`,
                  _id: this.bestPos[position]._id,
                  position: this.bestPos[position].position.name.includes(
                    "Five",
                  )
                    ? "fiveeighth"
                    : this.bestPos[position].position.name.includes("2nd")
                    ? "secondrow"
                    : this.bestPos[position].position.name.toLowerCase(),
                  number: this.bestPos[position].position.number,
                  team: this.bestPos[position].teamName,
                  team_id:
                    this.game.homeTeam.name === this.bestPos[position].teamName
                      ? this.game.homeTeam._id
                      : this.game.awayTeam._id,
                  votes: 1,
                })),
              ];

              // Adding positional votes
              vote.dallyMPlayerVotes.forEach((player) => {
                vote.dallyMTeamVotes = vote.dallyMTeamVotes.map((p) => {
                  if (p.name === player.name) p.votes = player.votes;
                  return p;
                });
              });
            }

            this.$store.state.root.loading = true;
            this.$http
              .post("/nrl/api/v1/awards/vote", { vote })
              .then(() => {
                this.$notify.success({
                  title: "Success",
                  message: "Votes submitted successfully.",
                  showClose: false,
                  iconClass: "hideIcon",
                });
                this.$router.push("/matches");
                this.$store.state.root.loading = false;
              })
              .catch((e) => {
                this.$notify.error({
                  title: "Error",
                  message: e.response.data.message,
                });
                this.$router.push("/matches");
                this.$store.state.root.loading = false;
              });
          })
          .catch(() => {});
      }
    },
    goBack() {
      const { awardsEnabled, awardsSettings } = this.game;
      const { step } = this;
      const goToStep =
        awardsEnabled && awardsSettings.teamOfYear ? step - 1 : 1;
      this.step = goToStep;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.main {
  padding-top: 1rem;

  .submit-startover {
    margin: 0 auto;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    .el-button {
      margin: 0 auto;
      margin-top: 1rem;
      width: 50%;
      display: block;
    }
    span {
      color: #fff;
    }
    a {
      color: #fff;
      font-weight: bold;
    }
  }

  .next {
    margin: 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .title {
    text-align: center;
    letter-spacing: 0.046rem;
    font-weight: 600;
    margin: 0 auto;
    color: rgb(214, 193, 135);
    text-transform: capitalize;
  }

  .box {
    padding: 8px;
  }

  .select-position {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .player-box {
      background-color: #000;
      box-shadow: 0 4px 6px -5px rgb(214 193 135);
      .player-name {
        color: #fff;
      }
      .player-position {
        border-radius: 5px;
      }
    }
  }

  .player-box {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    width: auto;
    height: 5rem;
    overflow-x: auto;
    overflow-y: hidden;
    text-align: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    color: rgb(120, 100, 46);
    font-weight: 500;
    border-radius: 5px;
    background-color: rgb(214, 193, 135);
    cursor: pointer;
    position: relative;
    @media (min-width: $tablet) {
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
      width: 100%;
    }

    * {
      margin-left: 0.25rem;
    }

    .player-headshot {
      width: 8.75rem;
      position: relative;
      bottom: 0;
      height: 5rem;
      left: 0;
      overflow-x: hidden;
      position: absolute;
      left: auto;
      right: 0;
      img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 5rem;
        max-width: none;
        @media (min-width: $tablet) {
          left: 0;
        }
      }
    }

    .player-position {
      background-color: rgb(214, 193, 135);
      text-align: center;
      width: 25%;
      overflow: hidden;
      padding: 0;
      font-size: 1.2rem;
    }

    .player-points {
      width: 15%;
      height: 50%;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
    }

    .player-name {
      width: 25%;
      font-size: 0.7rem;

      @media (min-width: $tablet) {
        font-size: 14px;
      }
    }
  }
}
</style>
