import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import jwtDecode from "jwt-decode";

Vue.use(Vuex);

const root = {
  namespaced: true,
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    loading: false,
    rootLoadingMessage: "",
    maintenanceBegin: 1637233200000,
    maintenanceEnd: 1637278200000,
    maintenanceCode: "snapcrackle",
    s3Url: process.env.VUE_APP_S3,
  },
  mutations: {
    loading(state, update) {
      if (typeof update === "boolean") {
        state.rootLoading = update;
        if (update === false) state.rootLoadingMessage = "";
      } else {
        state.rootLoading = true;
        state.rootLoadingMessage = update;
      }
    },
  },
  actions: {},
  getters: {
    rootLoading(state) {
      return state.rootLoading;
    },
    rootLoadingMessage(state) {
      return state.rootLoadingMessage;
    },
    apiUrl(state) {
      return state.apiUrl;
    },
    redirectUri(state) {
      return state.redirectUri;
    },

    maintenanceBegin(state) {
      return state.maintenanceBegin;
    },
    maintenanceEnd(state) {
      return state.maintenanceEnd;
    },
    maintenanceCode(state) {
      return state.maintenanceCode;
    },
  },
};

const app = {
  namespaced: true,
  state: {
    appLoading: false,
    previousPath: "/",
  },
  mutations: {
    loading(state, update) {
      state.appLoading = update;
    },
    setPath(state, update) {
      state.previousPath = update;
    },
  },
  getters: {
    appLoading(state) {
      return state.appLoading;
    },
    getPreviousPath(state) {
      return state.previousPath;
    },
  },
};

const competition = {
  namespaced: true,
  state: {
    currentCompetition: undefined,
  },
  mutations: {
    CURRENT_COMPETITION(state, update) {
      state.currentCompetition = update;
    },
  },
  getters: {
    getCurrentCompetition(state) {
      return state.currentCompetition;
    },
  },
};


const user = {
  namespaced: true,
  state: {
    redirectUriPath: "/",
    ignoreResponsiveWarning: false,
    authenticated: false,
    activeRole: false,
    tokens: {},
    profile: {},
    maintenanceCleared: false,
  },
  mutations: {
    setRedirectUriPath(state, update) {
      state.redirectUriPath = update;
    },

    DEBUGGING(state, update) {
      state.maintenanceCleared = update;
    },
    ignoreWarning(state, update) {
      state.ignoreResponsiveWarning = update;
    },

    login(state, tokens) {
      const updateuser = jwtDecode(tokens.id_token);
      state.profile = updateuser;
      state.tokens = tokens;
      state.authenticated = true;

      if (updateuser.roles && updateuser.roles.length > 0) {
        // check if current active role is available
        const filteredByCurrent = updateuser.roles.filter(
          (role) => role._id === state.activeRole._id,
        );
        if (filteredByCurrent.length > 0) {
          state.activeRole = filteredByCurrent[0];
        } else {
          state.activeRole = updateuser.roles[0];
        }
      }

      // state.activeRole =
      //   updateuser.roles && updateuser.roles.length > 0 ? updateuser.roles[0] : false;
    },
    logout(state) {
      state.ignoreResponsiveWarning = false;
      state.authenticated = false;
      state.tokens = {};
      state.profile = {};
      state.activeRole = false;
    },
    switchRole(state, role) {
      state.activeRole = role;
      Sentry.setUser({
        nrl_id: state.profile ? state.profile._id : "",
        nrl_role: state.activeRole || "",
      });
    },
    updateRoles(state, roles) {
      state.profile.roles = roles;
    },
    updateVerification(state, status) {
      state.profile.verified = status;
    },
  },
  actions: {},
  getters: {
    redirectUriPath(state) {
      return state.redirectUriPath;
    },
    maintenanceCleared(state) {
      return state.maintenanceCleared;
    },
    ignoreResponsiveWarning(state) {
      return state.ignoreResponsiveWarning;
    },
    isAuthenticated(state) {
      return state.authenticated;
    },
    userid(state) {
      return state.profile._id;
    },
    isVerified(state) {
      return state.profile.verified;
    },
    email(state) {
      return state.profile.email;
    },
    expiry(state) {
      return state.tokens.expires_in;
    },
    accessToken(state) {
      return state.tokens.access_token;
    },
    refreshToken(state) {
      return state.tokens.refresh_token;
    },
    userName(state) {
      return `${state.profile.firstName} ${state.profile.lastName}`;
    },
    activeRole(state) {
      return state.activeRole;
    },
    activeRoleId(state) {
      return state.activeRole._id;
    },
    roles(state) {
      return state.profile.roles;
    },
    uploadHeaders(state) {
      const { profile, tokens, activeRole } = state;
      const authheader = `${profile._id}:${tokens.access_token}`;
      const encodedString = btoa(authheader);
      const Authorization = `Bearer ${encodedString}`;
      return { Authorization, "active-role": activeRole._id };
    },
    assignedMatches(state) {
      return state.profile.assignedAwardsMatches;
    },
  },
};

const vuexLocal = new VuexPersistence({
  key: "nrl-user",
  storage: window.localStorage,
  modules: ["user"],
});

const store = new Vuex.Store({
  modules: {
    root,
    user,
    app,
    competition,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
