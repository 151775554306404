<template>
  <el-container v-loading.fullscreen.lock="loading"> </el-container>
</template>

<script>
export default {
  name: "Oauth",
  created() {
    if (!this.$route.query.code) {
      if (this.$store.getters["user/isAuthenticated"]) {
        this.$router
          .push("/matches")
          .catch(() => console.log("--- redirected ---"));
      } else {
        this.$router.push("/");
      }
    } else {
      // we have a code to use
      this.$noauth
        .post(`/nrl/api/v1/awards/login?code=${this.$route.query.code}`, {})
        .then((response) => {
          this.$store.commit("user/login", response.data);
          const path = this.$store.getters["user/redirectUriPath"];
          this.$router
            .push(path)
            .catch(() => console.log("--- redirected after oauth---"));
        })
        .catch((e) => {
          this.$notify("Not Authorized");
          console.log(e);
          this.$router.push("/");
        });
    }
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>

<style scoped lang="scss"></style>
