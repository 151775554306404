<template>
  <el-collapse>
    <el-collapse-item title="Scoring Summary" name="1">
      <div>
        <el-row :gutter="2">
          <el-col :span="8">
            <h5 class="label">{{ homeTries }}</h5>
            <h5
              v-for="homeTry in scoringEvents(true, 'Try')"
              :key="homeTry._id"
              class="label right"
            >
              {{ `${homeTry.shirtNum}. ${homeTry.name} (${homeTry.time}')` }}
            </h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">Tries</h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">{{ awayTries }}</h5>
            <h5
              v-for="awayTry in scoringEvents(false, 'Try')"
              :key="awayTry._id"
              class="label left"
            >
              {{ `${awayTry.shirtNum}. ${awayTry.name} (${awayTry.time}')` }}
            </h5>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="8">
            <h5 class="label">
              {{ homeConversions }} /
              {{ scoringEventsAttempts(true, "Conversion") }}
            </h5>
            <h5
              v-for="homeConv in scoringEvents(true, 'Conversion Successful')"
              :key="homeConv._id"
              class="label right"
            >
              {{ `${homeConv.shirtNum}. ${homeConv.name} (${homeConv.time}')` }}
            </h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">Conversions</h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">
              {{ awayConversions }} /
              {{ scoringEventsAttempts(false, "Conversion") }}
            </h5>
            <h5
              v-for="awayConv in scoringEvents(false, 'Conversion Successful')"
              :key="awayConv._id"
              class="label left"
            >
              {{ `${awayConv.shirtNum}. ${awayConv.name} (${awayConv.time}')` }}
            </h5>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="8">
            <h5 class="label">
              {{ homePenaltyGoals }} /
              {{ scoringEventsAttempts(true, "Penalty Goal") }}
            </h5>
            <h5
              v-for="homePenGoal in scoringEvents(
                true,
                'Penalty Goal Successful',
              )"
              :key="homePenGoal._id"
              class="label right"
            >
              {{
                `${homePenGoal.shirtNum}. ${homePenGoal.name} (${homePenGoal.time}')`
              }}
            </h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">Penalty Goals</h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">
              {{ awayPenaltyGoals }} /
              {{ scoringEventsAttempts(false, "Penalty Goal") }}
            </h5>
            <h5
              v-for="awayPenGoal in scoringEvents(
                false,
                'Penalty Goal Successful',
              )"
              :key="awayPenGoal._id"
              class="label left"
            >
              {{
                `${awayPenGoal.shirtNum}. ${awayPenGoal.name} (${awayPenGoal.time}')`
              }}
            </h5>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="8">
            <h5 class="label">
              {{ home1ptFieldGoals }} /
              {{ scoringEventsAttempts(true, "1pt Field Goal") }}
            </h5>
            <h5
              v-for="home1PtFg in scoringEvents(
                true,
                '1pt Field Goal Successful',
              )"
              :key="home1PtFg._id"
              class="label right"
            >
              {{
                `${home1PtFg.shirtNum}. ${home1PtFg.name} (${home1PtFg.time}')`
              }}
            </h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">
              1 Point Field Goals
            </h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">
              {{ away1ptFieldGoals }} /
              {{ scoringEventsAttempts(false, "1pt Field Goal") }}
            </h5>
            <h5
              v-for="away1PtFg in scoringEvents(
                false,
                '1pt Field Goal Successful',
              )"
              :key="away1PtFg._id"
              class="label left"
            >
              {{
                `${away1PtFg.shirtNum}. ${away1PtFg.name} (${away1PtFg.time}')`
              }}
            </h5>
          </el-col>
        </el-row>
        <el-row :gutter="2">
          <el-col :span="8">
            <h5 class="label">
              {{ home2ptFieldGoals }} /
              {{ scoringEventsAttempts(true, "2pt Field Goal") }}
            </h5>
            <h5
              v-for="home2PtFg in scoringEvents(
                true,
                '2pt Field Goal Successful',
              )"
              :key="home2PtFg._id"
              class="label left"
            >
              {{
                `${home2PtFg.shirtNum}. ${home2PtFg.name} (${home2PtFg.time}')`
              }}
            </h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">2 Point Field Goals</h5>
          </el-col>
          <el-col :span="8">
            <h5 class="label">
              {{ away2ptFieldGoals }} /
              {{ scoringEventsAttempts(false, "2pt Field Goal") }}
            </h5>
            <h5
              v-for="away2PtFg in scoringEvents(
                false,
                '2pt Field Goal Successful',
              )"
              :key="away2PtFg._id"
              class="label left"
            >
              {{
                `${away2PtFg.shirtNum}. ${away2PtFg.name} (${away2PtFg.time}')`
              }}
            </h5>
          </el-col>
        </el-row>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
export default {
  name: "Score",
  props: ["game"],
  methods: {
    scoringEvents(isHome, event) {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const eventmap = this.game.scoringEvents.filter((e) => {
        return e.isHomeTeam === isHome && e.name === event;
      });
      const events = eventmap.map((t) => {
        if (!t.meta) return;
        const time = t.sec > 30 ? t.min + 1 : t.min;
        return {
          _id: t._id,
          name: `${
            t.meta.player && t.meta.player.firstName
              ? t.meta.player.firstName
              : ""
          } ${
            t.meta.player && t.meta.player.lastName
              ? t.meta.player.lastName
              : ""
          }`,
          shirtNum:
            t.meta.player && t.meta.player.shirtNum
              ? t.meta.player.shirtNum
              : "",
          time,
        };
      });
      return events;
    },
    scoringEventsAttempts(isHome, event) {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const events = this.game.scoringEvents.filter((e) => {
        return e.isHomeTeam === isHome && e.name.includes(event);
      });
      return events.length || 0;
    },
  },
  computed: {
    homeTries() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const tries = this.game.scoringEvents.filter((e) => {
        return e.isHomeTeam && e.name === "Try";
      }).length;
      return tries;
    },
    awayTries() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const tries = this.game.scoringEvents.filter((e) => {
        return !e.isHomeTeam && e.name === "Try";
      }).length;
      return tries;
    },
    homeConversions() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const homeSuccessfulConversions = this.game.scoringEvents.filter(
        (event) => event.isHomeTeam && event.name === "Conversion Successful",
      ).length;
      return homeSuccessfulConversions;
    },
    awayConversions() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const awaySuccessfulConversions = this.game.scoringEvents.filter(
        (event) => !event.isHomeTeam && event.name === "Conversion Successful",
      ).length;
      return awaySuccessfulConversions;
    },
    homePenaltyGoals() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const homePenaltyGoals = this.game.scoringEvents.filter(
        (event) => event.isHomeTeam && event.name === "Penalty Goal Successful",
      ).length;
      return homePenaltyGoals;
    },
    awayPenaltyGoals() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const awayPenaltyGoals = this.game.scoringEvents.filter(
        (event) =>
          !event.isHomeTeam && event.name === "Penalty Goal Successful",
      ).length;
      return awayPenaltyGoals;
    },
    home1ptFieldGoals() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const homeFieldGoals = this.game.scoringEvents.filter(
        (event) =>
          event.isHomeTeam && event.name === "1pt Field Goal Successful",
      ).length;
      return homeFieldGoals;
    },
    home2ptFieldGoals() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const homeTwoPtFieldGoals = this.game.scoringEvents.filter(
        (event) =>
          event.isHomeTeam && event.name === "2pt Field Goal Successful",
      ).length;
      return homeTwoPtFieldGoals;
    },
    away1ptFieldGoals() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const awayFieldGoals = this.game.scoringEvents.filter(
        (event) =>
          !event.isHomeTeam && event.name === "1pt Field Goal Successful",
      ).length;
      return awayFieldGoals;
    },
    away2ptFieldGoals() {
      if (!this.game) return 0;
      if (!this.game.scoringEvents) return 0;
      const awayTwoPtFieldGoals = this.game.scoringEvents.filter(
        (event) =>
          !event.isHomeTeam && event.name === "2pt Field Goal Successful",
      ).length;
      return awayTwoPtFieldGoals;
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  font-size: 0.6rem;
  @media (min-width: $tablet) {
    font-size: 12px;
  }
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
</style>
