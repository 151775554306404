import axios from "axios";

// Open Auth Requests
const httpnoauth = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*"
  },
});

export default httpnoauth;
