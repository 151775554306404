export const nrlScoring = [
  { name: "Try", type: 38, value: 4 },
  { name: "Conversion Successful", type: 1, value: 2 },
  { name: "Conversion Unsuccessful", type: 2, value: 0 },
  { name: "Penalty Goal Successful", type: 67, value: 2 },
  { name: "Penalty Goal Unsuccessful", type: 68, value: 0 },
  { name: "Field Goal Successful", type: 69, value: 1 },
  { name: "Field Goal Unsuccessful", type: 70, value: 0 },
  { name: "Penalty Try", type: 99, value: 4 }, // Sent as 38 to STATS
];

export const gamePeriods = [
  { name: "First Half", type: 1 },
  { name: "Second Half", type: 2 },
  { name: "Extra Time", type: 3 },
];

// export const nrlTeams = {
//   "380533": ["broncos", "broncos-badge.svg"],
//   "380534": ["raiders", "raiders-badge.svg"],
//   "380535": ["bulldogs", "bulldogs-badge.svg"],
//   "380536": ["sharks", "sharks-badge.svg"],
//   "380537": ["titans", "titans-badge.svg"],
//   "380538": ["sea-eagles", "sea-eagles-badge.svg"],
//   "380539": ["storm", "storm-badge.svg"],
//   "380541": ["knights", "knights-badge.svg"],
//   "380540": ["warriors", "warriors-badge.svg"],
//   "380542": ["cowboys", "cowboys-badge.svg"],
//   "380543": ["eels", "eels-badge.svg"],
//   "380544": ["panthers", "panthers-badge.svg"],
//   "380545": ["rabbitohs", "rabbitohs-badge.svg"],
//   "380546": ["dragons", "dragons-badge.svg"],
//   "380547": ["roosters", "roosters-badge.svg"],
//   "380548": ["wests-tigers", "wests-tigers-badge.svg"],
// };

// export const testTeams = {
//   "314136": ["raiders", "raiders-badge.svg"],
//   "314135": ["sharks", "sharks-badge.svg"],
//   "314137": ["titans", "titans-badge.svg"],
//   "314138": ["wests-tigers", "wests-tigers-badge.svg"],
// };

export const positionAbbrev = {
  "Full-Back": "FB",
  Fullback: "FB",
  Wing: "WG",
  Winger: "WG",
  Centre: "C",
  "Five-Eighth": "FE",
  "Half-Back": "HB",
  Halfback: "HB",
  Prop: "PR",
  "Front-Row": "PR",
  "Fron-Row": "PR",
  Hooker: "HK",
  "Second Row": "SR",
  "2nd Row": "SR",
  Lock: "LK",
  Interchange: "INT",
  "18th Player": "18TH",
  "18th Man": "18TH",
  "Replacement Player": "18TH",
};

export const positionNames = {
  "Full-Back": "Fullback",
  Fullback: "Fullback",
  Wing: "Wing",
  Winger: "Wing",
  Centre: "Centre",
  "Five-Eighth": "Five Eighth",
  "Half-Back": "Halfback",
  Halfback: "Halfback",
  Prop: "Prop",
  "Front-Row": "Prop",
  "Fron-Row": "Prop",
  Hooker: "Hooker",
  "Second Row": "Second Row",
  "2nd Row": "Second Row",
  Lock: "Lock",
  Interchange: "Interchange",
  "18th Player": "Replacement Player",
  "18th Man": "Replacement Player",
  "Replacement Player": "Replacement Player",
};

export const intervalPosArr = [
  { type: "Fullback", name: "Fullback" },
  { type: "Wing-1", name: "Winger" },
  { type: "Centre-1", name: "Centre" },
  { type: "Centre-2", name: "Centre" },
  { type: "Wing-2", name: "Winger" },
  { type: "Five-Eighth", name: "Five-Eighth" },
  { type: "Halfback", name: "Halfback" },
  { type: "Prop-1", name: "Prop" },
  { type: "Hooker", name: "Hooker" },
  { type: "Prop-2", name: "Prop" },
  { type: "SecondRow-1", name: "Second Row" },
  { type: "SecondRow-2", name: "Second Row" },
  { type: "Lock", name: "Lock" },
];
export const interchange = {
  test: {
    324353: {
      "1-7": {
        Fullback: [],
        Centre: [
          {
            name: "Corey Allan",
            playerId: 2016745,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Joseph Suaalii",
            playerId: 2180293,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Jackson Topine",
            playerId: 2243207,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Tommy Talau",
            playerId: 2590594,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
        Winger: [
          {
            name: "Tyran Wishart",
            playerId: 788147,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Brian To'o",
            playerId: 1863952,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
        ],
        "Five-Eighth": [],
        Halfback: [],
        Prop: [
          {
            name: "Alec Macdonald",
            playerId: 3035645,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Alex Twal",
            playerId: 79833,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Bunty Afoa",
            playerId: 6235185,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Ethan BULLEMOR",
            playerId: 2037896,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Emre Guler",
            playerId: 2206932,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Herman Ese'ese",
            playerId: 2265623,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Jai Arrow",
            playerId: 1718077,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Jack Hetherington",
            playerId: 774306,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Joshua Kerr",
            playerId: 2032577,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Jack Murchie",
            playerId: 96710,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Jarrod Wallace",
            playerId: 2139072,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Keenan Palasia",
            playerId: 2129046,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Lindsay Smith",
            playerId: 826971,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Mitch Barnett",
            playerId: 1067416,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Matt Lodge",
            playerId: 68544,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Makahesi Makatoa",
            playerId: 2452202,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Mark Nicholls",
            playerId: 177061,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Martin Taupau",
            playerId: 2227970,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Nelson Asofa-Solomona",
            playerId: 2544502,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Ofahiki Ogden",
            playerId: 2261706,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Royce Hunt",
            playerId: 2541943,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Ryan Sutton",
            playerId: 3473701,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Shawn Blore",
            playerId: 1274552,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Sean Keppie",
            playerId: 23645,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Spencer Leniu",
            playerId: 2183365,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Shaquai Mitchell",
            playerId: 1617738,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Sitili Tupouniua",
            playerId: 2778103,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Tom Ale",
            playerId: 2461856,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Toby Couchman",
            playerId: 2345823,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Thomas Hazelton",
            playerId: 2872103,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Tariq Sims",
            playerId: 81104,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Tevita Tatola",
            playerId: 47880,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
        ],
        Hooker: [
          {
            name: "Jake Turpin",
            playerId: 1301229,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Kaeo Weekes",
            playerId: 2161563,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Mason Teague",
            playerId: 2215186,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Phoenix Crossland",
            playerId: 2131277,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Ray Stone",
            playerId: 1935879,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Soni Luke",
            playerId: 109013,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Tom Starling",
            playerId: 102747,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
        ],
        "Second Row": [
          {
            name: "Jayden Okunbor",
            playerId: 116857,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Jack Williams",
            playerId: 138208,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
        ],
        Lock: [
          {
            name: "Kobe Hetherington",
            playerId: 361714,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
        ],
      },
      "10-17": {
        Fullback: [
          {
            name: "Kalyn PONGA",
            playerId: 2642781,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
        ],
        Centre: [
          // extras added in
          {
            name: "Corey Allan",
            playerId: 2016745,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Joseph Suaalii",
            playerId: 2180293,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Jackson Topine",
            playerId: 2243207,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Tommy Talau",
            playerId: 2590594,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
        Winger: [
          {
            name: "Marcelo Montoya",
            playerId: 1415542,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          // extras added in
          {
            name: "Tyran Wishart",
            playerId: 788147,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Brian To'o",
            playerId: 1863952,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
        ],
        "Five-Eighth": [
          {
            name: "Drew HUTCHISON",
            playerId: 76509,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Halfback: [
          {
            name: "Jayden SULLIVAN",
            playerId: 2121211,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
        ],
        Prop: [
          {
            name: "Nathan BROWN",
            playerId: 1616868,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Lindsay Smith",
            playerId: 826971,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Ethan BULLEMOR",
            playerId: 2037896,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Jack WILLIAMS",
            playerId: 138208,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Makahesi MAKATOA",
            playerId: 2452202,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Jamayne TAUNOA-BROWN",
            playerId: 952136,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Emre GULER",
            playerId: 2206932,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Kobe HETHERINGTON",
            playerId: 361714,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Mitchell BARNETT",
            playerId: 1067416,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Dylan WALKER",
            playerId: 180051,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Joshua ALOIAI",
            playerId: 2237423,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Pasami SAULO",
            playerId: 82472,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Spencer Leniu",
            playerId: 2183365,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Thomas BURGESS",
            playerId: 2832239,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Thomas EISENHUTH",
            playerId: 164487,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Martin TAUPAU",
            playerId: 2227970,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Matthew LODGE",
            playerId: 68544,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Liam MARTIN",
            playerId: 150222,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Thomas HAZELTON",
            playerId: 2872103,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Kulikefu FINEFEUIAKI",
            playerId: 3158155,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Jayden OKUNBOR",
            playerId: 116857,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Mark NICHOLLS",
            playerId: 177061,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Xavier WILLISON",
            playerId: 2773951,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Daniel SULUKA FIFITA",
            playerId: 705384,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Jaimin JOLLIFFE",
            playerId: 63312,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            name: "Tom ALE",
            playerId: 2461856,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Herman ESE'ESE",
            playerId: 2265623,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Shaquai MITCHELL",
            playerId: 1617738,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Josh KERR",
            playerId: 2032577,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Corey JENSEN",
            playerId: 2111021,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Erin-Wayne CLARK",
            playerId: 2776543,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
        ],
        Hooker: [
          {
            name: "Tyran WISHART",
            playerId: 788147,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Tom STARLING",
            playerId: 102747,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Bayley SIRONEN",
            playerId: 1169080,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Christopher RANDALL",
            playerId: 2103357,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            name: "Cory PAIX",
            playerId: 2029651,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Jake SIMPKIN",
            playerId: 2473949,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
        "Second Row": [
          {
            name: "Jack BIRD",
            playerId: 54392,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Angus CRICHTON",
            playerId: 2389876,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Jai ARROW",
            playerId: 1718077,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Brendan PIAKURA",
            playerId: 2204287,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Mathew CROKER",
            playerId: 772522,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
        ],
        Lock: [
          {
            name: "Jake GRANVILLE",
            playerId: 364664,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Alex TWAL",
            playerId: 79833,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Harrison EDWARDS",
            playerId: 2186155,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Griffin NEAME",
            playerId: 2326456,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
        ],
      },
      "18-25": {
        Fullback: [
          {
            name: "Sebastian KRIS",
            playerId: 2016405,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Keano KINI",
            playerId: 6115443,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            name: "Trai FULLER",
            playerId: 2008657,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Kodi NIKORIMA",
            playerId: 2300943,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
        ],
        Centre: [
          // extras added in
          {
            name: "Corey Allan",
            playerId: 2016745,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Joseph Suaalii",
            playerId: 2180293,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Jackson Topine",
            playerId: 2243207,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Tommy Talau",
            playerId: 2590594,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
        Winger: [
          {
            name: "Dean MATTERSON",
            playerId: 327501,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          // extras added in
          {
            name: "Marcelo Montoya",
            playerId: 1415542,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Brian To'o",
            playerId: 1863952,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
        ],
        "Five-Eighth": [
          // extras added in
          {
            name: "Drew HUTCHISON",
            playerId: 76509,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Halfback: [
          // extras added in
          {
            name: "Jayden SULLIVAN",
            playerId: 2121211,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
        ],
        Prop: [
          {
            name: "Mathew CROKER",
            playerId: 772522,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Hame SELE",
            playerId: 1405176,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Jason TAUMALOLO",
            playerId: 2225024,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Herman ESE'ESE",
            playerId: 2265623,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Shawn Blore",
            playerId: 1274552,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Thomas HAZELTON",
            playerId: 2872103,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Ethan BULLEMOR",
            playerId: 2037896,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Jack HETHERINGTON",
            playerId: 774306,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Jaimin JOLLIFFE",
            playerId: 63312,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            name: "Royce HUNT",
            playerId: 2541943,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Naufahu Whyte",
            playerId: 6009573,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Egan Butcher",
            playerId: 960523,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Harrison EDWARDS",
            playerId: 2186155,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Griffin NEAME",
            playerId: 2326456,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Heilum LUKI",
            playerId: 2112650,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Tallis Duncan",
            playerId: 2196837,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Spencer Leniu",
            playerId: 2183365,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Alec MACDONALD",
            playerId: 3035645,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Toby Couchman",
            playerId: 2345823,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Alexander SEYFARTH",
            playerId: 244416,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Jack WILLIAMS",
            playerId: 138208,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Ben MURDOCH-MASILA",
            playerId: 2190727,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Wiremu GREIG",
            playerId: 2826214,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Emre GULER",
            playerId: 2206932,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Terrell MAY",
            playerId: 1459550,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Hooker: [
          {
            name: "Tom STARLING",
            playerId: 102747,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Tyson SMOOTHY",
            playerId: 2038065,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
        ],
        "Second Row": [
          {
            name: "Kenneath BROMWICH",
            playerid: 2208118,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Ben TRBOJEVIC",
            playerId: 1526513,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Kurtis MORRIN",
            playerId: 480711,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Angus CRICHTON",
            playerId: 2389876,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Lock: [
          {
            name: "Dylan WALKER",
            playerId: 180051,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Kurt MANN",
            playerId: 2046663,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Fletcher Baker",
            playerId: 3019367,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Kruise LEEMING",
            playerId: 3380921,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
        ],
      },
      "team-of-the-year": {
        Fullback: [
          {
            name: "James TEDESCO",
            playerId: 175119,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Centre: [],
        Winger: [],
        "Five-Eighth": [],
        Halfback: [
          {
            name: "Ben HUNT",
            playerId: 1741623,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Jamal FOGARTY",
            playerId: 1257343,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
        ],
        Prop: [
          {
            name: "Nelson Asofa-Solomona",
            playerId: 2544502,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
        ],
        Hooker: [],
        "Second Row": [],
        Lock: [],
      },
      "end-of-season": {
        captain: [
          {
            memberName: "Adam REYNOLDS",
            memberId: 45238,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Kurt CAPEWELL",
            memberId: 881574,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Reed MAHONEY",
            memberId: 298933,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Matt BURTON",
            memberId: 2227278,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Jason TAUMALOLO",
            memberId: 2225024,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Chad TOWNSEND",
            memberId: 1005071,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Jesse BROMWICH",
            memberId: 2202811,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Ben HUNT",
            memberId: 1741623,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Clinton GUTHERSON",
            memberId: 1941388,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Kalyn PONGA",
            memberId: 2642781,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Jayden BRAILEY",
            memberId: 1924032,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Tyson FRIZELL",
            memberId: 42549,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Cameron MURRAY",
            memberId: 17297,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Jarrod CROKER",
            memberId: 176181,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Isaah YEO",
            memberId: 272882,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Nathan CLEARY",
            memberId: 2450242,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "James  FISHER-HARRIS",
            memberId: 2684072,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Dylan EDWARDS",
            memberId: 130432,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Daly CHERRY-EVANS",
            memberId: 171405,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Lachlan CROKER",
            memberId: 816150,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Christian Welch",
            memberId: 2042424,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Nicholas	HYNES",
            memberId: 19162,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Alexander BRIMSON",
            memberId: 2043505,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Kieran FORAN",
            memberId: 2162970,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Tohu HARRIS",
            memberId: 2232345,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Wayde EGAN",
            memberId: 1043250,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "James TEDESCO",
            memberId: 175119,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Luke KEARY",
            memberId: 820973,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Apisai KOROISAU",
            memberId: 158516,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "John BATEMAN",
            memberId: 3540180,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
        coach: [
          {
            memberName: "Kevin WALTERS",
            memberId: 190103,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Cameron Ciraldo",
            memberId: 2162814,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Todd PAYTEN",
            memberId: 100216,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Wayne BENNETT",
            memberId: 2881351,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Ryan CARR",
            memberId: 16390,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Bradley ARTHUR",
            memberId: 129162,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Adam O'BRIEN",
            memberId: 85571,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Jason DEMETRIOU",
            memberId: 13862,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Ricky STUART",
            memberId: 148901,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Ivan CLEARY",
            memberId: 102216,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Anthony SEIBOLD",
            memberId: 52284,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Craig FITZGIBBON",
            memberId: 2164895,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Craig BELLAMY",
            memberId: 3371964,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "James LENIHAN",
            memberId: 419639,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Andrew WEBSTER",
            memberId: 314972,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Trent ROBINSON",
            memberId: 2097816,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Tim SHEENS",
            memberId: 133810,
            teamName: "Wests Tigers - Mens",
            teamId: 435588,
          },
        ],
        rookie: [
          {
            memberName: "Sunia TURUVA",
            memberId: 2191643,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Alofiana KHAN-PEREIRA",
            memberId: 2402810,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Junior TUPOU",
            memberId: 2573947,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Isaiya KATOA",
            memberId: 2800202,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "William Warbrick",
            memberId: 6197983,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Lindsay SMITH",
            memberId: 826971,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Soni LUKE",
            memberId: 109013,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Zac HOSKING",
            memberId: 56089,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Bronson GARLICK",
            memberId: 117639,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Paul Alamoti",
            memberId: 2520665,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Brendan HANDS",
            memberId: 1573207,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Jahream Bula",
            memberId: 6056903,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Sean RUSSELL",
            memberId: 2186188,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Thomas HAZELTON",
            memberId: 2872103,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Wiremu GREIG",
            memberId: 2826214,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Klese HAAS",
            memberId: 2383809,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Ata MARIOTA",
            memberId: 3882918,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Toby Couchman",
            memberId: 2345823,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Iszac FAASUAMALEAUI",
            memberId: 2405849,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Sandon SMITH",
            memberId: 2816837,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Harrison EDWARDS",
            memberId: 2186155,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Kurtis MORRIN",
            memberId: 480711,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Valynce-Mikaeri CROSBY-TE WHARE",
            memberId: 6225651,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Kulikefu FINEFEUIAKI",
            memberId: 3158155,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Shaquai MITCHELL",
            memberId: 1617738,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Blake WILSON",
            memberId: 2130745,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Tyson SMOOTHY",
            memberId: 2038065,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Brendan PIAKURA",
            memberId: 2204287,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Luca MORETTI",
            memberId: 2206079,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Kaeo WEEKES",
            memberId: 2161563,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Naufahu Whyte",
            memberId: 6009573,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Daniel RUSSELL",
            memberId: 653450,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Joshua WONG",
            memberId: 2407909,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Xavier WILLISON",
            memberId: 2773951,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Fetalaiga PAUGA",
            memberId: 2834731,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Izaac THOMPSON",
            memberId: 2259122,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Karl OLOAPU",
            memberId: 2649237,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Kurt DONOGHOE",
            memberId: 2196056,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Mason TEAGUE",
            memberId: 2215186,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Dean MATTERSON",
            memberId: 327501,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Jonah Pezet",
            memberId: 2189388,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Tallis Duncan",
            memberId: 2196837,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Franklin PELE",
            memberId: 2338041,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Tomas CHESTER",
            memberId: 2133231,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Harrison GRAHAM",
            memberId: 2116763,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Keano KINI",
            memberId: 6115443,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Dylan LUCAS",
            memberId: 1170005,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Connor MUHLEISEN",
            memberId: 2111452,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Jesse COLQUHOUN",
            memberId: 2602583,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Justin MATAMUA",
            memberId: 2358315,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Thomas JENKINS",
            memberId: 2195108,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Samuel HUGHES",
            memberId: 2225853,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Samuela FAINU",
            memberId: 2202334,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Taine TUAUPIKI",
            memberId: 2217364,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Ryan COUCHMAN",
            memberId: 2345824,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Aitasi JAMES",
            memberId: 2566371,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Tallyn DA SILVA",
            memberId: 2619405,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Deine Mariner",
            memberId: 6040583,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Khaled RAJAB",
            memberId: 1886940,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Jack BOSTOCK",
            memberId: 2384663,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Jacob ALICK",
            memberId: 2740783,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Zac LAYBUTT",
            memberId: 2411218,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Declan CASEY",
            memberId: 2474146,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Ronald VOLKMAN",
            memberId: 2117603,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Trey MOONEY",
            memberId: 2725951,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Jeral SKELTON",
            memberId: 2193775,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Thomas WEAVER",
            memberId: 2374291,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Demitric Sifakula",
            memberId: 6197982,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Benjamin LOVETT",
            memberId: 2412048,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Tyrone MUNRO",
            memberId: 2536790,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Triston REILLY",
            memberId: 154322,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Dean HAWKINS",
            memberId: 162310,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Kane BRADLEY",
            memberId: 1796059,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Adrian TREVILYAN",
            memberId: 2148237,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Liam Henry",
            memberId: 2143462,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Jayden TANNER",
            memberId: 574790,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Max PLATH",
            memberId: 2376625,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Gordon CHAN KUM TONG",
            memberId: 2142323,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Riley PRICE",
            memberId: 2244454,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Luke SOMMERTON",
            memberId: 934928,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Daniel ATKINSON",
            memberId: 2190012,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Oryn Keeley",
            memberId: 2182401,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Blake MOZER",
            memberId: 2401984,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Joshua ROGERS",
            memberId: 252260,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Ethan STRANGE",
            memberId: 2555082,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Hohepa PURU",
            memberId: 2557535,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Ethan QUAI-WARD",
            memberId: 597616,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Brayden MCGRADY",
            memberId: 770639,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Trai FULLER",
            memberId: 2008657,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Jack HOWARTH",
            memberId: 2371504,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Joe CHAN",
            memberId: 2511747,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Sualauvi Faalogo",
            memberId: 3325040,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Ali Leiataua",
            memberId: 6009642,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Kalani GOING",
            memberId: 2684081,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Paul ROACHE",
            memberId: 2555869,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Riley Jones",
            memberId: 2216031,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Ryan RIVETT",
            memberId: 2198234,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Thomas CANT",
            memberId: 2096527,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Robert DERBY",
            memberId: 2669273,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Arthur MILLER-STEPHEN",
            memberId: 2641261,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Jesse MCLEAN",
            memberId: 2708225,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Sione FINAU",
            memberId: 3045709,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Brandon TUMETH",
            memberId: 2344582,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Joshua FELEDY",
            memberId: 6014774,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Kit LAULILII",
            memberId: 3046486,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
      },
    },
    46462720: {
      "team-of-the-year": {
        Fullback: [],
        Centre: [],
        Winger: [
          {
            playerId: 3582727,
            name: "Shenai LENDILL",
            teamId: 46577216,
            team: "St George-Illawarra Dragons - Womens",
          },
          {
            playerId: 3746947,
            name: "China POLATA",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
        ],
        "Five-Eighth": [],
        Halfback: [
          {
            playerId: 3042188,
            name: "Losana LUTU",
            teamId: 46577409,
            team: "Wests Tigers - Womens",
          },
        ],
        Prop: [
          {
            playerId: 2121134,
            name: "Maddison WEATHERALL",
            teamId: 46577216,
            team: "St George-Illawarra Dragons - Womens",
          },
          {
            playerId: 3300988,
            name: "Chelsea LENARDUZZI",
            teamId: 46576577,
            team: "Brisbane Broncos - Womens",
          },
          {
            playerId: 20763920,
            name: "Grace Kemp",
            teamId: 46576623,
            team: "Canberra Raiders - Womens",
          },
          {
            playerId: 2371864,
            name: "Holli WHEELER",
            teamId: 46576750,
            team: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            playerId: 641985286,
            name: "Angelina Teakaraanga-Katoa",
            teamId: 46577216,
            team: "St George-Illawarra Dragons - Womens",
          },
          {
            playerId: 1435965,
            name: "Stephanie HANCOCK",
            teamId: 46576823,
            team: "Gold Coast Titans - Womens",
          },
          {
            playerId: 6084062,
            name: "Grace Hamilton",
            teamId: 46577368,
            team: "Sydney Roosters - Womens",
          },
          {
            playerId: 3225584,
            name: "Sareka MOOKA",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
          {
            playerId: 2415570,
            name: "Sophie CURTAIN",
            teamId: 46577409,
            team: "Wests Tigers - Womens",
          },
          {
            playerId: 21299985,
            name: "Tara REINKE",
            teamId: 46576623,
            team: "Canberra Raiders - Womens",
          },
          {
            playerId: 2748124,
            name: "Sera KOROI",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
          {
            playerId: 6340359,
            name: "Noaria Kapua",
            teamId: 46577180,
            team: "Parramatta Eels - Womens",
          },
          {
            playerId: 6301090,
            name: "Tiana Davison",
            teamId: 46576922,
            team: "Newcastle Knights - Womens",
          },
          {
            playerId: 2522800,
            name: "Jessikah REEVES",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
          {
            playerId: 3563106,
            name: "Jessica KENNEDY",
            teamId: 46577409,
            team: "Wests Tigers - Womens",
          },
          {
            playerId: 3271209,
            name: "Lily PEACOCK",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
          {
            playerId: 3688648,
            name: "Makenzie WEALE",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
        ],
        Hooker: [
          {
            playerId: 3546394,
            name: "Emma BARNES",
            teamId: 46576623,
            team: "Canberra Raiders - Womens",
          },
          {
            playerId: 3844522,
            name: "Lavinia GOULD",
            teamId: 46576577,
            team: "Brisbane Broncos - Womens",
          },
          {
            playerId: 2982361,
            name: "Jada FERGUSON",
            teamId: 46576577,
            team: "Brisbane Broncos - Womens",
          },
          {
            playerId: 3878565,
            name: "Zali HOPKINS",
            teamId: 46577216,
            team: "St George-Illawarra Dragons - Womens",
          },
          {
            playerId: 2617181,
            name: "Sereana NAITOKATOKA",
            teamId: 46576750,
            team: "Cronulla-Sutherland Sharks - Womens",
          },
        ],
        "Second Row": [
          {
            playerId: 2699175,
            name: "Caitlan JOHNSTON",
            teamId: 46576922,
            team: "Newcastle Knights - Womens",
          },
          {
            playerId: 2626598,
            name: "Rilee JORGENSEN",
            teamId: 46576823,
            team: "Gold Coast Titans - Womens",
          },
          {
            playerId: 3158537,
            name: "Kaitlyn PHILLIPS",
            teamId: 46576823,
            team: "Gold Coast Titans - Womens",
          },
          {
            playerId: 3073466,
            name: "Nakia DAVIS-WELSH",
            teamId: 46577180,
            team: "Parramatta Eels - Womens",
          },
        ],
        Lock: [
          {
            playerId: 20498428,
            name: "Nita Maynard",
            teamId: 46576922,
            team: "Newcastle Knights - Womens",
          },
          {
            playerId: 2116194,
            name: "Shawden BURTON",
            teamId: 46577368,
            team: "Sydney Roosters - Womens",
          },
        ],
      },
      "end-of-season": {
        captain: [
          {
            memberName: "Ali BRIGGINSHAW",
            memberId: 2071253,
            teamName: "Brisbane Broncos - Womens",
            teamId: 46576577,
          },
          {
            memberName: "Simaima TAUFA",
            memberId: 2733221,
            teamName: "Canberra Raiders - Womens",
            teamId: 46576623,
          },
          {
            memberName: "Zahara TEMARA",
            memberId: 2382036,
            teamName: "Canberra Raiders - Womens",
            teamId: 46576623,
          },
          {
            memberName: "Tiana Penitani",
            memberId: 1224067,
            teamName: "Cronulla-Sutherland Sharks - Womens",
            teamId: 46576750,
          },
          {
            memberName: "Georgia HALE",
            memberId: 2530455,
            teamName: "Gold Coast Titans - Womens",
            teamId: 46576823,
          },
          {
            memberName: "Hannah SOUTHWELL",
            memberId: 1646386,
            teamName: "Newcastle Knights - Womens",
            teamId: 46576922,
          },
          {
            memberName: "Kirra DIBB",
            memberId: 78822,
            teamName: "North Queensland Cowboys - Womens",
            teamId: 46577082,
          },
          {
            memberName: "Tallisha HARDEN",
            memberId: 2880863,
            teamName: "North Queensland Cowboys - Womens",
            teamId: 46577082,
          },
          {
            memberName: "Rachael PEARSON",
            memberId: 2473209,
            teamName: "Parramatta Eels - Womens",
            teamId: 46577180,
          },
          {
            memberName: "Raecene MCGREGOR",
            memberId: 3062013,
            teamName: "St George-Illawarra Dragons - Womens",
            teamId: 46577216,
          },
          {
            memberName: "Isabelle Kelly",
            memberId: 3132989,
            teamName: "Sydney Roosters - Womens",
            teamId: 46577368,
          },
          {
            memberName: "Kezie APPS",
            memberId: 2725209,
            teamName: "Wests Tigers - Womens",
            teamId: 46577409,
          },
          {
            memberName: "Botille VETTE-WELSH",
            memberId: 3284725,
            teamName: "Wests Tigers - Womens",
            teamId: 46577409,
          },
        ],
        coach: [
          {
            memberName: "Scott PRINCE",
            memberId: 107064,
            teamName: "Brisbane Broncos - Womens",
            teamId: 46576577,
          },
          {
            memberName: "Darrin BORTHWICK",
            memberId: 2201082,
            teamName: "Canberra Raiders - Womens",
            teamId: 46576623,
          },
          {
            memberName: "Tony HERMAN",
            memberId: 32531,
            teamName: "Cronulla-Sutherland Sharks - Womens",
            teamId: 46576750,
          },
          {
            memberName: "Karyn MURPHY",
            memberId: 5900639,
            teamName: "Gold Coast Titans - Womens",
            teamId: 46576823,
          },
          {
            memberName: "Ronald GRIFFITHS",
            memberId: 1667664,
            teamName: "Newcastle Knights - Womens",
            teamId: 46576922,
          },
          {
            memberName: "Benjamin JEFFRIES",
            memberId: 2810242,
            teamName: "North Queensland Cowboys - Womens",
            teamId: 46577082,
          },
          {
            memberName: "Dean WIDDERS",
            memberId: 2728182,
            teamName: "Parramatta Eels - Womens",
            teamId: 46577180,
          },
          {
            memberName: "Jamie Soward",
            memberId: 2088344,
            teamName: "St George-Illawarra Dragons - Womens",
            teamId: 46577216,
          },
          {
            memberName: "John STRANGE",
            memberId: 86051,
            teamName: "Sydney Roosters - Womens",
            teamId: 46577368,
          },
          {
            memberName: "Brett KIMMORLEY",
            memberId: 18623,
            teamName: "Wests Tigers - Womens",
            teamId: 46577409,
          },
        ],
        rookie: [
          {
            memberId: 3133463,
            memberName: "Ahlivia INGRAM",
            teamId: 46576623,
            teamName: "Canberra Raiders - Womens",
          },
          {
            memberId: 3574598,
            memberName: "Alanna DUMMETT",
            teamId: 46576623,
            teamName: "Canberra Raiders - Womens",
          },
          {
            memberId: 3546394,
            memberName: "Emma BARNES",
            teamId: 46576623,
            teamName: "Canberra Raiders - Womens",
          },
          {
            memberId: 3811784,
            memberName: "Petesa LIO",
            teamId: 46576623,
            teamName: "Canberra Raiders - Womens",
          },
          {
            memberId: 642114551,
            memberName: "Annessa Biddle",
            teamId: 46576750,
            teamName: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            memberId: 3734865,
            memberName: "Fiona JAHNKE",
            teamId: 46576750,
            teamName: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            memberId: 2376173,
            memberName: "Jada TAYLOR",
            teamId: 46576750,
            teamName: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            memberId: 3661061,
            memberName: "Jazmon Tupou-Witchman",
            teamId: 46576750,
            teamName: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            memberId: 6001675,
            memberName: "Chantay Kiria-Ratu",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 3608747,
            memberName: "Dannii PERESE",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 6123369,
            memberName: "Destiny MINO-SINAPATI",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 2626598,
            memberName: "Rilee JORGENSEN",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 3586882,
            memberName: "Sienna LOFIPO",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 3376594,
            memberName: "Sophie BULLER",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },

          {
            memberId: 4072438,
            memberName: "Felila KIA",
            teamId: 46576922,
            teamName: "Newcastle Knights - Womens",
          },

          {
            memberId: 3678857,
            memberName: "Jacinta CARTER",
            teamId: 46576922,
            teamName: "Newcastle Knights - Womens",
          },
          {
            memberId: 3625344,
            memberName: "Jasmin STRANGE",
            teamId: 46576922,
            teamName: "Newcastle Knights - Womens",
          },
          {
            memberId: 3375042,
            memberName: "Viena TINAO",
            teamId: 46576922,
            teamName: "Newcastle Knights - Womens",
          },
          {
            memberId: 2528468,
            memberName: "Bree CHESTER",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3746947,
            memberName: "China POLATA",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3207586,
            memberName: "Essay BANU",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 2522800,
            memberName: "Jessikah REEVES",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3696226,
            memberName: "Krystal BLACKWELL",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3564190,
            memberName: "Libby SURHA",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3271209,
            memberName: "Lily PEACOCK",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 2748124,
            memberName: "Sera KOROI",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3129204,
            memberName: "Shellie LONG",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 6028225,
            memberName: "Capri Paekau",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 2880998,
            memberName: "Rosemarie BECKETT",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 20870694,
            memberName: "Rueben Cherrington",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 3371363,
            memberName: "Taneka TODHUNTER",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 2816899,
            memberName: "Tyla AMIATU",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 6339426,
            memberName: "Alexis TAUANEAI",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 641985286,
            memberName: "Angelina Teakaraanga-Katoa",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 3152038,
            memberName: "Cheynoah Amone",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 6263489,
            memberName: "Cortez Te Pou",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 3258077,
            memberName: "Ella KOSTER",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 2998297,
            memberName: "Ellie WILLIAMSON",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 3934980,
            memberName: "Macie CARLILE",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 6152559,
            memberName: "Madison Mulhall",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },

          {
            memberId: 2556332,
            memberName: "Sophie CLANCY",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 3361688,
            memberName: "Joeli Morris",
            teamId: 46577368,
            teamName: "Sydney Roosters - Womens",
          },
          {
            memberId: 3561502,
            memberName: "Lily ROGAN",
            teamId: 46577368,
            teamName: "Sydney Roosters - Womens",
          },
          {
            memberId: 3665672,
            memberName: "Bianca BENNETTS",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3976386,
            memberName: "Brooke TALATAINA",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3557610,
            memberName: "Ebony PRIOR",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 2623003,
            memberName: "Eliza SIILATA",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 2838332,
            memberName: "Folau VAKI",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3281227,
            memberName: "Hope TEVAGA",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3933923,
            memberName: "Imogen GOBRAN",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 4058140,
            memberName: "Jae PATU",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3563106,
            memberName: "Jessica KENNEDY",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3042188,
            memberName: "Losana LUTU",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 2565952,
            memberName: "Salma NOUR",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 2415570,
            memberName: "Sophie CURTAIN",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
        ],
      },
    },
  },
  prod: {
    // NRL Competition
    435588: {
      "1-9": {
        Fullback: [],
        Centre: [
          {
            name: "Corey Allan",
            playerId: 2016745,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Joseph Suaalii",
            playerId: 2180293,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Jackson Topine",
            playerId: 2243207,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Tommy Talau",
            playerId: 2590594,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
        Winger: [
          {
            name: "Tyran Wishart",
            playerId: 788147,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
        ],
        "Five-Eighth": [],
        Halfback: [],
        Prop: [
          {
            name: "Alec Macdonald",
            playerId: 3035645,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Alex Twal",
            playerId: 79833,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Bunty Afoa",
            playerId: 6235185,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Ethan BULLEMOR",
            playerId: 2037896,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Emre Guler",
            playerId: 2206932,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Herman Ese'ese",
            playerId: 2265623,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Jai Arrow",
            playerId: 1718077,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Jack Hetherington",
            playerId: 774306,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Joshua Kerr",
            playerId: 2032577,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Jack Murchie",
            playerId: 96710,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Jarrod Wallace",
            playerId: 2139072,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Keenan Palasia",
            playerId: 2129046,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Lindsay Smith",
            playerId: 826971,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Mitch Barnett",
            playerId: 1067416,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Matt Lodge",
            playerId: 68544,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Makahesi Makatoa",
            playerId: 2452202,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Mark Nicholls",
            playerId: 177061,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Martin Taupau",
            playerId: 2227970,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Nelson Asofa-Solomona",
            playerId: 2544502,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Ofahiki Ogden",
            playerId: 2261706,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Royce Hunt",
            playerId: 2541943,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Ryan Sutton",
            playerId: 3473701,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Shawn Blore",
            playerId: 1274552,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Sean Keppie",
            playerId: 23645,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Spencer Leniu",
            playerId: 2183365,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Shaquai Mitchell",
            playerId: 1617738,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Sitili Tupouniua",
            playerId: 2778103,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Tom Ale",
            playerId: 2461856,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Toby Couchman",
            playerId: 2345823,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Thomas Hazelton",
            playerId: 2872103,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Tariq Sims",
            playerId: 81104,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Tevita Tatola",
            playerId: 47880,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
        ],
        Hooker: [
          {
            name: "Jake Turpin",
            playerId: 1301229,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Kaeo Weekes",
            playerId: 2161563,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Mason Teague",
            playerId: 2215186,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Phoenix Crossland",
            playerId: 2131277,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Ray Stone",
            playerId: 1935879,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Soni Luke",
            playerId: 109013,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Tom Starling",
            playerId: 102747,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
        ],
        "Second Row": [
          {
            name: "Jayden Okunbor",
            playerId: 116857,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Jack Williams",
            playerId: 138208,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
        ],
        Lock: [
          {
            name: "Kobe Hetherington",
            playerId: 361714,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
        ],
      },
      "10-17": {
        Fullback: [],
        Centre: [],
        Winger: [
          {
            name: "Marcelo Montoya",
            playerId: 1415542,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
        ],
        "Five-Eighth": [
          {
            name: "Drew HUTCHISON",
            playerId: 76509,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Halfback: [
          {
            name: "Jayden SULLIVAN",
            playerId: 2121211,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
        ],
        Prop: [
          {
            name: "Nathan BROWN",
            playerId: 1616868,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Lindsay Smith",
            playerId: 826971,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Ethan BULLEMOR",
            playerId: 2037896,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Jack WILLIAMS",
            playerId: 138208,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Makahesi MAKATOA",
            playerId: 2452202,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Jamayne TAUNOA-BROWN",
            playerId: 952136,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Emre GULER",
            playerId: 2206932,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Kobe HETHERINGTON",
            playerId: 361714,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Mitchell BARNETT",
            playerId: 1067416,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Dylan WALKER",
            playerId: 180051,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Joshua ALOIAI",
            playerId: 2237423,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Pasami SAULO",
            playerId: 82472,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Spencer Leniu",
            playerId: 2183365,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Thomas BURGESS",
            playerId: 2832239,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Thomas EISENHUTH",
            playerId: 164487,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Martin TAUPAU",
            playerId: 2227970,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Matthew LODGE",
            playerId: 68544,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Liam MARTIN",
            playerId: 150222,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Thomas HAZELTON",
            playerId: 2872103,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Kulikefu FINEFEUIAKI",
            playerId: 3158155,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Jayden OKUNBOR",
            playerId: 116857,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Mark NICHOLLS",
            playerId: 177061,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Xavier WILLISON",
            playerId: 2773951,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Daniel SULUKA FIFITA",
            playerId: 705384,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Jaimin JOLLIFFE",
            playerId: 63312,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            name: "Tom ALE",
            playerId: 2461856,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Herman ESE'ESE",
            playerId: 2265623,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Shaquai MITCHELL",
            playerId: 1617738,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Josh KERR",
            playerId: 2032577,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Corey JENSEN",
            playerId: 2111021,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Erin-Wayne CLARK",
            playerId: 2776543,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
        ],
        Hooker: [
          {
            name: "Tyran WISHART",
            playerId: 788147,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Tom STARLING",
            playerId: 102747,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Bayley SIRONEN",
            playerId: 1169080,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Christopher RANDALL",
            playerId: 2103357,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            name: "Cory PAIX",
            playerId: 2029651,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Jake SIMPKIN",
            playerId: 2473949,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
        "Second Row": [
          {
            name: "Jack BIRD",
            playerId: 54392,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Angus CRICHTON",
            playerId: 2389876,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Jai ARROW",
            playerId: 1718077,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Brendan PIAKURA",
            playerId: 2204287,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            name: "Mathew CROKER",
            playerId: 772522,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
        ],
        Lock: [
          {
            name: "Jake GRANVILLE",
            playerId: 364664,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Alex TWAL",
            playerId: 79833,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Harrison EDWARDS",
            playerId: 2186155,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Griffin NEAME",
            playerId: 2326456,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
        ],
      },
      "18-25": {
        Fullback: [
          {
            name: "Sebastian KRIS",
            playerId: 2016405,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Keano KINI",
            playerId: 6115443,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            name: "Trai FULLER",
            playerId: 2008657,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Kodi NIKORIMA",
            playerId: 2300943,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
        ],
        Centre: [],
        Winger: [
          {
            name: "Dean MATTERSON",
            playerId: 327501,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
        ],
        "Five-Eighth": [],
        Halfback: [],
        Prop: [
          {
            name: "Mathew CROKER",
            playerId: 772522,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Hame SELE",
            playerId: 1405176,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Jason TAUMALOLO",
            playerId: 2225024,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Herman ESE'ESE",
            playerId: 2265623,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Shawn Blore",
            playerId: 1274552,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Thomas HAZELTON",
            playerId: 2872103,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Ethan BULLEMOR",
            playerId: 2037896,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Jack HETHERINGTON",
            playerId: 774306,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Jaimin JOLLIFFE",
            playerId: 63312,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            name: "Royce HUNT",
            playerId: 2541943,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Naufahu Whyte",
            playerId: 6009573,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Egan Butcher",
            playerId: 960523,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Harrison EDWARDS",
            playerId: 2186155,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Griffin NEAME",
            playerId: 2326456,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Heilum LUKI",
            playerId: 2112650,
            team: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            name: "Tallis Duncan",
            playerId: 2196837,
            team: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            name: "Spencer Leniu",
            playerId: 2183365,
            team: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            name: "Alec MACDONALD",
            playerId: 3035645,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            name: "Toby Couchman",
            playerId: 2345823,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Alexander SEYFARTH",
            playerId: 244416,
            team: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            name: "Jack WILLIAMS",
            playerId: 138208,
            team: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            name: "Ben MURDOCH-MASILA",
            playerId: 2190727,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Wiremu GREIG",
            playerId: 2826214,
            team: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            name: "Emre GULER",
            playerId: 2206932,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Terrell MAY",
            playerId: 1459550,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Hooker: [
          {
            name: "Tom STARLING",
            playerId: 102747,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            name: "Tyson SMOOTHY",
            playerId: 2038065,
            team: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
        ],
        "Second Row": [
          {
            name: "Kenneath BROMWICH",
            playerid: 2208118,
            team: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            name: "Ben TRBOJEVIC",
            playerId: 1526513,
            team: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            name: "Kurtis MORRIN",
            playerId: 480711,
            team: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            name: "Angus CRICHTON",
            playerId: 2389876,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Lock: [
          {
            name: "Dylan WALKER",
            playerId: 180051,
            team: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            name: "Kurt MANN",
            playerId: 2046663,
            team: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            name: "Fletcher Baker",
            playerId: 3019367,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            name: "Kruise LEEMING",
            playerId: 3380921,
            team: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
        ],
      },
      "team-of-the-year": {
        Fullback: [
          {
            name: "James TEDESCO",
            playerId: 175119,
            team: "Sydney Roosters - Mens",
            teamId: 435802,
          },
        ],
        Centre: [],
        Winger: [],
        "Five-Eighth": [],
        Halfback: [
          {
            name: "Ben HUNT",
            playerId: 1741623,
            team: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            name: "Jamal FOGARTY",
            playerId: 1257343,
            team: "Canberra Raiders - Mens",
            teamId: 435786,
          },
        ],
        Prop: [
          {
            name: "Nelson Asofa-Solomona",
            playerId: 2544502,
            team: "Melbourne Storm - Mens",
            teamId: 435793,
          },
        ],
        Hooker: [],
        "Second Row": [],
        Lock: [],
      },
      "end-of-season": {
        captain: [
          {
            memberName: "Adam REYNOLDS",
            memberId: 45238,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Kurt CAPEWELL",
            memberId: 881574,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Reed MAHONEY",
            memberId: 298933,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Matt BURTON",
            memberId: 2227278,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Jason TAUMALOLO",
            memberId: 2225024,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Chad TOWNSEND",
            memberId: 1005071,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Jesse BROMWICH",
            memberId: 2202811,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Ben HUNT",
            memberId: 1741623,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Clinton GUTHERSON",
            memberId: 1941388,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Kalyn PONGA",
            memberId: 2642781,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Jayden BRAILEY",
            memberId: 1924032,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Tyson FRIZELL",
            memberId: 42549,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Cameron MURRAY",
            memberId: 17297,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Jarrod CROKER",
            memberId: 176181,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Isaah YEO",
            memberId: 272882,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Nathan CLEARY",
            memberId: 2450242,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "James  FISHER-HARRIS",
            memberId: 2684072,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Dylan EDWARDS",
            memberId: 130432,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Daly CHERRY-EVANS",
            memberId: 171405,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Lachlan CROKER",
            memberId: 816150,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Christian Welch",
            memberId: 2042424,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Nicholas	HYNES",
            memberId: 19162,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Alexander BRIMSON",
            memberId: 2043505,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Kieran FORAN",
            memberId: 2162970,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Tohu HARRIS",
            memberId: 2232345,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Wayde EGAN",
            memberId: 1043250,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "James TEDESCO",
            memberId: 175119,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Luke KEARY",
            memberId: 820973,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Apisai KOROISAU",
            memberId: 158516,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "John BATEMAN",
            memberId: 3540180,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
        coach: [
          {
            memberName: "Kevin WALTERS",
            memberId: 190103,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Cameron Ciraldo",
            memberId: 2162814,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Todd PAYTEN",
            memberId: 100216,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Wayne BENNETT",
            memberId: 2881351,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Ryan CARR",
            memberId: 16390,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Bradley ARTHUR",
            memberId: 129162,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Adam O'BRIEN",
            memberId: 85571,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Jason DEMETRIOU",
            memberId: 13862,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Ricky STUART",
            memberId: 148901,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Ivan CLEARY",
            memberId: 102216,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Anthony SEIBOLD",
            memberId: 52284,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Craig FITZGIBBON",
            memberId: 2164895,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Craig BELLAMY",
            memberId: 3371964,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "James LENIHAN",
            memberId: 419639,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Andrew WEBSTER",
            memberId: 314972,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Trent ROBINSON",
            memberId: 2097816,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Tim SHEENS",
            memberId: 133810,
            teamName: "Wests Tigers - Mens",
            teamId: 435588,
          },
        ],
        rookie: [
          {
            memberName: "Sunia TURUVA",
            memberId: 2191643,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Alofiana KHAN-PEREIRA",
            memberId: 2402810,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Junior TUPOU",
            memberId: 2573947,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Isaiya KATOA",
            memberId: 2800202,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "William Warbrick",
            memberId: 6197983,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Lindsay SMITH",
            memberId: 826971,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Soni LUKE",
            memberId: 109013,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Zac HOSKING",
            memberId: 56089,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Bronson GARLICK",
            memberId: 117639,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Paul Alamoti",
            memberId: 2520665,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Brendan HANDS",
            memberId: 1573207,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Jahream Bula",
            memberId: 6056903,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Sean RUSSELL",
            memberId: 2186188,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Thomas HAZELTON",
            memberId: 2872103,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Wiremu GREIG",
            memberId: 2826214,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Klese HAAS",
            memberId: 2383809,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Ata MARIOTA",
            memberId: 3882918,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Toby Couchman",
            memberId: 2345823,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Iszac FAASUAMALEAUI",
            memberId: 2405849,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Sandon SMITH",
            memberId: 2816837,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Harrison EDWARDS",
            memberId: 2186155,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Kurtis MORRIN",
            memberId: 480711,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Valynce-Mikaeri CROSBY-TE WHARE",
            memberId: 6225651,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Kulikefu FINEFEUIAKI",
            memberId: 3158155,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Shaquai MITCHELL",
            memberId: 1617738,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Blake WILSON",
            memberId: 2130745,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Tyson SMOOTHY",
            memberId: 2038065,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Brendan PIAKURA",
            memberId: 2204287,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Luca MORETTI",
            memberId: 2206079,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Kaeo WEEKES",
            memberId: 2161563,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Naufahu Whyte",
            memberId: 6009573,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Daniel RUSSELL",
            memberId: 653450,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Joshua WONG",
            memberId: 2407909,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Xavier WILLISON",
            memberId: 2773951,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Fetalaiga PAUGA",
            memberId: 2834731,
            teamName: "Sydney Roosters - Mens",
            teamId: 435802,
          },
          {
            memberName: "Izaac THOMPSON",
            memberId: 2259122,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Karl OLOAPU",
            memberId: 2649237,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Kurt DONOGHOE",
            memberId: 2196056,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Mason TEAGUE",
            memberId: 2215186,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Dean MATTERSON",
            memberId: 327501,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Jonah Pezet",
            memberId: 2189388,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Tallis Duncan",
            memberId: 2196837,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Franklin PELE",
            memberId: 2338041,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Tomas CHESTER",
            memberId: 2133231,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Harrison GRAHAM",
            memberId: 2116763,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Keano KINI",
            memberId: 6115443,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Dylan LUCAS",
            memberId: 1170005,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Connor MUHLEISEN",
            memberId: 2111452,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Jesse COLQUHOUN",
            memberId: 2602583,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Justin MATAMUA",
            memberId: 2358315,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Thomas JENKINS",
            memberId: 2195108,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Samuel HUGHES",
            memberId: 2225853,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Samuela FAINU",
            memberId: 2202334,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Taine TUAUPIKI",
            memberId: 2217364,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Ryan COUCHMAN",
            memberId: 2345824,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Aitasi JAMES",
            memberId: 2566371,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Tallyn DA SILVA",
            memberId: 2619405,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Deine Mariner",
            memberId: 6040583,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Khaled RAJAB",
            memberId: 1886940,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Jack BOSTOCK",
            memberId: 2384663,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Jacob ALICK",
            memberId: 2740783,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Zac LAYBUTT",
            memberId: 2411218,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Declan CASEY",
            memberId: 2474146,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Ronald VOLKMAN",
            memberId: 2117603,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Trey MOONEY",
            memberId: 2725951,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Jeral SKELTON",
            memberId: 2193775,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Thomas WEAVER",
            memberId: 2374291,
            teamName: "Gold Coast Titans - Mens",
            teamId: 435791,
          },
          {
            memberName: "Demitric Sifakula",
            memberId: 6197982,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Benjamin LOVETT",
            memberId: 2412048,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Tyrone MUNRO",
            memberId: 2536790,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Triston REILLY",
            memberId: 154322,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Dean HAWKINS",
            memberId: 162310,
            teamName: "South Sydney Rabbitohs - Mens",
            teamId: 435799,
          },
          {
            memberName: "Kane BRADLEY",
            memberId: 1796059,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Adrian TREVILYAN",
            memberId: 2148237,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Liam Henry",
            memberId: 2143462,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Jayden TANNER",
            memberId: 574790,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Max PLATH",
            memberId: 2376625,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Gordon CHAN KUM TONG",
            memberId: 2142323,
            teamName: "Manly-Warringah Sea Eagles - Mens",
            teamId: 435792,
          },
          {
            memberName: "Riley PRICE",
            memberId: 2244454,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Luke SOMMERTON",
            memberId: 934928,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Daniel ATKINSON",
            memberId: 2190012,
            teamName: "Cronulla-Sutherland Sharks - Mens",
            teamId: 435788,
          },
          {
            memberName: "Oryn Keeley",
            memberId: 2182401,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Blake MOZER",
            memberId: 2401984,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Joshua ROGERS",
            memberId: 252260,
            teamName: "Brisbane Broncos - Mens",
            teamId: 435785,
          },
          {
            memberName: "Ethan STRANGE",
            memberId: 2555082,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Hohepa PURU",
            memberId: 2557535,
            teamName: "Canberra Raiders - Mens",
            teamId: 435786,
          },
          {
            memberName: "Ethan QUAI-WARD",
            memberId: 597616,
            teamName: "Canterbury-Bankstown Bulldogs - Mens",
            teamId: 435787,
          },
          {
            memberName: "Brayden MCGRADY",
            memberId: 770639,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Trai FULLER",
            memberId: 2008657,
            teamName: "Dolphins - Mens",
            teamId: 435790,
          },
          {
            memberName: "Jack HOWARTH",
            memberId: 2371504,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Joe CHAN",
            memberId: 2511747,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Sualauvi Faalogo",
            memberId: 3325040,
            teamName: "Melbourne Storm - Mens",
            teamId: 435793,
          },
          {
            memberName: "Ali Leiataua",
            memberId: 6009642,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Kalani GOING",
            memberId: 2684081,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Paul ROACHE",
            memberId: 2555869,
            teamName: "New Zealand Warriors - Mens",
            teamId: 435794,
          },
          {
            memberName: "Riley Jones",
            memberId: 2216031,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Ryan RIVETT",
            memberId: 2198234,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Thomas CANT",
            memberId: 2096527,
            teamName: "Newcastle Knights - Mens",
            teamId: 435795,
          },
          {
            memberName: "Robert DERBY",
            memberId: 2669273,
            teamName: "North Queensland Cowboys - Mens",
            teamId: 435796,
          },
          {
            memberName: "Arthur MILLER-STEPHEN",
            memberId: 2641261,
            teamName: "Parramatta Eels - Mens",
            teamId: 435797,
          },
          {
            memberName: "Jesse MCLEAN",
            memberId: 2708225,
            teamName: "Penrith Panthers - Mens",
            teamId: 435798,
          },
          {
            memberName: "Sione FINAU",
            memberId: 3045709,
            teamName: "St George Illawarra Dragons - Mens",
            teamId: 435800,
          },
          {
            memberName: "Brandon TUMETH",
            memberId: 2344582,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Joshua FELEDY",
            memberId: 6014774,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
          {
            memberName: "Kit LAULILII",
            memberId: 3046486,
            teamName: "Wests Tigers - Mens",
            teamId: 435803,
          },
        ],
      },
    },
    // NRLW Competition
    46576462: {
      "team-of-the-year": {
        Fullback: [],
        Centre: [],
        Winger: [
          {
            playerId: 3582727,
            name: "Shenai LENDILL",
            teamId: 46577216,
            team: "St George-Illawarra Dragons - Womens",
          },
          {
            playerId: 3746947,
            name: "China POLATA",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
        ],
        "Five-Eighth": [],
        Halfback: [
          {
            playerId: 3042188,
            name: "Losana LUTU",
            teamId: 46577409,
            team: "Wests Tigers - Womens",
          },
        ],
        Prop: [
          {
            playerId: 2121134,
            name: "Maddison WEATHERALL",
            teamId: 46577216,
            team: "St George-Illawarra Dragons - Womens",
          },
          {
            playerId: 3300988,
            name: "Chelsea LENARDUZZI",
            teamId: 46576577,
            team: "Brisbane Broncos - Womens",
          },
          {
            playerId: 20763920,
            name: "Grace Kemp",
            teamId: 46576623,
            team: "Canberra Raiders - Womens",
          },
          {
            playerId: 2371864,
            name: "Holli WHEELER",
            teamId: 46576750,
            team: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            playerId: 641985286,
            name: "Angelina Teakaraanga-Katoa",
            teamId: 46577216,
            team: "St George-Illawarra Dragons - Womens",
          },
          {
            playerId: 1435965,
            name: "Stephanie HANCOCK",
            teamId: 46576823,
            team: "Gold Coast Titans - Womens",
          },
          {
            playerId: 6084062,
            name: "Grace Hamilton",
            teamId: 46577368,
            team: "Sydney Roosters - Womens",
          },
          {
            playerId: 3225584,
            name: "Sareka MOOKA",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
          {
            playerId: 2415570,
            name: "Sophie CURTAIN",
            teamId: 46577409,
            team: "Wests Tigers - Womens",
          },
          {
            playerId: 21299985,
            name: "Tara REINKE",
            teamId: 46576623,
            team: "Canberra Raiders - Womens",
          },
          {
            playerId: 2748124,
            name: "Sera KOROI",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
          {
            playerId: 6340359,
            name: "Noaria Kapua",
            teamId: 46577180,
            team: "Parramatta Eels - Womens",
          },
          {
            playerId: 6301090,
            name: "Tiana Davison",
            teamId: 46576922,
            team: "Newcastle Knights - Womens",
          },
          {
            playerId: 2522800,
            name: "Jessikah REEVES",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
          {
            playerId: 3563106,
            name: "Jessica KENNEDY",
            teamId: 46577409,
            team: "Wests Tigers - Womens",
          },
          {
            playerId: 3271209,
            name: "Lily PEACOCK",
            teamId: 46577082,
            team: "North Queensland Cowboys - Womens",
          },
        ],
        Hooker: [
          {
            playerId: 3546394,
            name: "Emma BARNES",
            teamId: 46576623,
            team: "Canberra Raiders - Womens",
          },
          {
            playerId: 3844522,
            name: "Lavinia GOULD",
            teamId: 46576577,
            team: "Brisbane Broncos - Womens",
          },
          {
            playerId: 2982361,
            name: "Jada FERGUSON",
            teamId: 46576577,
            team: "Brisbane Broncos - Womens",
          },
          {
            playerId: 3878565,
            name: "Zali HOPKINS",
            teamId: 46577216,
            team: "St George-Illawarra Dragons - Womens",
          },
          {
            playerId: 2617181,
            name: "Sereana NAITOKATOKA",
            teamId: 46576750,
            team: "Cronulla-Sutherland Sharks - Womens",
          },
        ],
        "Second Row": [
          {
            playerId: 2699175,
            name: "Caitlan JOHNSTON",
            teamId: 46576922,
            team: "Newcastle Knights - Womens",
          },
          {
            playerId: 2626598,
            name: "Rilee JORGENSEN",
            teamId: 46576823,
            team: "Gold Coast Titans - Womens",
          },
          {
            playerId: 3158537,
            name: "Kaitlyn PHILLIPS",
            teamId: 46576823,
            team: "Gold Coast Titans - Womens",
          },
          {
            playerId: 3073466,
            name: "Nakia DAVIS-WELSH",
            teamId: 46577180,
            team: "Parramatta Eels - Womens",
          },
        ],
        Lock: [
          {
            playerId: 20498428,
            name: "Nita Maynard",
            teamId: 46576922,
            team: "Newcastle Knights - Womens",
          },
          {
            playerId: 2116194,
            name: "Shawden BURTON",
            teamId: 46577368,
            team: "Sydney Roosters - Womens",
          },
        ],
      },
      "end-of-season": {
        captain: [
          {
            memberName: "Ali BRIGGINSHAW",
            memberId: 2071253,
            teamName: "Brisbane Broncos - Womens",
            teamId: 46576577,
          },
          {
            memberName: "Simaima TAUFA",
            memberId: 2733221,
            teamName: "Canberra Raiders - Womens",
            teamId: 46576623,
          },
          {
            memberName: "Zahara TEMARA",
            memberId: 2382036,
            teamName: "Canberra Raiders - Womens",
            teamId: 46576623,
          },
          {
            memberName: "Tiana Penitani",
            memberId: 1224067,
            teamName: "Cronulla-Sutherland Sharks - Womens",
            teamId: 46576750,
          },
          {
            memberName: "Georgia HALE",
            memberId: 2530455,
            teamName: "Gold Coast Titans - Womens",
            teamId: 46576823,
          },
          {
            memberName: "Hannah SOUTHWELL",
            memberId: 1646386,
            teamName: "Newcastle Knights - Womens",
            teamId: 46576922,
          },
          {
            memberName: "Kirra DIBB",
            memberId: 78822,
            teamName: "North Queensland Cowboys - Womens",
            teamId: 46577082,
          },
          {
            memberName: "Tallisha HARDEN",
            memberId: 2880863,
            teamName: "North Queensland Cowboys - Womens",
            teamId: 46577082,
          },
          {
            memberName: "Rachael PEARSON",
            memberId: 2473209,
            teamName: "Parramatta Eels - Womens",
            teamId: 46577180,
          },
          {
            memberName: "Raecene MCGREGOR",
            memberId: 3062013,
            teamName: "St George-Illawarra Dragons - Womens",
            teamId: 46577216,
          },
          {
            memberName: "Isabelle Kelly",
            memberId: 3132989,
            teamName: "Sydney Roosters - Womens",
            teamId: 46577368,
          },
          {
            memberName: "Kezie APPS",
            memberId: 2725209,
            teamName: "Wests Tigers - Womens",
            teamId: 46577409,
          },
          {
            memberName: "Botille VETTE-WELSH",
            memberId: 3284725,
            teamName: "Wests Tigers - Womens",
            teamId: 46577409,
          },
        ],
        coach: [
          {
            memberName: "Scott PRINCE",
            memberId: 107064,
            teamName: "Brisbane Broncos - Womens",
            teamId: 46576577,
          },
          {
            memberName: "Darrin BORTHWICK",
            memberId: 2201082,
            teamName: "Canberra Raiders - Womens",
            teamId: 46576623,
          },
          {
            memberName: "Tony HERMAN",
            memberId: 32531,
            teamName: "Cronulla-Sutherland Sharks - Womens",
            teamId: 46576750,
          },
          {
            memberName: "Karyn MURPHY",
            memberId: 5900639,
            teamName: "Gold Coast Titans - Womens",
            teamId: 46576823,
          },
          {
            memberName: "Ronald GRIFFITHS",
            memberId: 1667664,
            teamName: "Newcastle Knights - Womens",
            teamId: 46576922,
          },
          {
            memberName: "Benjamin JEFFRIES",
            memberId: 2810242,
            teamName: "North Queensland Cowboys - Womens",
            teamId: 46577082,
          },
          {
            memberName: "Dean WIDDERS",
            memberId: 2728182,
            teamName: "Parramatta Eels - Womens",
            teamId: 46577180,
          },
          {
            memberName: "Jamie Soward",
            memberId: 2088344,
            teamName: "St George-Illawarra Dragons - Womens",
            teamId: 46577216,
          },
          {
            memberName: "John STRANGE",
            memberId: 86051,
            teamName: "Sydney Roosters - Womens",
            teamId: 46577368,
          },
          {
            memberName: "Brett KIMMORLEY",
            memberId: 18623,
            teamName: "Wests Tigers - Womens",
            teamId: 46577409,
          },
        ],
        rookie: [
          {
            memberId: 3133463,
            memberName: "Ahlivia INGRAM",
            teamId: 46576623,
            teamName: "Canberra Raiders - Womens",
          },
          {
            memberId: 3574598,
            memberName: "Alanna DUMMETT",
            teamId: 46576623,
            teamName: "Canberra Raiders - Womens",
          },
          {
            memberId: 3546394,
            memberName: "Emma BARNES",
            teamId: 46576623,
            teamName: "Canberra Raiders - Womens",
          },
          {
            memberId: 3811784,
            memberName: "Petesa LIO",
            teamId: 46576623,
            teamName: "Canberra Raiders - Womens",
          },
          {
            memberId: 642114551,
            memberName: "Annessa Biddle",
            teamId: 46576750,
            teamName: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            memberId: 3734865,
            memberName: "Fiona JAHNKE",
            teamId: 46576750,
            teamName: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            memberId: 2376173,
            memberName: "Jada TAYLOR",
            teamId: 46576750,
            teamName: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            memberId: 3661061,
            memberName: "Jazmon Tupou-Witchman",
            teamId: 46576750,
            teamName: "Cronulla-Sutherland Sharks - Womens",
          },
          {
            memberId: 6001675,
            memberName: "Chantay Kiria-Ratu",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 3608747,
            memberName: "Dannii PERESE",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 6123369,
            memberName: "Destiny MINO-SINAPATI",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 2626598,
            memberName: "Rilee JORGENSEN",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 3586882,
            memberName: "Sienna LOFIPO",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },
          {
            memberId: 3376594,
            memberName: "Sophie BULLER",
            teamId: 46576823,
            teamName: "Gold Coast Titans - Womens",
          },

          {
            memberId: 4072438,
            memberName: "Felila KIA",
            teamId: 46576922,
            teamName: "Newcastle Knights - Womens",
          },

          {
            memberId: 3678857,
            memberName: "Jacinta CARTER",
            teamId: 46576922,
            teamName: "Newcastle Knights - Womens",
          },
          {
            memberId: 3625344,
            memberName: "Jasmin STRANGE",
            teamId: 46576922,
            teamName: "Newcastle Knights - Womens",
          },
          {
            memberId: 3375042,
            memberName: "Viena TINAO",
            teamId: 46576922,
            teamName: "Newcastle Knights - Womens",
          },
          {
            memberId: 2528468,
            memberName: "Bree CHESTER",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3746947,
            memberName: "China POLATA",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3207586,
            memberName: "Essay BANU",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 2522800,
            memberName: "Jessikah REEVES",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3696226,
            memberName: "Krystal BLACKWELL",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3564190,
            memberName: "Libby SURHA",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3271209,
            memberName: "Lily PEACOCK",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 2748124,
            memberName: "Sera KOROI",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 3129204,
            memberName: "Shellie LONG",
            teamId: 46577082,
            teamName: "North Queensland Cowboys - Womens",
          },
          {
            memberId: 6028225,
            memberName: "Capri Paekau",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 2880998,
            memberName: "Rosemarie BECKETT",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 20870694,
            memberName: "Rueben Cherrington",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 3371363,
            memberName: "Taneka TODHUNTER",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 2816899,
            memberName: "Tyla AMIATU",
            teamId: 46577180,
            teamName: "Parramatta Eels - Womens",
          },
          {
            memberId: 6339426,
            memberName: "Alexis TAUANEAI",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 641985286,
            memberName: "Angelina Teakaraanga-Katoa",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 3152038,
            memberName: "Cheynoah Amone",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 6263489,
            memberName: "Cortez Te Pou",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 3258077,
            memberName: "Ella KOSTER",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 2998297,
            memberName: "Ellie WILLIAMSON",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 3934980,
            memberName: "Macie CARLILE",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 6152559,
            memberName: "Madison Mulhall",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },

          {
            memberId: 2556332,
            memberName: "Sophie CLANCY",
            teamId: 46577216,
            teamName: "St George-Illawarra Dragons - Womens",
          },
          {
            memberId: 3361688,
            memberName: "Joeli Morris",
            teamId: 46577368,
            teamName: "Sydney Roosters - Womens",
          },
          {
            memberId: 3561502,
            memberName: "Lily ROGAN",
            teamId: 46577368,
            teamName: "Sydney Roosters - Womens",
          },
          {
            memberId: 3665672,
            memberName: "Bianca BENNETTS",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3976386,
            memberName: "Brooke TALATAINA",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3557610,
            memberName: "Ebony PRIOR",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 2623003,
            memberName: "Eliza SIILATA",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 2838332,
            memberName: "Folau VAKI",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3281227,
            memberName: "Hope TEVAGA",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3933923,
            memberName: "Imogen GOBRAN",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 4058140,
            memberName: "Jae PATU",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3563106,
            memberName: "Jessica KENNEDY",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 3042188,
            memberName: "Losana LUTU",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 2565952,
            memberName: "Salma NOUR",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
          {
            memberId: 2415570,
            memberName: "Sophie CURTAIN",
            teamId: 46577409,
            teamName: "Wests Tigers - Womens",
          },
        ],
      },
    },
  },
};

export const reportsUsersProd = [503820, 65782, 3460983, 3429871];
export const reportsUsersTest = [3488, 3932, 2102, 3653];

// export const dallyMCaptainOYList = [
//   {
//     memberName: "Adam REYNOLDS",
//     memberId: 45238,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Kurt CAPEWELL",
//     memberId: 881574,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Reed MAHONEY",
//     memberId: 298933,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Matt BURTON",
//     memberId: 2227278,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Jason TAUMALOLO",
//     memberId: 2225024,
//     teamName: "North Queensland Cowboys - Mens",
//     teamId: 435796,
//   },
//   {
//     memberName: "Chad TOWNSEND",
//     memberId: 1005071,
//     teamName: "North Queensland Cowboys - Mens",
//     teamId: 435796,
//   },
//   {
//     memberName: "Jesse BROMWICH",
//     memberId: 2202811,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Ben HUNT",
//     memberId: 1741623,
//     teamName: "St George Illawarra Dragons - Mens",
//     teamId: 435800,
//   },
//   {
//     memberName: "Clinton GUTHERSON",
//     memberId: 1941388,
//     teamName: "Parramatta Eels - Mens",
//     teamId: 435797,
//   },
//   {
//     memberName: "Kalyn PONGA",
//     memberId: 2642781,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Jayden BRAILEY",
//     memberId: 1924032,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Tyson FRIZELL",
//     memberId: 42549,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Cameron MURRAY",
//     memberId: 17297,
//     teamName: "South Sydney Rabbitohs - Mens",
//     teamId: 435799,
//   },
//   {
//     memberName: "Jarrod CROKER",
//     memberId: 176181,
//     teamName: "Canberra Raiders - Mens",
//     teamId: 435786,
//   },
//   {
//     memberName: "Isaah YEO",
//     memberId: 272882,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Nathan CLEARY",
//     memberId: 2450242,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "James  FISHER-HARRIS",
//     memberId: 2684072,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Dylan EDWARDS",
//     memberId: 130432,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Daly CHERRY-EVANS",
//     memberId: 171405,
//     teamName: "Manly-Warringah Sea Eagles - Mens",
//     teamId: 435792,
//   },
//   {
//     memberName: "Lachlan CROKER",
//     memberId: 816150,
//     teamName: "Manly-Warringah Sea Eagles - Mens",
//     teamId: 435792,
//   },
//   {
//     memberName: "Christian Welch",
//     memberId: 2042424,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "Nicholas	HYNES",
//     memberId: 19162,
//     teamName: "Cronulla-Sutherland Sharks - Mens",
//     teamId: 435788,
//   },
//   {
//     memberName: "Alexander BRIMSON",
//     memberId: 2043505,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Kieran FORAN",
//     memberId: 2162970,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Tohu HARRIS",
//     memberId: 2232345,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "Wayde EGAN",
//     memberId: 1043250,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "James TEDESCO",
//     memberId: 175119,
//     teamName: "Sydney Roosters - Mens",
//     teamId: 435802,
//   },
//   {
//     memberName: "Luke KEARY",
//     memberId: 820973,
//     teamName: "Sydney Roosters - Mens",
//     teamId: 435802,
//   },
//   {
//     memberName: "Apisai KOROISAU",
//     memberId: 158516,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "John BATEMAN",
//     memberId: 3540180,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
// ];
// export const dallyMCoachOYList = [
//   {
//     memberName: "Kevin WALTERS",
//     memberId: 190103,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Cameron Ciraldo",
//     memberId: 2162814,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Todd PAYTEN",
//     memberId: 100216,
//     teamName: "North Queensland Cowboys - Mens",
//     teamId: 435796,
//   },
//   {
//     memberName: "Wayne BENNETT",
//     memberId: 2881351,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Ryan CARR",
//     memberId: 16390,
//     teamName: "St George Illawarra Dragons - Mens",
//     teamId: 435800,
//   },
//   {
//     memberName: "Bradley ARTHUR",
//     memberId: 129162,
//     teamName: "Parramatta Eels - Mens",
//     teamId: 435797,
//   },
//   {
//     memberName: "Adam O'BRIEN",
//     memberId: 85571,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Jason DEMETRIOU",
//     memberId: 13862,
//     teamName: "South Sydney Rabbitohs - Mens",
//     teamId: 435799,
//   },
//   {
//     memberName: "Ricky STUART",
//     memberId: 148901,
//     teamName: "Canberra Raiders - Mens",
//     teamId: 435786,
//   },
//   {
//     memberName: "Ivan CLEARY",
//     memberId: 102216,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Anthony SEIBOLD",
//     memberId: 52284,
//     teamName: "Manly-Warringah Sea Eagles - Mens",
//     teamId: 435792,
//   },
//   {
//     memberName: "Craig FITZGIBBON",
//     memberId: 2164895,
//     teamName: "Cronulla-Sutherland Sharks - Mens",
//     teamId: 435788,
//   },
//   {
//     memberName: "Craig BELLAMY",
//     memberId: 3371964,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "James LENIHAN",
//     memberId: 419639,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Andrew WEBSTER",
//     memberId: 314972,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "Trent ROBINSON",
//     memberId: 2097816,
//     teamName: "Sydney Roosters - Mens",
//     teamId: 435802,
//   },
//   {
//     memberName: "Tim SHEENS",
//     memberId: 133810,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435588,
//   },
// ];
// export const dallyMRookieOYList = [
//   {
//     memberName: "Sunia TURUVA",
//     memberId: 2191643,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Alofiana KHAN-PEREIRA",
//     memberId: 2402810,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Junior TUPOU",
//     memberId: 2573947,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "Isaiya KATOA",
//     memberId: 2800202,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "William Warbrick",
//     memberId: 6197983,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "Lindsay SMITH",
//     memberId: 826971,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Soni LUKE",
//     memberId: 109013,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Zac HOSKING",
//     memberId: 56089,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Bronson GARLICK",
//     memberId: 117639,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "Paul Alamoti",
//     memberId: 2520665,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Brendan HANDS",
//     memberId: 1573207,
//     teamName: "Parramatta Eels - Mens",
//     teamId: 435797,
//   },
//   {
//     memberName: "Jahream Bula",
//     memberId: 6056903,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "Sean RUSSELL",
//     memberId: 2186188,
//     teamName: "Parramatta Eels - Mens",
//     teamId: 435797,
//   },
//   {
//     memberName: "Thomas HAZELTON",
//     memberId: 2872103,
//     teamName: "Cronulla-Sutherland Sharks - Mens",
//     teamId: 435788,
//   },
//   {
//     memberName: "Wiremu GREIG",
//     memberId: 2826214,
//     teamName: "Parramatta Eels - Mens",
//     teamId: 435797,
//   },
//   {
//     memberName: "Klese HAAS",
//     memberId: 2383809,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Ata MARIOTA",
//     memberId: 3882918,
//     teamName: "Canberra Raiders - Mens",
//     teamId: 435786,
//   },
//   {
//     memberName: "Toby Couchman",
//     memberId: 2345823,
//     teamName: "St George Illawarra Dragons - Mens",
//     teamId: 435800,
//   },
//   {
//     memberName: "Iszac FAASUAMALEAUI",
//     memberId: 2405849,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Sandon SMITH",
//     memberId: 2816837,
//     teamName: "Sydney Roosters - Mens",
//     teamId: 435802,
//   },
//   {
//     memberName: "Harrison EDWARDS",
//     memberId: 2186155,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Kurtis MORRIN",
//     memberId: 480711,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Valynce-Mikaeri CROSBY-TE WHARE",
//     memberId: 6225651,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Kulikefu FINEFEUIAKI",
//     memberId: 3158155,
//     teamName: "North Queensland Cowboys - Mens",
//     teamId: 435796,
//   },
//   {
//     memberName: "Shaquai MITCHELL",
//     memberId: 1617738,
//     teamName: "South Sydney Rabbitohs - Mens",
//     teamId: 435799,
//   },
//   {
//     memberName: "Blake WILSON",
//     memberId: 2130745,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Tyson SMOOTHY",
//     memberId: 2038065,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Brendan PIAKURA",
//     memberId: 2204287,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Luca MORETTI",
//     memberId: 2206079,
//     teamName: "Parramatta Eels - Mens",
//     teamId: 435797,
//   },
//   {
//     memberName: "Kaeo WEEKES",
//     memberId: 2161563,
//     teamName: "Manly-Warringah Sea Eagles - Mens",
//     teamId: 435792,
//   },
//   {
//     memberName: "Naufahu Whyte",
//     memberId: 6009573,
//     teamName: "Sydney Roosters - Mens",
//     teamId: 435802,
//   },
//   {
//     memberName: "Daniel RUSSELL",
//     memberId: 653450,
//     teamName: "St George Illawarra Dragons - Mens",
//     teamId: 435800,
//   },
//   {
//     memberName: "Joshua WONG",
//     memberId: 2407909,
//     teamName: "Sydney Roosters - Mens",
//     teamId: 435802,
//   },
//   {
//     memberName: "Xavier WILLISON",
//     memberId: 2773951,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Fetalaiga PAUGA",
//     memberId: 2834731,
//     teamName: "Sydney Roosters - Mens",
//     teamId: 435802,
//   },
//   {
//     memberName: "Izaac THOMPSON",
//     memberId: 2259122,
//     teamName: "South Sydney Rabbitohs - Mens",
//     teamId: 435799,
//   },
//   {
//     memberName: "Karl OLOAPU",
//     memberId: 2649237,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Kurt DONOGHOE",
//     memberId: 2196056,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Mason TEAGUE",
//     memberId: 2215186,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Dean MATTERSON",
//     memberId: 327501,
//     teamName: "Manly-Warringah Sea Eagles - Mens",
//     teamId: 435792,
//   },
//   {
//     memberName: "Jonah Pezet",
//     memberId: 2189388,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "Tallis Duncan",
//     memberId: 2196837,
//     teamName: "South Sydney Rabbitohs - Mens",
//     teamId: 435799,
//   },
//   {
//     memberName: "Franklin PELE",
//     memberId: 2338041,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Tomas CHESTER",
//     memberId: 2133231,
//     teamName: "North Queensland Cowboys - Mens",
//     teamId: 435796,
//   },
//   {
//     memberName: "Harrison GRAHAM",
//     memberId: 2116763,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Keano KINI",
//     memberId: 6115443,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Dylan LUCAS",
//     memberId: 1170005,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Connor MUHLEISEN",
//     memberId: 2111452,
//     teamName: "St George Illawarra Dragons - Mens",
//     teamId: 435800,
//   },
//   {
//     memberName: "Jesse COLQUHOUN",
//     memberId: 2602583,
//     teamName: "Cronulla-Sutherland Sharks - Mens",
//     teamId: 435788,
//   },
//   {
//     memberName: "Justin MATAMUA",
//     memberId: 2358315,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "Thomas JENKINS",
//     memberId: 2195108,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Samuel HUGHES",
//     memberId: 2225853,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Samuela FAINU",
//     memberId: 2202334,
//     teamName: "Manly-Warringah Sea Eagles - Mens",
//     teamId: 435792,
//   },
//   {
//     memberName: "Taine TUAUPIKI",
//     memberId: 2217364,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "Ryan COUCHMAN",
//     memberId: 2345824,
//     teamName: "St George Illawarra Dragons - Mens",
//     teamId: 435800,
//   },
//   {
//     memberName: "Aitasi JAMES",
//     memberId: 2566371,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "Tallyn DA SILVA",
//     memberId: 2619405,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "Deine Mariner",
//     memberId: 6040583,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Khaled RAJAB",
//     memberId: 1886940,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Jack BOSTOCK",
//     memberId: 2384663,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Jacob ALICK",
//     memberId: 2740783,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Zac LAYBUTT",
//     memberId: 2411218,
//     teamName: "North Queensland Cowboys - Mens",
//     teamId: 435796,
//   },
//   {
//     memberName: "Declan CASEY",
//     memberId: 2474146,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Ronald VOLKMAN",
//     memberId: 2117603,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "Trey MOONEY",
//     memberId: 2725951,
//     teamName: "Canberra Raiders - Mens",
//     teamId: 435786,
//   },
//   {
//     memberName: "Jeral SKELTON",
//     memberId: 2193775,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Thomas WEAVER",
//     memberId: 2374291,
//     teamName: "Gold Coast Titans - Mens",
//     teamId: 435791,
//   },
//   {
//     memberName: "Demitric Sifakula",
//     memberId: 6197982,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "Benjamin LOVETT",
//     memberId: 2412048,
//     teamName: "South Sydney Rabbitohs - Mens",
//     teamId: 435799,
//   },
//   {
//     memberName: "Tyrone MUNRO",
//     memberId: 2536790,
//     teamName: "South Sydney Rabbitohs - Mens",
//     teamId: 435799,
//   },
//   {
//     memberName: "Triston REILLY",
//     memberId: 154322,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "Dean HAWKINS",
//     memberId: 162310,
//     teamName: "South Sydney Rabbitohs - Mens",
//     teamId: 435799,
//   },
//   {
//     memberName: "Kane BRADLEY",
//     memberId: 1796059,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "Adrian TREVILYAN",
//     memberId: 2148237,
//     teamName: "Canberra Raiders - Mens",
//     teamId: 435786,
//   },
//   {
//     memberName: "Liam Henry",
//     memberId: 2143462,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Jayden TANNER",
//     memberId: 574790,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Max PLATH",
//     memberId: 2376625,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Gordon CHAN KUM TONG",
//     memberId: 2142323,
//     teamName: "Manly-Warringah Sea Eagles - Mens",
//     teamId: 435792,
//   },
//   {
//     memberName: "Riley PRICE",
//     memberId: 2244454,
//     teamName: "North Queensland Cowboys - Mens",
//     teamId: 435796,
//   },
//   {
//     memberName: "Luke SOMMERTON",
//     memberId: 934928,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Daniel ATKINSON",
//     memberId: 2190012,
//     teamName: "Cronulla-Sutherland Sharks - Mens",
//     teamId: 435788,
//   },
//   {
//     memberName: "Oryn Keeley",
//     memberId: 2182401,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Blake MOZER",
//     memberId: 2401984,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Joshua ROGERS",
//     memberId: 252260,
//     teamName: "Brisbane Broncos - Mens",
//     teamId: 435785,
//   },
//   {
//     memberName: "Ethan STRANGE",
//     memberId: 2555082,
//     teamName: "Canberra Raiders - Mens",
//     teamId: 435786,
//   },
//   {
//     memberName: "Hohepa PURU",
//     memberId: 2557535,
//     teamName: "Canberra Raiders - Mens",
//     teamId: 435786,
//   },
//   {
//     memberName: "Ethan QUAI-WARD",
//     memberId: 597616,
//     teamName: "Canterbury-Bankstown Bulldogs - Mens",
//     teamId: 435787,
//   },
//   {
//     memberName: "Brayden MCGRADY",
//     memberId: 770639,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Trai FULLER",
//     memberId: 2008657,
//     teamName: "Dolphins - Mens",
//     teamId: 435790,
//   },
//   {
//     memberName: "Jack HOWARTH",
//     memberId: 2371504,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "Joe CHAN",
//     memberId: 2511747,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "Sualauvi Faalogo",
//     memberId: 3325040,
//     teamName: "Melbourne Storm - Mens",
//     teamId: 435793,
//   },
//   {
//     memberName: "Ali Leiataua",
//     memberId: 6009642,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "Kalani GOING",
//     memberId: 2684081,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "Paul ROACHE",
//     memberId: 2555869,
//     teamName: "New Zealand Warriors - Mens",
//     teamId: 435794,
//   },
//   {
//     memberName: "Riley Jones",
//     memberId: 2216031,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Ryan RIVETT",
//     memberId: 2198234,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Thomas CANT",
//     memberId: 2096527,
//     teamName: "Newcastle Knights - Mens",
//     teamId: 435795,
//   },
//   {
//     memberName: "Robert DERBY",
//     memberId: 2669273,
//     teamName: "North Queensland Cowboys - Mens",
//     teamId: 435796,
//   },
//   {
//     memberName: "Arthur MILLER-STEPHEN",
//     memberId: 2641261,
//     teamName: "Parramatta Eels - Mens",
//     teamId: 435797,
//   },
//   {
//     memberName: "Jesse MCLEAN",
//     memberId: 2708225,
//     teamName: "Penrith Panthers - Mens",
//     teamId: 435798,
//   },
//   {
//     memberName: "Sione FINAU",
//     memberId: 3045709,
//     teamName: "St George Illawarra Dragons - Mens",
//     teamId: 435800,
//   },
//   {
//     memberName: "Brandon TUMETH",
//     memberId: 2344582,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "Joshua FELEDY",
//     memberId: 6014774,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
//   {
//     memberName: "Kit LAULILII",
//     memberId: 3046486,
//     teamName: "Wests Tigers - Mens",
//     teamId: 435803,
//   },
// ];
