<template>
  <div class="modal d-flex-column ">
    <div class="header flex-0 d-flex">
      <div class="flex-1">
        <h2>{{heading}}</h2>
      </div>
      <button class="flex-0 close-button" @click="handleClick">
        X
      </button>
    </div>
    <div class='content flex-1'>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    heading: String,
    isVideo: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;

    .header {
      width: 100%;
      height: 3.125rem;
      background-color: black;
      color: #ffffff;

      h2 {
        text-align: left;
        margin: 4.5px 0px;
        padding: 10px 20px;
        font-size: 0.875rem;
        letter-spacing: 0.09375rem;
        text-transform: uppercase;
      }

      button {
        width: 3.125rem;
        color: white;
        background-color: black;
      }
    }

    .content {
      text-align: left;
      background-color: black;
      color: white;
      padding: 10px;
      max-width: 100%;
    }

    .video {
      display: flex;
      // height: 100%;
      // width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
</style>

<style lang="scss">
  // Global styling for filters
  .filters-heading {
    margin: 10px;
    margin-top: 30px;
    // font-family: ;
  }

  .filters-wrapper {
    flex-wrap: wrap;
    justify-content: space-around;

    .filter {
      width: 10rem;
      height: 3.5rem;
      border: 1px solid rgba(black, 0.7);
      text-align: center;
      margin: 10px 0px;

      &.selected {
        border: 2px solid black;
      }

      .label {
        max-width: 90%;
        margin: auto;
        line-height: 1rem;
      }
    }
  }
</style>
