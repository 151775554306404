import axios from "axios";
import moment from "moment";
import httpnoauth from "./noauth";
import router from "../router";
import store from "../store";

// Require Auth Requests
const httpauth = axios.create({
  baseURL: store.getters["root/apiUrl"],
  headers: {
    "Content-Type": "application/json",
  },
});

// Handle Refresh Token
httpauth.interceptors.request.use(
  (config) => {
    const originalRequest = config;
    const now = moment().valueOf();
    const expiry = store.getters["user/expiry"];
    const userid = store.getters["user/userid"];

    // Token expired or about to expire
    if (expiry - 300 <= now) {
      const refreshToken = store.getters["user/refreshToken"];
      return httpnoauth
        .post("/nrl/api/v1/awards/authorize", {
          refresh_token: refreshToken,
          user_id: userid,
        })
        .then((response) => {
          store.commit("user/login", response.data);
          // Update Auth Header
          const token = store.getters["user/accessToken"];
          const authheader = `${userid}:${token}`;
          const encodedString = btoa(authheader);
          originalRequest.headers.Authorization = `Bearer ${encodedString}`;
          originalRequest.headers["active-Role"] =
            store.getters["user/activeRoleId"];
          return originalRequest;
        })
        .catch((e) => {
          store.commit("user/logout");
          router.go("/");
          return e;
        });
      // eslint-disable-next-line no-else-return
    } else {
      // Update Auth Header
      const accessToken = store.getters["user/accessToken"];
      const authheader = `${userid}:${accessToken}`;
      const encodedString = btoa(authheader);
      originalRequest.headers.Authorization = `Bearer ${encodedString}`;
      originalRequest.headers["active-Role"] =
        store.getters["user/activeRoleId"];
      return originalRequest;
    }
  },
  (err) => Promise.reject(err),
);

// Handle Global Error Handler
httpauth.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response || { status: 520 };

    if (status === 401) {
      // Not Authorized, redirect to login
      store.commit("user/logout");
      store.commit("root/loading", false);
      router.go("/");
    }

    if (status === 520) {
      // Adding an unknown error handler to catch all error responses with no error msg from API in future
      throw new Error(`An unexpected error happened: ${error}`);
    }

    return Promise.reject(error);
  },
);

export default httpauth;
