<template>
  <div class="card" :class="{ gameClosed: !votingStatus }">
    <!-- FOR FEED BUTTON  -->
    <slot />
    <div class="match-title">
      <p>{{ title }}</p>
    </div>
    <div class="versus-wrapper">
      <div class="versus">
        <div class="match-team--home">
          <div class="team-name left">
            <span>{{ club1.name || team1 }}</span>
          </div>
          <div class="club-logo">
            <img
              :src="`${s3Url}/logos/resize/${club1._id}.png`"
              @error="displayPlaceholder"
              :alt="team1"
            />
          </div>
        </div>

        <div class="time-wrap">
          <span class="time" v-if="status !== 'final'">{{ time }}</span>
          <div class="scores d-flex" v-else>
            <div class="team-score">
              <component
                :is="parseInt(score1) > parseInt(score2) ? 'b' : 'span'"
                >{{ score1 }}</component
              >
            </div>
            <div class="caps grey">full time</div>
            <div class="team-score">
              <component
                :is="parseInt(score2) > parseInt(score1) ? 'b' : 'span'"
                >{{ score2 }}</component
              >
            </div>
          </div>
        </div>
        <div class="match-team--away">
          <div class="club-logo">
            <img
              :src="`${s3Url}/logos/resize/${club2._id}.png`"
              @error="displayPlaceholder"
              :alt="team2"
            />
          </div>
          <div class="team-name right">
            <span>{{ club2.name || team2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <span class="match-title">{{ place || "NA" }}</span>
    <span v-if="votesSubmitted" class="badge">Votes Submitted</span>
  </div>
</template>
<script>
export default {
  name: "Match",
  props: [
    "title",
    "team1",
    "club1",
    "team2",
    "club2",
    "time",
    "place",
    "score1",
    "score2",
    "status",
    "votingStatus",
    "votesSubmitted",
  ],
  computed: {
    s3Url() {
      return this.$store.state.root.s3Url;
    },
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require("../assets/shield_white.svg");
    },
  },
};
</script>
<style lang="scss" scoped>
.grey {
  color: grey;
}
.left {
  text-align: right;
}
.right {
  text-align: left;
}
.versus-wrapper {
  display: flex;
}
.time-wrap {
  min-width: 100px;
  max-width: 185px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  color: rgb(214, 193, 135);
  .scores {
    flex-grow: 1;
    justify-content: space-evenly;
    max-width: 300px;
    font-weight: normal;
    @media (max-width: $tablet) {
      font-size: 0.6em;
    }

    .team-score {
      @media (max-width: $tablet) {
        font-size: 20px;
      }
    }
  }
  .time {
    @media (max-width: $tablet) {
      font-size: 0.7em;
    }
  }
}
.versus {
  /*justify-content: space-between;*/
  flex-grow: 1;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 80px;
  justify-content: center;
  column-gap: 10px;
  @media (max-width: $tablet) {
    max-width: none;
  }
}

.versus {
  font-weight: 500;
  display: flex;

  * {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.block {
  min-width: 50px;
}
.match-title {
  font-size: 0.7em;
  color: rgb(214, 193, 135);
}
[class^="match-team"] {
  display: flex;
  justify-content: flex-end;
  max-width: 30%;
  min-width: 225px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    max-width: 125px;
    min-width: 0;
    width: calc((100% - 100px) / 2);
    align-items: center;
  }

  .team-name {
    color: rgb(214, 193, 135);
    background: -webkit-linear-gradient(rgba(214, 193, 135), #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: $tablet) {
      font-size: 0.6em;
      width: auto;
      max-width: none;
      text-align: center;
      margin-top: 4px;
    }
  }
  .club-logo {
    width: 50px;
    margin: 0 7px;
    flex-shrink: 0;
    @media (min-width: $tablet) {
      width: 65px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
.match-team--away {
  justify-content: flex-start;
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}
</style>
