import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "@/styles/app.scss";
import VueSelect from "vue-select";
import noauth from "./axios/noauth";
import auth from "./axios/auth";
import httpauth from "./axios/httpauth";

Vue.use(Element, { locale });
Vue.component("v-select", VueSelect);

Vue.config.productionTip = false;

Vue.prototype.$logout = () => {
  store.commit("user/logout");
  router.push({ name: "index" });
};

Vue.prototype.$noauth = noauth;
// Vue.prototype.$http = auth;
Vue.prototype.$http = httpauth;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
