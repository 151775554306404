<template>
  <div class="mt-20">
    <span class="title">
      Pick a top player for each position
    </span>
    <div
      v-for="(position, i) in playerByPosition"
      :key="position.value + `-${i}`"
    >
      <el-collapse v-if="!bestPos[position.value]">
        <el-collapse-item :title="position.name" :name="position.value">
          <div class="select-position">
            <div
              v-for="player in position.players"
              :key="`${player._id}`"
              class="player-box"
              @click.stop="pickPlayer(player, position.value)"
            >
              <div class="player-position">
                {{ player.shirtNum }}
              </div>
              <div class="player-name">{{ playerName(player) }}</div>
              <div class="player-headshot">
                <img
                  :src="getHeadshot(player, player.isHomeTeam, 'light')"
                  :alt="playerName(player)"
                />
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div v-if="bestPos[position.value]">
        <div
          :key="`${bestPos[position.value].playerId}`"
          class="player-box mt-20"
          @click.stop="handleSelectPosition(position.value)"
        >
          <div class="player-position">
            {{ positionAbbrev[bestPos[position.value].position.name] }}
          </div>
          <div class="player-name">
            {{ playerName(bestPos[position.value]) }}
          </div>
          <div class="player-headshot">
            <img
              :src="
                getHeadshot(
                  bestPos[position.value],
                  bestPos[position.value].isHomeTeam,
                )
              "
              :alt="playerName(bestPos[position.value])"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { positionAbbrev } from "../utils/constants";

export default {
  name: "TopTeam",
  props: ["playerByPosition", "bestPos", "game"],
  data() {
    return {
      positionAbbrev,
    };
  },
  methods: {
    getHeadshot(player, isHomeTeam, colour = "dark") {
      if (player.teamName)
        isHomeTeam = player.teamName === this.game.homeTeam.name; // fallback code if hometeam boolean is not sent through to function
      const team = this.game.squadData.find(
        (team) => team.isHomeTeam === isHomeTeam,
      );
      const myplayer = team.headshots.find((p) => p._id === player._id);
      const type =
        colour === "dark"
          ? require("@/assets/body-shot.png")
          : require("@/assets/head-shot.png");

      return myplayer &&
        myplayer.profile &&
        myplayer.profile.meta &&
        myplayer.profile.meta.avatar &&
        myplayer.profile.meta.avatar.includes("statsperform")
        ? myplayer.profile.meta.avatar
        : type;
    },
    handleSelectPosition(p) {
      this.$emit("handleSelectPosition", p);
    },
    pickPlayer(player, position) {
      this.$emit("pickPlayer", player, position);
    },
    playerName(p) {
      return `${p.firstName} ${p.lastName}`;
    },
  },
};
</script>
