<template>
  <component :is="Default">
    <template slot="buttons">
      <div class="buttons">
        <button
          class="loose transparent thin"
          v-if="userCheck"
          @click="getHistory"
        >
          admin report
        </button>
        <button class="loose transparent thin" @click="$logout">log out</button>
      </div>
    </template>
    <div class="main">
      <div class="d-flex filters">
        <v-select
          placeholder="Select Competition"
          :options="competitions"
          label="name"
          v-model="selectedCompetition"
          class="w-45"
          :clearable="false"
          @input="setCompetition"
        />
        <v-select
          placeholder="Select Round"
          :options="rounds"
          v-model="selectedRound"
          class="w-45"
          :clearable="false"
        />
      </div>
      <el-collapse class="mt-20">
        <el-collapse-item
          v-if="periodVotingExists"
          title="Periodic Voting"
          name="1"
        >
          <div
            v-for="p of periodVoting"
            :key="p._id"
            class="clickable"
            @click="
              $router.push(
                `/interval-voting?rounds=${p.quarter}&competition=${selectedCompetition._id}`,
              )
            "
          >
            <img src="@/assets/img/logo/dally-m-V2.svg" class="centred" />
            <span class="title">Rounds {{ p.quarter }} Voting Open</span>
            <span v-if="periodVotesSubmitted(p.quarter)" class="badge"
              >Votes Submitted</span
            >
          </div>
        </el-collapse-item>
        <el-collapse-item
          v-if="
            eosCaptainAssignment ||
              eosCoachAssignment ||
              eosRookieAssignment ||
              eosTeamAssignment
          "
          title="End of Season Voting"
          name="2"
        >
          <div
            v-for="eos of eosAssignedCategories"
            :key="eos._id"
            class="clickable"
            @click="
              $router.push(
                `/end-of-season-voting/${eos.type}?competition=${selectedCompetition._id}`,
              )
            "
          >
            <img src="@/assets/img/logo/dally-m-V2.svg" class="centred" />
            <span class="title">{{ textFormat(eos.type) }} Voting Open</span>
            <span v-if="eosVotesSubmitted(eos.type)" class="badge"
              >Votes Submitted</span
            >
          </div>
        </el-collapse-item>
      </el-collapse>
      <div
        v-if="filteredMatches && filteredMatches.length"
        class="competitions"
      >
        <div
          v-for="(f, index) in filteredMatches"
          :key="index"
          @click="selectMatch(f.match, f.votingOpen)"
          class="mt-20 clickable"
        >
          <Match
            :place="f.venue ? f.venue.name : ''"
            :team1="f.match && f.match.home ? f.match.home.name : ''"
            :team2="f.match && f.match.away ? f.match.away.name : ''"
            :club1="f.homeClub ? f.homeClub : undefined"
            :club2="f.awayClub ? f.awayClub : undefined"
            :score1="f.homeScore"
            :score2="f.awayScore"
            :time="matchTime(f.dateTime)"
            :status="f.matchStatus"
            :title="`Round ${f.round.number} ${matchDate(f.dateTime)}`"
            :votingStatus="f.votingOpen"
            :votesSubmitted="f.votedOn"
          />
        </div>
      </div>
      <div v-else>
        <div class="title">
          Welcome to the Dally M voting app. You are currently not assigned any
          matches to vote on.
        </div>
      </div>
      <div style="height:60px;"></div>
    </div>
  </component>
</template>

<script>
import Default from "@/layouts/default";
import Match from "@/components/MatchCard";
import { reportsUsersProd, reportsUsersTest } from "../utils/constants";
import moment from "moment";
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: "MatchList",
  components: {
    Match,
  },
  data() {
    return {
      Default,
      matches: {},
      compAssignments: {},
      eosVoting: {
        "captain-of-the-year": [],
        "coach-of-the-year": [],
        "rookie-of-the-year": [],
        "team-of-the-year": [],
      },
      selectedCompetition: null,
      selectedRound: undefined,
      matchesTest: [],
    };
  },
  async mounted() {
    if (this.currentCompetition) {
      this.selectedCompetition = this.currentCompetition;
    } else if (this.selectedCompetition) {
      this.setCompetition(this.selectedCompetition);
    }
    try {
      this.$store.state.root.loading = true;
      // get match list query and return array with assignments
      const apiMatches = await this.$http.get("/nrl/api/v1/awards/matches");

      if (!apiMatches.data || !apiMatches.data.data)
        throw "Could not retrieve matches";
      const matches = apiMatches.data.data;
      const compObj = {};
      matches.forEach((v) => {
        if (!compObj[v.comp.id]) compObj[v.comp.id] = new Array();
        const votingOpen = v.status.split("_").includes("open");
        const votedOn = !v.status.split("_").includes("novote");
        compObj[v.comp.id].push({
          comp: v.comp,
          match: v.match,
          matchStatus: v.matchInfo.status,
          status: v.status,
          round: v.matchInfo.round,
          dateTime: v.matchInfo.dateTime,
          venue: v.matchInfo.venue,
          homeScore:
            v.matchInfo && v.matchInfo.scores
              ? v.matchInfo.scores.homeTeam
              : "",
          awayScore:
            v.matchInfo && v.matchInfo.scores
              ? v.matchInfo.scores.awayTeam
              : "",
          homeClub: {
            _id: v.homeClub ? v.homeClub._id : "",
            name: v.homeClub ? v.homeClub.name : "",
          },
          awayClub: {
            _id: v.awayClub ? v.awayClub._id : "",
            name: v.awayClub ? v.awayClub.name : "",
          },
          votingOpen,
          votedOn,
        });
      });
      const keys = Object.keys(compObj);
      keys.forEach((k) => {
        const newArr = compObj[k].sort((a, b) => {
          if (b.round.number === a.round.number) return a.dateTime - b.dateTime;
          return b.round.number - a.round.number;
        });
        compObj[k] = newArr;
      });
      this.matches = compObj;
      const compsGet = await this.$http.get("/nrl/api/v1/awards/comps");
      this.compAssignments =
        compsGet.data && compsGet.data.data ? compsGet.data.data : {};
      const eosAssignmentsRes = await this.$http.get(
        "/nrl/api/v1/awards/comps/end-of-season",
      );
      const eosAssignments =
        eosAssignmentsRes.data && eosAssignmentsRes.data.data
          ? eosAssignmentsRes.data.data
          : {};
      const endOfYearAssignments = Object.entries(eosAssignments);
      endOfYearAssignments.forEach((assignment) => {
        if (
          [
            "coach-of-the-year",
            "captain-of-the-year",
            "rookie-of-the-year",
            "team-of-the-year",
          ].includes(assignment[0])
        )
          this.eosVoting[assignment[0]] = assignment[1];
      });

      this.$store.state.root.loading = false;
    } catch (error) {
      this.$store.state.root.loading = false;
      this.$notify.error({
        title: "Unable to complete action",
        message: "Cannot retrieve match data at this time",
      });
    }
  },
  computed: {
    competitions() {
      let arr = [];
      const comps = Object.keys(this.matches);
      comps.forEach((c) => {
        const matches = this.matches[c];
        const unique = [
          ...new Map(matches.map((m) => [m.comp.id, m])).values(),
        ].map((m) => ({
          _id: m.comp.id,
          name: m.comp.name,
        }));
        arr.push(...unique);
      });
      if (arr.length && !this.selectedCompetition && !this.currentCompetition) {
        this.selectedCompetition = arr[0];
        this.setCompetition(arr[0]); // Set initial competition
      }
      return arr;
    },
    ...mapGetters('competition', ['getCurrentCompetition']),
    currentCompetition() {
      return this.getCurrentCompetition;
    },
    eosAssignedCategories() {
      const eosArr = [];
      const {
        eosCaptainAssignment,
        eosCoachAssignment,
        eosRookieAssignment,
        eosTeamAssignment,
        eosVoting,
      } = this;
      if (eosCaptainAssignment)
        eosArr.push(eosVoting["captain-of-the-year"][0]);
      if (eosCoachAssignment) eosArr.push(eosVoting["coach-of-the-year"][0]);
      if (eosRookieAssignment) eosArr.push(eosVoting["rookie-of-the-year"][0]);
      if (eosTeamAssignment) eosArr.push(eosVoting["team-of-the-year"][0]);
      return eosArr;
    },
    eosCaptainAssignment() {
      if (
        !this.eosVoting ||
        !this.eosVoting["captain-of-the-year"] ||
        !this.eosVoting["captain-of-the-year"].length
      )
        return false;
      const current = this.eosVoting["captain-of-the-year"].find(
        (c) => c.comp.id === this.selectedCompetition._id,
      );
      if (!current) return false;
      const stat = current.status;
      const statArr = stat.split("_");
      if (!statArr.includes("assigned")) return false;
      return true;
    },
    eosCoachAssignment() {
      if (
        !this.eosVoting ||
        !this.eosVoting["coach-of-the-year"] ||
        !this.eosVoting["coach-of-the-year"].length
      )
        return false;
      const current = this.eosVoting["coach-of-the-year"].find(
        (c) => c.comp.id === this.selectedCompetition._id,
      );
      if (!current) return false;
      const stat = current.status;
      const statArr = stat.split("_");
      if (!statArr.includes("assigned")) return false;
      return true;
    },
    eosRookieAssignment() {
      if (
        !this.eosVoting ||
        !this.eosVoting["rookie-of-the-year"] ||
        !this.eosVoting["rookie-of-the-year"].length
      )
        return false;
      const current = this.eosVoting["rookie-of-the-year"].find(
        (c) => c.comp.id === this.selectedCompetition._id,
      );
      if (!current) return false;
      const stat = current.status;
      const statArr = stat.split("_");
      if (!statArr.includes("assigned")) return false;
      return true;
    },
    eosTeamAssignment() {
      if (
        !this.eosVoting ||
        !this.eosVoting["team-of-the-year"] ||
        !this.eosVoting["team-of-the-year"].length
      )
        return false;
      const current = this.eosVoting["team-of-the-year"].find(
        (c) => c.comp.id === this.selectedCompetition._id,
      );
      if (!current) return false;
      const stat = current.status;
      const statArr = stat.split("_");
      if (!statArr.includes("assigned")) return false;
      return true;
    },
    filteredMatches() {
      if (this.selectedRound && this.selectedRound.number) {
        return this.matches[this.selectedCompetition._id].filter(
          (match) =>
            match.round.number === this.selectedRound.number &&
            match.round.type === this.selectedRound.type,
        );
      } else {
        return this.matches[this.selectedCompetition._id];
      }
    },
    periodVotingAssignments() {
      const entries = Object.entries(this.compAssignments);
      const periodObj = {};
      entries
        .filter((e) => e[0] !== "end-of-season")
        .forEach((e) => {
          const assignmentObj =
            e[1] &&
            e[1].length &&
            e[1][0].comp.id === this.selectedCompetition._id
              ? e[1][0]
              : {};
          periodObj[e[0]] = assignmentObj;
        });

      return periodObj;
    },
    periodVotingExists() {
      return this.periodVoting && this.periodVoting.length;
    },
    periodVoting() {
      const values = Object.values(this.periodVotingAssignments);
      const assigned = values.filter((v) => Object.keys(v).length);
      return assigned;
    },
    rounds() {
      if (this.selectedCompetition) {
        const { matches, selectedCompetition } = this;
        if (
          !matches[selectedCompetition._id] ||
          !matches[selectedCompetition._id].length
        )
          return [];
        let allRounds = [];
        allRounds = [
          ...new Map(
            matches[selectedCompetition._id].map((m) => [m.round.number, m]),
          ).values(),
        ];
        allRounds = allRounds.map((m) => ({
          label: m.round.displayName,
          type: m.round.type,
          number: m.round.number,
        }));
        allRounds.push({ label: "All Rounds" });
        return allRounds;
      }
      return [];
    },
    userCheck() {
      const _id = this.$store.getters["user/userid"];
      const userAuth =
        process.env.NODE_ENV === "production"
          ? reportsUsersProd
          : reportsUsersTest;
      return userAuth.includes(_id);
    },
  },
  methods: {
    ...mapMutations('competition', ['CURRENT_COMPETITION']),
    setCompetition(competition) {
      this.selectedCompetition = competition;
      this.CURRENT_COMPETITION(competition);
    },
    eosVotesSubmitted(type) {
      if (!this.eosVoting) return false;

      const isOpen = this.eosVoting[type];
      if (!isOpen.length) return false;
      const found = isOpen.find(
        (s) => s.comp.id === this.selectedCompetition._id,
      );
      if (!found) return false;
      const sa = found.status.split("_");
      return sa.includes("voted");
    },
    async getHistory() {
      try {
        this.$store.state.root.loading = true;
        if (!this.selectedCompetition._id)
          throw { msg: "No competition selected" };
        await this.$http.get(
          `/nrl/api/v1/awards/voting-history/${this.selectedCompetition._id}`,
        );
        this.$notify.success({
          title: "Success",
          message: "Report successfully sent",
          showClose: false,
          iconClass: "hideIcon",
        });
        this.$store.state.root.loading = false;
      } catch (error) {
        const errMsg =
          error.response && error.response.status === 401
            ? "Not Authorised"
            : `Unable to send report.${error.msg ? " " + error.msg : ""}`;
        this.$notify.error({
          title: "Error",
          message: errMsg,
        });
        this.$store.state.root.loading = false;
      }
    },
    matchTime(dateTime) {
      return moment(dateTime).format("h:mm a");
    },
    matchDate(dateTime) {
      return moment(dateTime).format("dddd Do MMMM");
    },
    periodVotesSubmitted(p) {
      if (!this.compAssignments) return false;

      const isOpen = this.compAssignments[p];
      if (!isOpen.length) return false;

      const sa = isOpen[0].status.split("_");
      return sa.includes("voted");
    },
    selectMatch(match, votingOpen) {
      if (!match) {
        this.$notify.error({
          title: "Unable to complete action",
          message: "Cannot retrieve match data at this time",
        });
        return;
      }
      if (!votingOpen) {
        this.$notify.error({
          title: "Unable to complete action",
          message: "Voting for this match has been closed",
        });
        return;
      }

      this.$router.push("/match/" + match.id);
      return;
    },
    textFormat(str) {
      const formattedStr = str.split("-").join(" ");
      return formattedStr;
    },
  },
  watch: {
    selectedCompetition: function(val) {
      this.selectedRound = undefined;
      const upcomingMatch = this.filteredMatches.find((match) => {
        return moment()
          .add(2, "hours")
          .isSameOrAfter(match.dateTime);
      });
      if (upcomingMatch && this.rounds.length > 0) {
        this.selectedRound = this.rounds.find(
          (r) =>
            r.number === upcomingMatch.round.number &&
            r.type === upcomingMatch.round.type,
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gameClosed {
  opacity: 0.25;
}
button.download {
  background-color: rgb(153, 27, 27) !important;

  animation: pulse-black 2s infinite;
}
@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: inset 0 -0.6em 1em -0.35em rgb(0 0 0 / 75%),
      inset 0 0.1em 2em -0.3em rgb(0 0 0 / 75%),
      inset 0 0 0em 0.05em rgb(0 0 0 / 10%);
  }

  70% {
    transform: scale(1);
    box-shadow: inset 0 -0.6em 1em -0.35em rgb(0 0 0 / 75%),
      inset 0 0.6em 2em -0.3em rgb(0 0 0 / 75%),
      inset 0 0 0em 0.05em rgb(0 0 0 / 10%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: inset 0 -0.6em 1em -0.35em rgb(0 0 0 / 75%),
      inset 0 0.1em 2em -0.3em rgb(0 0 0 / 75%),
      inset 0 0 0em 0.05em rgb(0 0 0 / 10%);
  }
}
button.download:focus,
button.download:hover {
  background-color: rgb(248 113 113) !important;
}

.centred {
  margin: 0 auto;
}

.main {
  padding-top: 1rem;
  padding-left: 8px;
  padding-right: 8px;

  .filters {
    width: 100%;
    justify-content: space-between;
    padding: 8px;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 10%;

    .v-select {
      @media (min-width: 979px) {
        width: 45%;
      }
    }
  }

  .disabled {
    background-color: #f5f7fa;
  }
  .disabled a {
    color: #c0c4cc;
    cursor: not-allowed;
  }

  .title {
    text-align: center;
    letter-spacing: 0.046rem;
    font-weight: 600;
    width: 100vw;
    margin: 1rem auto;
    color: rgb(120, 100, 46);
    text-transform: capitalize;
    @media (min-width: 680px) {
      width: 75vw;
    }

    @media (min-width: 920px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: 70vw;
      margin-bottom: 1rem;
    }

    @media (min-width: 1124px) {
      paddin-top: 1rem;
      padding-bottom: 1rem;
      width: 68vw;
      margin-bottom: 1rem;
    }
  }
}
</style>
