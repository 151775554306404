<template>
  <div style="width:100%; ">
    <div style="display:flex;flex-direction:column;padding-left:20px; ">
      <a @click="() => this.$router.push('/')" style="text-decoration:none;">
        <div
          style="padding-top:20px;text-align:left;font-size:18px;font-weight:100;color:rgba(214, 193, 135);"
        >
          Home
        </div>
      </a>
      <!-- <a href="/how-to-vote" style="text-decoration:none;">
				<div
					style="padding-top:20px;text-align:left;font-size:18px;font-weight:100;color:rgba(214, 193, 135);"
				>
					How To Vote
				</div>
			</a> -->
      <a
        href="mailto:webmaster@example.com?subject=Dally M Tech Support"
        style="text-decoration:none;"
      >
        <div
          style="padding-top:20px;text-align:left;font-size:18px;font-weight:100;color:rgba(214, 193, 135);"
        >
          Technical support
        </div>
      </a>
      <div
        style="cursor:pointer;padding-top:20px;text-align:left;font-size:18px;font-weight:100;color:rgba(214, 193, 135);"
        @click="clickLogout"
      >
        Sign out
      </div>
      <div
        style="display:flex;flex-direction: column;justify-content:center;padding-top:32px;width:100wh;"
      >
        <div>
          <img src="../assets/img/logo/dally-m-V2.svg" height="200" />
        </div>
        <div style="font-size:24px;font-weight:100;color:rgba(214, 193, 135);">
          Dally M Player of the Year
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowToVote",
  components: {},
  data() {
    return {};
  },
  methods: {
    clickLogout() {
      this.$store.commit("user/logout");
      this.$router.push("/");
    },
  },
};
</script>
