<template>
  <div id="app">
    <LoadingLayout v-if="loading" />
    <router-view />
  </div>
</template>
<script>
import LoadingLayout from "./components/Loading.vue";

export default {
  components: {
    LoadingLayout,
  },
  computed: {
    loading() {
      return this.$store.state.root.loading;
    },
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  min-height: 100%;
  background-color: black;
}
#app {
  height: 100%;
  min-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.mt-2 {
  margin-top: 2rem;
}
</style>
