<template>
  <component :is="Default">
    <div class="main">
      <div class="title">Rounds {{ interval }}</div>
      <div v-if="judgeOverrideOptions" class="mt-20">
        <label class="subtitle">Vote on Behalf of:</label>
        <div class="d-flex filters">
          <v-select
            placeholder="Select Judge"
            :options="judgesToOverride"
            label="name"
            v-model="judgeOverride"
            class="w-45"
          />
        </div>
      </div>
      <el-collapse v-if="ineligiblePlayers && ineligiblePlayers.length">
        <el-collapse-item title="Ineligible Players" name="ineligible">
          <p>
            The following players are not considered for voting due to
            suspension.
          </p>
          <div
            v-for="player in ineligiblePlayers"
            :key="`${player.pId}`"
            class="mt-20 player-box"
          >
            <span>{{ player.pName }} ({{ player.pTeam }})</span>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="mt-20 subtitle">{{ activePos }}</div>
      <div v-if="activePos">
        <el-input
          autocomplete="off"
          placeholder="Filter Names"
          v-model="search"
          class="mt-20"
        ></el-input>
        <el-table
          :data="activeTable"
          border
          @row-click="selectMember"
          highlight-current-row
          v-if="tableRefresh"
          class="mt-20"
        >
          <el-table-column label="Name" prop="name" width="auto">
          </el-table-column>
          <el-table-column label="Team" prop="team" width="auto">
          </el-table-column>
        </el-table>
      </div>
      <div class="clear"></div>
      <el-tabs type="card" class="mt-20">
        <el-tab-pane label="Field">
          <div class="mt-20 column">
            <!-- Column 2 - Canvas with player positions -->
            <div class="canvas">
              <div
                v-for="position in mappedPositions"
                class="player"
                :class="{
                  [position[0].toLowerCase()]: true
                }"
              >
                <div
                  :id="position[0]"
                  class="number-circle gold clickable"
                  @click="populateTable($event, position[0])"
                >
                  <b>{{
                    position[0] !== undefined ? getShirtNum(position[0]) : ''
                  }}</b>
                </div>
                <div class="player-name-wrap">
                  <div class="player-name">
                    <mark v-if="position[1]">{{
                      position[1] !== undefined
                        ? getName(position[1].player.name)
                        : ''
                    }}</mark>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="List">
          <div v-for="pos in positions" :key="pos.type">
            <div class="mt-20 pos-box">
              <div :id="pos.type" class="position" @click="populateTable">
                {{ pos.name }}
              </div>
              <input
                v-if="teamObj[pos.type]"
                :value="teamObj[pos.type].player.name"
                class="position-input"
                disabled
              /><input
                v-else
                class="position-input__unset"
                :value="undefined"
                :placeholder="
                  `Interact with label above to select a ${pos.name}`
                "
                disabled
              />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="clear"></div>
    <el-button
      class="next"
      type="primary"
      :disabled="Object.keys(teamObj).some(pos => teamObj[pos] === undefined)"
      @click="submitInterval"
    >
      Submit
    </el-button>
    <div id="bottom" class="clear"></div>
  </component>
</template>

<script>
import {
  reportsUsersProd,
  reportsUsersTest,
  intervalPosArr
} from '../utils/constants'
import Default from '@/layouts/default'

export default {
  name: 'Interval',
  components: {},
  data () {
    return {
      Default,
      nominees: {},
      members: [],
      activePos: '',
      competition: undefined,
      compAssignments: undefined,
      judge: {
        _id: undefined,
        name: undefined,
        assignmentId: undefined,
        reference: undefined
      },
      interval: '',
      posId: '',
      ineligiblePlayers: [],
      judgeOverrideOptions: undefined,
      judgeOverride: undefined,
      positions: intervalPosArr,
      teamObj: {
        Fullback: undefined,
        'Wing-1': undefined,
        'Centre-1': undefined,
        'Centre-2': undefined,
        'Wing-2': undefined,
        'Five-Eighth': undefined,
        Halfback: undefined,
        'Prop-1': undefined,
        'Prop-2': undefined,
        Hooker: undefined,
        'SecondRow-1': undefined,
        'SecondRow-2': undefined,
        Lock: undefined
      },
      tableRefresh: true,
      search: '',
      interchange: undefined,
      interchangeRemovedInterval: undefined
    }
  },
  async mounted () {
    try {
      this.$store.state.root.loading = true
      const compsGet = await this.$http.get('/nrl/api/v1/awards/comps')
      this.compAssignments =
        compsGet && compsGet.data && compsGet.data.data
          ? compsGet.data.data
          : null
      this.interval =
        this.$route.query && this.$route.query.rounds
          ? this.$route.query.rounds
          : ''
      this.competition =
        this.$route.query && this.$route.query.competition
          ? parseInt(this.$route.query.competition)
          : ''
      const { compAssignments, interval } = this
      if (
        !compAssignments ||
        !compAssignments[interval] ||
        !compAssignments[interval].length ||
        !compAssignments[interval][0].status
      )
        throw 'Not authorised to vote on this interval'

      const assignment = compAssignments[interval][0]
      const statArr = assignment.status.split('_')
      const stat =
        assignment.comp.id === this.competition &&
        statArr.includes('assigned') &&
        statArr.includes('open')
      if (!stat) throw 'Not authorised to vote on this interval'

      const { _id, quarter: q, memberId } = compAssignments[interval][0]
      const name = this.$store.getters['user/userName']
      this.judge = {
        _id: memberId,
        name,
        assignmentId: _id,
        reference: `voter_${memberId}_quarter_${q}`
      }

      const res = await this.$http.get(
        `/nrl/api/v1/awards/comps/${this.competition}/players/interval/${this.interval}`
      )
      if (!res.data && !res.data.data && !res.data.data.playerPosObj)
        throw 'Could not retrieve players'
      this.nominees = res.data.data.playerPosObj
      this.ineligiblePlayers = res.data.data.ineligiblePlayers || []

      const interchangeDataRes = await this.$http.get(
        `/nrl/api/v1/awards/interchange/competition/${this.competition}`
      )
      const interchangeData = interchangeDataRes.data.data

      if (interchangeData && interchangeData.interval) {
        this.interchange = interchangeData.interval
      }

      if (interchangeData && interchangeData.removedInterval) {
        this.interchangeRemovedInterval = interchangeData.removedInterval
      }

      // Ensure removedIntervalData is an object or empty object if null/undefined
      const removedIntervalData = interchangeData ? interchangeData.removedInterval || {} : {}

      if (this.interchange && this.interchange[this.interval]) {
        const nomsExpanded = Object.entries(this.nominees)

        // Iterate over each range of player positions in interchangeData.interval
        for (const intervalRange of Object.keys(this.interchange)) {
          const positionData = this.interchange[intervalRange]
          const removedPlayers = removedIntervalData[intervalRange]

          // Iterate over each position category within the interval range
          for (const position of Object.keys(positionData)) {
            const playersToRemove =
              (removedPlayers && removedPlayers[position]) || []

            if (playersToRemove && Array.isArray(playersToRemove)) {
              const removedPlayerIds = playersToRemove.map(
                player => player.playerId
              )

              // Filter out players from nominees based on the removedPlayerIds
              for (const nom of nomsExpanded) {
                const nomPos = nom[0]
                const nomArr = nom[1]

                if (nomArr && nomArr.length > 0) {
                  const filteredNomArr = nomArr.filter(n => {
                    // Check if player's ID is not in removedPlayerIds
                    return !removedPlayerIds.includes(n.playerId)
                  })

                  this.nominees[nomPos] = filteredNomArr
                }
              }
            }
          }
        }
      }

      // if (
      //   this.interchange &&
      //   this.interchange[this.interval]
      // ) {
      //   const nomsExpanded = Object.entries(this.nominees);
      //   const flattenNoms = Object.values(
      //     interchangeData.interval[this.interval],
      //   ).filter(arr => arr !== null);
      //   const overrideIds = flattenNoms
      //     .flat()
      //     .map((profile) => profile.playerId);
      //   for (const nom of nomsExpanded) {
      //     const nomPos = nom[0];
      //     const nomArr = nom[1];
      //     nomArr.forEach((n) => {
      //       if (!overrideIds.includes(n.playerId)) return;
      //       const nomsIds = this.nominees[nomPos].map((n) => n.playerId);
      //       const deleteInd = nomsIds.indexOf(n.playerId);
      //       if (deleteInd > -1) this.nominees[nomPos].splice(deleteInd, 1);
      //     });
      //   }
      // }

      const userId = this.$store.getters['user/userid']
      const userAuth =
        process.env.NODE_ENV === 'production'
          ? reportsUsersProd
          : reportsUsersTest
      if (!userAuth.includes(userId)) {
        this.$store.state.root.loading = false
        return
      }
      this.judgeOverrideOptions = []
      const url = `/nrl/api/v1/awards/comps/${this.competition}/judges/${this.interval}`
      const superRes = await this.$http.get(url)
      if (!superRes.data && !superRes.data.data) return
      this.judgeOverrideOptions = superRes.data.data
      this.$store.state.root.loading = false
    } catch (error) {
      this.$store.state.root.loading = false
      this.$notify.error({
        title: 'Error',
        message: error
      })
      this.$router.push('/matches')
    }
  },
  computed: {
    activeTable () {
      if (!this.activePos) return []
      const keys = Object.keys(this.teamObj)
      const otherPosArr = keys.filter(
        k => k.includes(this.activePos.replace(' ', '')) && k !== this.posId
      )
      let otherPos
      if (otherPosArr.length) otherPos = otherPosArr[0]
      let playerDup
      if (otherPos) playerDup = this.teamObj[otherPos]
      if (playerDup && typeof playerDup === 'object')
        this.members = this.members.filter(
          m => m.playerId !== playerDup.player._id
        )

      if (this.search.length > 2) {
        const res = this.members.filter(p =>
          p.name.toLowerCase().includes(this.search.toLowerCase().trim())
        )
        return res.sort()
      }
      return this.members.sort()
    },
    ineligibleIds () {
      return this.ineligiblePlayers.map(p => p.pId)
    },
    mappedPositions () {
      const entries = Object.entries(this.teamObj)
      return entries
    },
    mappedPlayers () {
      const v = Object.values(this.teamObj)
      const selectedPLayers = v.filter(p => p && p.player && p.player._id)
      return selectedPLayers.map(p => p.player._id)
    },
    judgesToOverride () {
      const { judgeOverrideOptions } = this
      if (!judgeOverrideOptions) return []
      const arr = judgeOverrideOptions.filter(
        j =>
          j &&
          j.status &&
          j.status.split('_').includes('assigned') &&
          j.status.split('_').includes('open')
      )
      return arr
    }
  },
  methods: {
    selectMember (currentRow) {
      try {
        if (this.ineligibleIds.includes(currentRow.playerId))
          throw {
            title: ': Ineligible Player',
            message:
              'Selected player player has been flagged as ineligble due to accumulated suspensions.'
          }
        this.teamObj[this.posId] = {
          player: {
            _id: currentRow.playerId,
            name: currentRow.name,
            position: this.activePos
          },
          team: {
            _id: currentRow.teamId,
            name: currentRow.team
          }
        }

        const posKeys = Object.keys(this.teamObj)
        this.nextPosition(posKeys)
        this.activePos = ''
      } catch (error) {
        this.$store.state.root.loading = false
        if (!error.title) error.title = ''
        if (!error.message) error.message = 'Could not select player'
        this.$notify.error({
          title: `Error${error.title}`,
          message: error.message,
          showClose: false,
          iconClass: 'hideIcon'
        })
      }
    },
    getName (n) {
      const split = n.split(' ')
      const firstLetterFirstName = split[0].charAt(0).toUpperCase()
      // const firstLetterLastName = split[1].charAt(0).toUpperCase();
      // const remainingLettersLastName = split[1].slice(1);
      const hyphenCheck = split[1].split('-')
      const capitalizedLastName = hyphenCheck
        .map(x => {
          const firstLetter = x.charAt(0).toUpperCase()
          const remainingLetters = x.slice(1).toLowerCase()
          return firstLetter + remainingLetters
        })
        .join('-')

      // const capitalizedLastName =
      //   firstLetterLastName + remainingLettersLastName;
      const name = `${firstLetterFirstName}. ${capitalizedLastName}`
      return name
    },
    getShirtNum (pos) {
      const obj = {
        'Centre-1': 3,
        'Centre-2': 4,
        'Five-Eighth': 6,
        Fullback: 1,
        Halfback: 7,
        Hooker: 9,
        Lock: 13,
        'Prop-1': 8,
        'Prop-2': 10,
        'SecondRow-1': 11,
        'SecondRow-2': 12,
        'Wing-1': 2,
        'Wing-2': 5
      }
      return obj[pos]
    },
    nextPosition (posArr) {
      const nextPos = posArr.find(pos => !this.teamObj[pos])
      const jumpTo = nextPos ? nextPos : 'bottom'
      document.getElementById(jumpTo).scrollIntoView()
    },
    populateTable (e, pe = false) {
      this.$store.state.root.loading = true
      // TODO: add interchange options here
      this.search = ''
      this.tableRefresh = false
      const check = this.positions.find(p => p.type === pe)
      const pos = pe && check ? check.name : e.target.innerHTML
      this.posId = check ? check.type : e.target.id
      this.activePos = pos.trim()
      let currentPos = this.activePos
      if (process.env.VUE_APP_PIN_ENV === 'test') {
        const uatArr = ['Winger', 'Second Row']
        if (uatArr.includes(currentPos)) {
          const map = {
            Winger: 'Wing',
            'Second Row': '2nd Row',
          }
          currentPos = map[currentPos]
        }
      }

      const normalizedPos = currentPos.toLowerCase();

      // Interchange position follows standard position names following mapping is to reflect that.
      const interchangePositionMapping = {
        '2nd row': 'SecondRow',
        'second row': 'SecondRow',
        'winger': 'Wing'
        // Add more mappings as needed
      };

      const interchangePosition = interchangePositionMapping[normalizedPos] || currentPos;

      let posObj = this.nominees[currentPos] || []
      if (
        this.interchange &&
        this.interchange[this.interval] &&
        this.interchange[this.interval][interchangePosition] &&
        this.interchange[this.interval][interchangePosition].length > 0
      ) {
        this.interchange[this.interval][interchangePosition].forEach(int => {
          const playersInPos = posObj.map(p => p.playerId)
          if (playersInPos.includes(int.playerId)) return
          int.order = 0
          posObj.push(int)
        })
      }
      if (
        this.interchangeRemovedInterval &&
        this.interchangeRemovedInterval[this.interval] &&
        this.interchangeRemovedInterval[this.interval][interchangePosition] &&
        this.interchangeRemovedInterval[this.interval][interchangePosition].length
      ) {
        const removedPlayerIds = this.interchangeRemovedInterval[this.interval][
          interchangePosition
        ].map(int => int.playerId)

        // Filter out players to be removed from posObj
        const filteredPosObj = posObj.filter(
          p => !removedPlayerIds.includes(p.playerId)
        )
        posObj = filteredPosObj
      }
      let arr
      if (posObj && posObj.length)
        this.members = posObj.sort((p1, p2) => {
          if (p1.order < p2.order) {
            return 1
          }
          if (p1.order > p2.order) {
            return -1
          }
          if (p1.name.toLowerCase() < p2.name.toLowerCase()) {
            return -1
          }
          if (p1.name.toLowerCase() > p2.name.toLowerCase()) {
            return 1
          }
          return 0
        })
      else this.members = []
      if (this.mappedPlayers && this.mappedPlayers.length)
        this.members = this.members.filter(
          mem => !this.mappedPlayers.includes(mem.playerId)
        )

      setTimeout(() => {
        this.tableRefresh = true
        window.scrollTo(0, 0)
        this.$store.state.root.loading = false
      }, 250)
    },
    async submitInterval () {
      try {
        // clear previous errors
        this.$store.state.root.loading = true
        const allFlaggedErrors = document.querySelectorAll('.error_input')
        const allErrSpans = document.querySelectorAll('span.error_message')
        allErrSpans.forEach(s => s.remove())
        allFlaggedErrors.forEach(i => i.classList.remove('error_input'))

        const { teamObj } = this
        let best = Object.values(teamObj)
        best = best.filter(v => v)
        const players = best.map(v => {
          if (v) {
            if (this.ineligibleIds.includes(v.player._id)) {
              let objE = Object.entries(this.teamObj)
              objE = objE.filter(v => v)
              const inPl = objE.filter(
                e => e[1] && e[1].player._id === v.player._id
              )
              const flags = inPl.flat(2)
              let flaggedPlayers = []
              if (flags.length)
                flaggedPlayers = flags.filter(d => typeof d === 'string')
              if (flaggedPlayers.length) {
                flaggedPlayers.forEach(id => {
                  const elCircle = document.querySelector(
                    `#${id}.number-circle`
                  )
                  const el = document.querySelector(`#${id}.position`)
                  const canvasPlayer = elCircle.parentNode
                  const input = el.nextSibling
                  const cl = input.classList
                  if (cl.contains('error_input')) return
                  input.classList.add('error_input')
                  canvasPlayer.classList.add('error_input')
                  const span = document.createElement('span')
                  span.innerHTML = 'Player selected has been deemed ineligible'
                  span.style.color = 'red'
                  span.classList.add('error_message')
                  input.after(span)
                })
              }
              throw {
                title: ': Ineligible Player',
                message: `Selected player (${v.player.name})  has been flagged as ineligble due to accumulated suspensions.`
              }
            }
            return v.player._id
          }
        })

        const dupPlayers = players.filter(
          (value, index, self) => self.indexOf(value) !== index
        )
        if (dupPlayers.length) {
          let entries = Object.entries(this.teamObj)
          entries = entries.filter(v => v)
          const dupEl = dupPlayers.map(id =>
            entries.filter(e => e[1] && e[1].player._id === id)
          )
          const dups = dupEl.flat(2)
          let dupIds = []
          if (dups.length) dupIds = dups.filter(d => typeof d === 'string')
          if (dupIds.length)
            dupIds.forEach(id => {
              const el = document.getElementById(id)
              const input = el.nextSibling
              const cl = input.classList
              if (cl.contains('error_input')) return
              input.classList.add('error_input')
              const span = document.createElement('span')
              span.innerHTML = 'Player selected in multiple positions'
              span.style.color = 'red'
              span.classList.add('error_message')
              input.after(span)
            })
          throw {
            title: ': Player selected in multiple positions',
            message: 'Player can only be selected in one position.'
          }
        }
        const bestPlayers = Object.values(teamObj)
        const bestPos = Object.keys(teamObj)
        if (bestPlayers.filter(p => !p).length > 0)
          throw {
            title: '',
            message:
              'A player must be selected in every position before submitting votes'
          }
        const { judgeOverride } = this

        bestPlayers.forEach((bp, i) => {
          if (
            ['Winger', 'Centre', 'Prop', 'Second Row'].includes(
              bp.player.position
            )
          )
            bp.player.position = bestPos[i]
          bp.type = 'interval'
          bp.judge = this.judge
          bp.competition = this.competition
          bp.votes = 1
        })
        let override
        let interval
        if (
          judgeOverride &&
          Object.entries(judgeOverride).flat().length === 10
        ) {
          override = judgeOverride
          interval = this.interval
        }
        await this.$http.post('/nrl/api/v1/awards/interval/vote', {
          bestPlayers,
          override,
          interval
        })
        this.$notify.success({
          title: 'Success',
          message: 'Vote has been successfully submitted',
          showClose: false,
          iconClass: 'hideIcon'
        })
        this.$store.state.root.loading = false
        this.$router.push('/matches')
      } catch (error) {
        this.$store.state.root.loading = false
        if (!error.title) error.title = ''
        if (!error.message) error.message = 'Could not submit votes'
        this.$notify.error({
          title: `Error${error.title}`,
          message: error.message,
          showClose: false,
          iconClass: 'hideIcon'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main .position-input.error_input {
  border-bottom: solid 3px red;
  color: red;
  opacity: 0.6;
}

.canvas {
  .player {
    &.error_input {
      .number-circle {
        border-color: red;
        color: red;
        opacity: 0.6;
      }
      .player-name-wrap .player-name mark {
        background-color: red;
      }
    }
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}
.clear::after {
  content: '';
  clear: both;
  display: table;
}

.main {
  padding: 1rem 8px 0;

  .subtitle {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.225rem;
  }

  .v-select {
    @media (min-width: $tablet) {
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .pos-box {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .position {
    color: #fff;
    cursor: pointer;
  }
  .position-input {
    border-bottom: 1px solid rgb(214, 193, 135);
    max-width: 400px;
    width: 100%;
    text-align: center;
    color: rgba(214, 193, 135, 0.5);
    font-size: 14px;
  }
  .position-input__unset {
    max-width: 400px;
    width: 100%;
    border-bottom: solid 3px red;
    &::placeholder {
      font-size: 0.8rem;
    }
  }
}
$number-circle-factor: 0.8;
.canvas {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 550px;
  background-color: #098255;
  margin: auto;

  .player {
    position: absolute;
    transform: translate(20px, 16px);

    .player-name-wrap {
      position: relative;

      .player-name {
        position: absolute;
        width: auto;
        left: -50%;
        text-align: center;
        font-size: 0.7em;
        color: $secondary;
        white-space: nowrap;
        mark {
          background-color: rgb(214, 193, 135);
          padding: 0 4px;
        }
      }
    }
  }
  .prop-1,
  .prop-2,
  .hooker {
    top: 0;
  }
  .secondrow-1,
  .secondrow-2 {
    top: 15%;
  }
  .lock {
    top: 30%;
  }
  .halfback {
    top: 45%;
  }
  .five-eighth {
    top: 55%;
  }
  .centre-1,
  .centre-2 {
    bottom: 25%;
  }
  .wing-1,
  .wing-2 {
    bottom: 15%;
  }
  .fullback {
    bottom: 10%;
  }

  .prop-1,
  .wing-1 {
    left: 0;
  }
  .prop-2,
  .wing-2 {
    left: 75%;
  }
  .hooker,
  .lock,
  .fullback {
    left: 38.5%;
  }
  .secondrow-1,
  .centre-1 {
    left: 17.5%;
  }
  .secondrow-2,
  .centre-2 {
    left: 60%;
  }
  .halfback {
    left: 27.5%;
  }
  .five-eighth {
    left: 50%;
  }

  .number-circle {
    flex-shrink: 0;
    width: 36px * $number-circle-factor;
    height: 36px * $number-circle-factor;
    padding: 8px * $number-circle-factor;
    margin: 0;

    color: $tint;

    font: 20px * $number-circle-factor Arial, sans-serif;

    &.gold {
      border-color: $awards-primary;
    }

    &.available {
      border-color: $primary-highlight;
      color: $primary-highlight;
    }
  }
}
.next {
  margin: 0 auto;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  width: 50%;
  display: block;
}
</style>
